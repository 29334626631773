import { useListViewModel } from '@movicoders/ui';
import { MenuItem, TextField } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DepartmentDTO } from '../../../../../../clients/user-management';
import { DepartmentRepository } from '../../../../../../infrastructure/repository/DepartmentRepository';
import { UUIDGenerator } from '../../../../../../utils/arrayHelper';

interface ISelectDepartment {
  value?: DepartmentDTO;
  onChange?: (e: any) => void;
  variant?: 'standard' | 'outlined' | 'filled';
}

export const SelectDepartment = (params: ISelectDepartment) => {
  const { t } = useTranslation();

  const { data, fetch } = useListViewModel(DepartmentRepository);
  useEffect(() => {
    fetch({});
  }, []);

  const onChange = (e: any) => {
    let { name, value } = e.target;
    value = { id: value.split(',')[0], name: value.split(',')[1] };
    e.target = { name, value };
    params.onChange?.(e);
  };

  return (
    <TextField
      variant={params.variant}
      label={t('formUser.department')}
      select
      defaultValue={params.value ? `${params.value?.id},${params.value?.name}` : undefined}
      name="department"
      onChange={onChange}
      required
    >
      {data.map((value) => (
        <MenuItem key={UUIDGenerator()} value={`${value?.id},${value?.name}`}>
          {value.name?.toLocaleUpperCase()}
        </MenuItem>
      ))}
    </TextField>
  );
};
