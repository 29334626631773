import { GridColumns, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar } from '../../../base/avatar/Avatar';
import { IOptionsResponse } from '../../../base/table/options/types-options';
import CircleIcon from '@mui/icons-material/Circle';
import { StatusService } from '../../../../../domain/services/StatusService';
import { numberOfHours } from '../../../../../helpers/utils';
import { Tooltip } from '@mui/material';

interface IEmployeeColumns {
  onClick: (props: IOptionsResponse) => void | undefined;
}

export const SummaryColumns = (params: IEmployeeColumns) => {
  const { t } = useTranslation();

  const columns = useMemo<GridColumns<any>>(
    () => [
      {
        field: 'avatar',
        sortable: false,
        filterable: false,
        width: 60,
        valueGetter: (params: GridValueGetterParams) => {
          return `${params.row.name} ${params.row.surname}`;
        },
        renderCell: (params: GridRenderCellParams) => <Avatar name={params.value} />,
      },
      {
        field: 'name',
        headerName: t('table.employee.name'),
        flex: 1,
        type: 'string',
        valueGetter: (params: GridValueGetterParams) => {
          return `${params.row.name} ${params.row.surname}`;
        },
      },
      {
        field: 'status',
        headerName: t('table.employee.status'),
        flex: 0.5,
        headerAlign: 'center',
        align: 'center',
        type: 'singleSelect',
        valueOptions: [
          { value: 'COMPLETE', label: 'COMPLETE' },
          { value: 'INCOMPLETE', label: 'INCOMPLETE' },
        ],
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.status;
        },
        renderCell: (params: GridRenderCellParams) => (
          <Tooltip title={params.value}>
            <CircleIcon sx={{ color: StatusService({ status: params.value }) }} />
          </Tooltip>
        ),
      },
      {
        field: 'hours',
        headerName: t('table.employee.hours'),
        flex: 0.5,
        headerAlign: 'right',
        align: 'right',
        type: 'number',
        valueGetter: (params: GridValueGetterParams) => {
          return numberOfHours(params.row.hours);
        },
      },
      {
        field: 'role',
        headerName: t('table.employee.role'),
        flex: 1,
        type: 'string',
      },
      {
        field: 'office',
        type: 'string',
        headerName: t('table.employee.office'),
        flex: 1,
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.office.name.toLocaleUpperCase();
        },
      },
      {
        field: 'department',
        type: 'string',
        headerName: t('table.employee.department'),
        flex: 1,
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.department.name.toLocaleUpperCase();
        },
      },
    ],
    []
  );
  return columns;
};
