import { Box, Button, FormControlLabel, FormLabel, Grid, RadioGroup, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { UserDTO } from '../../../../../clients/user-management';
import { useBtnStyles } from '../../../../styles/buttons.styles';
import useFormStyles from '../../../../styles/form.styles';
import { FeedbackBTN } from '../../../buttons/feedback/feedback';
import { Form } from '../../../base/form/Form';
import { InputPassword } from '../../../base/form/input/input-password';
import { SelectDepartment } from './selects/select-department';
import { SelectOffice } from './selects/select-office';
import { useFormRegister } from './useFormRegister';
import Radio from '@mui/material/Radio';

interface IFormRegisterUser {
  onClose?: () => void;
  user?: UserDTO;
}

export const FormRegisterUser = (params: IFormRegisterUser) => {
  const { t } = useTranslation();

  const buttonsStyles = useBtnStyles();
  const formStyles = useFormStyles();
  const { handleSubmit, handleChange, handleUpdate, loading } = useFormRegister(params);

  return (
    <>
      {params.user ? (
        <>
          <Typography variant="h6" className={formStyles.titleTypography}>
            {t('formUser.update')}
          </Typography>
          <Typography variant="body2" textAlign={'center'} fontWeight={'bold'}>
            {`@${params.user?.username}`}
          </Typography>
        </>
      ) : (
        <Typography variant="h6" className={formStyles.titleTypography}>
          {t('formUser.register')}
        </Typography>
      )}

      <Box
        sx={{
          '& > :not(style)': {
            m: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          },
        }}
      >
        <Form onSubmit={params.user ? handleUpdate : handleSubmit} loading={loading}>
          <TextField label={t('formUser.name')} variant="standard" defaultValue={params.user?.name} name="name" onChange={handleChange} required />
          <TextField label={t('formUser.surname')} variant="standard" defaultValue={params.user?.surname} name="surname" onChange={handleChange} required />
          {!params.user && <TextField label={t('formUser.username')} variant="standard" name="username" onChange={handleChange} required />}
          {!params.user && <InputPassword label={t('formUser.password')} onChange={handleChange} />}
          <TextField
            label={t('formUser.email')}
            variant="standard"
            type="email"
            defaultValue={params.user?.email}
            name="email"
            onChange={handleChange}
            required
          />
          <SelectOffice value={params.user?.office} variant="standard" onChange={handleChange} />
          <SelectDepartment value={params.user?.department} variant="standard" onChange={handleChange} />
          <br />
          <FormLabel required>{t('table.employee.role')}</FormLabel>
          <RadioGroup
            defaultValue={params.user ? `${params.user?.role}` : 'WORKER'}
            sx={{ display: 'flex', flexDirection: 'row' }}
            name="role"
            onChange={handleChange}
          >
            <FormControlLabel value="WORKER" control={<Radio />} label="Worker" />
            <FormControlLabel value="MANAGER" control={<Radio />} label="Manager" />
            <FormControlLabel value="ADMINISTRATOR" control={<Radio />} label="Administrator" />
          </RadioGroup>
          <Grid container justifyContent={'space-evenly'} alignItems={'center'} sx={{ marginTop: '2em' }}>
            {params.user ? (
              <FeedbackBTN type="submit" loading={loading} children={t('button.save')} />
            ) : (
              <>
                <Button className={buttonsStyles.cancelBtn} type="reset" children={t('reset')} />
                <FeedbackBTN type="submit" loading={loading} children={t('button.formUser.register')} />
              </>
            )}
          </Grid>
        </Form>
      </Box>
    </>
  );
};
