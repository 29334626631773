import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { EnumOptions, IOptionsResponse } from './types-options';

interface IColumnOptions {
  onClick: (props: IOptionsResponse) => void;
  props: GridRenderCellParams<any>;
}

export const ColumnOptions = (params: IColumnOptions) => {
  const { t } = useTranslation();

  return [
    <GridActionsCellItem
      icon={<EditIcon />}
      title={t('edit')}
      label="Edit"
      onClick={() => {
        params.onClick({ origin: EnumOptions.EDIT, data: params.props });
      }}
    />,
    <GridActionsCellItem
      icon={<DeleteIcon />}
      label="Delete"
      title={t('delete')}
      onClick={() => {
        params.onClick({ origin: EnumOptions.DELETE, data: params.props });
      }}
    />,
  ];
};
