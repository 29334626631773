import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Grid, Typography } from '@mui/material';

interface IConfirmAction {
  actionText: string;
  descriptionText?: string;
  type: EnumAction;
}

export enum EnumAction {
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
}

export const ConfirmAction = (params: IConfirmAction) => {
  return (
    <Grid container spacing={0} alignItems={'center'} flexDirection={'row'} justifyContent={'center'}>
      <Grid item xs={2}>
        {params.type === EnumAction.ERROR && (
          <ErrorOutlineIcon sx={{ color: 'red', fontSize: '2.5em !important', display: 'flex', alignItems: 'center' }} />
        )}
        {params.type === EnumAction.WARNING && (
          <WarningAmberIcon sx={{ color: 'orange', fontSize: '2.5em !important', display: 'flex', alignItems: 'center' }} />
        )}
        {params.type === EnumAction.INFO && (
          <InfoOutlinedIcon sx={{ color: 'blue', fontSize: '2.5em !important', display: 'flex', alignItems: 'center' }} />
        )}
        {params.type === EnumAction.SUCCESS && (
          <CheckCircleOutlineIcon sx={{ color: 'green', fontSize: '2.5em !important', display: 'flex', alignItems: 'center' }} />
        )}
      </Grid>
      <Grid item xs={8}>
        <Typography variant="h6">{params.actionText}</Typography>
        {params.descriptionText && <Typography variant={'caption'}>{params.descriptionText}</Typography>}
      </Grid>
    </Grid>
  );
};
