import { Button, Grid, IconButton, Menu as MUIMenu, MenuItem, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Avatar } from '../../components/base/avatar/Avatar';
import { useAvatarViewModel } from '../../components/base/avatar/useAvatarViewModel';
import { Dialog } from '../../components/dialog/Dialog';
import { SignForm } from '../../components/signing/forms/sign-form/sign-form';
import { useBtnStyles } from '../../styles/buttons.styles';
import { pathIconsAvatar } from './toolbar-paths';
import { useToolbarViewModel } from './useToolbarViewModel';
export const Menu = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { name } = useAvatarViewModel();
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const { logout } = useToolbarViewModel();

  const buttonsStyles = useBtnStyles();
  const [openSignForm, setOpenSignForm] = useState(false);
  const onClose = () => {
    setOpenSignForm(false);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => setAnchorElUser(event.currentTarget);
  const handleCloseUserMenu = () => setAnchorElUser(null);
  const filterOptions = (option: string) => {
    if (option === 'toolbar.logout') logout();
  };

  return (
    <Grid container justifyContent="flex-end" alignItems="center" spacing={5}>
      <Grid item>
        <Tooltip title={t('button.newChecking')}>
          <Button className={buttonsStyles.addTopBtn} onClick={() => setOpenSignForm(true)}>
            {t('button.newChecking')}
          </Button>
        </Tooltip>
        <Dialog
          open={openSignForm}
          onClose={onClose}
          dialogConfig={{
            title: '',
            content: <SignForm setOpen={setOpenSignForm} onClose={onClose} />,
          }}
        />
      </Grid>
      <Grid item>
        <Tooltip title={t('toolbar.settings.title')}>
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            <Avatar name={name!!} />
          </IconButton>
        </Tooltip>
        <MUIMenu
          sx={{ mt: '45px' }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          {pathIconsAvatar.map((element: any) => (
            <MenuItem
              key={element.name}
              onClick={() => {
                filterOptions(element.name);
                handleCloseUserMenu();
                navigate(element.path);
              }}
            >
              <Typography textAlign="center">{t(element.name)}</Typography>
            </MenuItem>
          ))}
        </MUIMenu>
      </Grid>
    </Grid>
  );
};
