import { Container as MUIContainer } from '@mui/material';

interface IView {
  justifyContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around';
  backgroundColor?: string;
  fullWidth?: boolean;
  children: React.ReactNode;
}

export const View = (params: IView) => {
  return (
    <MUIContainer
      sx={{
        flexGrow: 1,
        p: 3,
        display: 'flex',
        justifyContent: params.justifyContent,
        backgroundColor: params.backgroundColor,
        maxWidth: params.fullWidth ? '100% !important' : 'inherit',
      }}
    >
      {params.children}
    </MUIContainer>
  );
};
