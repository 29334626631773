import { Paper } from '../../components/base/paper/Paper';
import { Container } from '../../components/container/Container';
import { TableEmployees } from '../../components/user/tables/employees/TableEmployees';

export const Employees = () => {
  return (
    <Container>
      <Paper>
        <TableEmployees />
      </Paper>
    </Container>
  );
};
