import { createTheme } from '@mui/material/styles';
import * as locales from '@mui/material/locale';
import { PaletteMode } from '@mui/material';

export const Colors = {
  primary: '#324191',
  secondary: '#2D5A90',
  primaryOpacity: 'rgba(50, 65, 145, 0.5)',
  primaryDark: '#1F2D4E',
  secondaryDark: '#22446D',
  text: '#9999',
  title: '#2D5A90',
  linkGrayText: '#AFAFAF',
  whiteText: '#FFFFFF',
  blackText: '#000000',
  error: 'rgba(225, 62, 62, 0.8)',
  errorOpacity: 'rgba(225, 62, 62, 0.5)',
  errorDark: '#A02424',
  loginBackground: '#dce0f5',
  //confirm green
  confirm: '#00BF63',
  typographyBody: '#3f51b5',
  calendarSecondary: '#FFBBBB',
  selected: '#E0ECFA',

  // Colors for the new design
  statusComplete: '#00BFA6',
  statusCompleteOpacity: 'rgba(0, 191, 166, 0.5)',
  statusIncomplete: '#FF5252',
  statusIncompleteOpacity: 'rgba(255, 82, 82, 0.5)',
  statusMissing: '#FFC107',
  statusMissingOpacity: 'rgba(255, 193, 7, 0.5)',
  statusEmpty: '#F5F6F8',
  statusEmptyOpacity: 'rgba(245, 246, 248, 0.5)',
  statusPending: '#3F51B5',
  statusPendingOpacity: 'rgba(63, 81, 181, 0.5)',

  // Colors for the new design
  background: '#F5F6F8',
  backgroundDark: '#C4CAD8',
  paperDark: '#37383A',
  darkGray: '#AFAAAA',
};

export const Sizes = {
  heightBtn: '2em',
  widthBtn: '8em',
  heightCircleBtn: '2em',
  widthCircleBtn: '2em',
  heightBox: '2em',
  widthBox: '8em',
  paddingBox: '1em',
  marginBox: '1em',
  title: '1.5em',
};

export const Border = {
  borderRadiusBox: '15px',
};
export const getDesignTokens = (mode: PaletteMode) => ({
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          primary: {
            main: Colors.primary,
          },
          secondary: {
            main: Colors.secondary,
          },
          background: {
            default: Colors.background,
          },
        }
      : {
          primary: {
            main: Colors.primary,
          },
          secondary: {
            main: Colors.secondary,
          },
          background: {
            default: Colors.backgroundDark,
            paper: Colors.paperDark,
          },
        }),
  },
});

const baseTheme = createTheme(
  {
    palette: {
      mode: 'light',
      primary: {
        main: Colors.primary,
      },
      secondary: {
        main: Colors.secondary,
      },
      background: {
        default: Colors.background,
      },
    },
  },
  locales['esES']
);

export default baseTheme;
