import { IRepository } from '@movicoders/domain';
import { useListViewModel, useResolve } from '@movicoders/ui';
import { useSnackbar } from '@movicoders/ui/lib/Snackbar/useSnackbar';
import { ObjectType } from '@movicoders/ui/lib/ViewModels/types';
import { SelectChangeEvent } from '@mui/material/Select/Select';
import i18next from 'i18next';
import { useState } from 'react';
import { ErrorsService } from '../../../domain/services/ErrorsService';

export const useLanguageSettings = () => {
  const [language, setLanguage] = useState(i18next.language);

  const handleLanguageChange = (event: SelectChangeEvent<string>) => {
    setLanguage(event.target.value);
    i18next.changeLanguage(event.target.value);
  };

  return { handleLanguageChange, language };
};

interface IUseChipList<T> {
  repository: ObjectType<IRepository<any, any, any>>;
  object?: any;
}

export function useChipList<T>(params: IUseChipList<T>) {
  const [visibleForm, setVisibleForm] = useState<boolean>(false);
  const [newItem, setNewItem] = useState<string>('');
  const { show } = useSnackbar();
  const resolveRepository = useResolve(params.repository);
  const { data, fetch, remove } = useListViewModel(params.repository);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await resolveRepository.create({ name: newItem });
      setVisibleForm(false);
      setNewItem('');
      show('Registro Añadido', 'success');
    } catch (e) {
      show('Error al añadir el registro', 'error');
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const result = await resolveRepository.remove(id);
      setVisibleForm(false);
      fetch({});
      show('Registro Eliminado', 'success');
    } catch (e: any) {
      const error = await ErrorsService(e.response);
      show(error?.message!!, 'error');
    }
  };

  const handleChange = (event: any) => {
    setNewItem(event.target.value);
  };

  const handleCancel = () => {
    setNewItem('');
    setVisibleForm(false);
  };

  return { visibleForm, setVisibleForm, handleChange, handleSubmit, handleDelete, handleCancel, fetch, data };
}
