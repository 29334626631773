import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import { Colors } from '../../../theme';

interface IRadialBar {
  series: number[];
  labels: string[];
  colors: string[];
  colorsGradient?: string[];
}

export const RadialBar = (params: IRadialBar) => {
  const series = params.series;

  const options: ApexOptions = {
    chart: {
      height: 235,
      type: 'radialBar',
      zoom: {
        enabled: true,
      },
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: '70%',
        },
        track: {
          background: Colors.darkGray,
        },
        dataLabels: {
          show: true,
          name: {
            offsetY: -10,
            show: true,
            color: Colors.paperDark,
            fontSize: '14px',
          },
          value: {
            formatter: (val: number) => {
              return val + '%';
            },
            color: Colors.linkGrayText,
            fontSize: '2em',
            show: true,
          },
        },
      },
    },
    labels: params.labels,
    fill: {
      type: 'gradient',
      colors: params.colors,
      gradient: {
        shade: 'dark',
        type: 'horizontal',
        shadeIntensity: 0.5,
        gradientToColors: params.colorsGradient ? params.colorsGradient : params.colors,
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
    stroke: {
      lineCap: 'round',
    },
  };

  return <ReactApexChart options={options} series={series} type={'radialBar'} height={235} />;
};
