import { Close } from '@mui/icons-material';
import { Button, Checkbox, FormControlLabel, Grid, IconButton } from '@mui/material';
import MuiDialog from '@mui/material/Dialog';
import MuiActions from '@mui/material/DialogActions';
import MuiContent from '@mui/material/DialogContent';
import MuiTitle from '@mui/material/DialogTitle';
import withStyles from '@mui/styles/withStyles';
import { Colors } from '../../theme';
import { useDialogStyles } from './dialog.styles';
import { ConfirmDialogProps } from './types';

export function Dialog(params: ConfirmDialogProps) {
  const CustomDialog = withStyles({})(MuiDialog);
  const CustomDialogTitle = withStyles({
    root: {
      backgroundColor: params.backgroundColorTitle ? params.backgroundColorTitle : 'transparent',
      color: params.colorTitle ? params.colorTitle : Colors.whiteText,
    },
  })(MuiTitle);
  const CustomDialogContent = withStyles({})(MuiContent);
  const CustomDialogAction = withStyles({})(MuiActions);

  const classes = useDialogStyles();
  let checkedDialog = false;

  const onCheckBoxChange = (state: boolean) => {
    params.onCheckBoxChange && params.onCheckBoxChange(state);
    checkedDialog = state;
  };

  return (
    <CustomDialog
      id={params.id}
      TransitionComponent={params.transition && params.transition}
      fullScreen={params.fullScreen}
      maxWidth={params.maxWidth}
      className={params.classNameDialog}
      open={params.open}
      fullWidth={params.fullWidth}
      style={params.styleDialog}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') params.onClose && params.onClose();
      }}
    >
      <CustomDialogTitle itemID={params.idDialogTitle} style={params.styleHeader} className={params.classNameDialogTitle} id="draggable-dialog-title">
        <Grid container spacing={2}>
          <Grid item xs={10} className={classes.detailViewTitle}>
            <div style={{ alignItems: 'center', display: 'flex' }}>
              {params.dialogConfig.titleContent}
              {params.dialogConfig.title}
            </div>
          </Grid>
          {params.closeWithX ?? (
            <Grid item xs={2} className={classes.disableCheckBox}>
              <IconButton component="span" id={params.idCloseWithXButton} onClick={() => (params.onClose && params.onClose()) ?? (() => {})}>
                <Close htmlColor={params.colorTitle} />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </CustomDialogTitle>

      <CustomDialogContent id={params.idDialogContent} style={params.styleContent} className={params.classNameDialogContent}>
        {params.dialogConfig.content}
        {params.checkBox === true && (
          <Grid item xs={12}>
            <FormControlLabel
              classes={{ label: classes.checkboxLabel }}
              control={
                <Checkbox id={params.idCheckBox} onChange={(event: any, checked: boolean) => onCheckBoxChange(checked)} name="checkedB" color="primary" />
              }
              label={params.dialogConfig.checkBoxLabel ?? ''}
            />
          </Grid>
        )}
      </CustomDialogContent>

      {/* Dialog buttons */}
      <CustomDialogAction id={params.idDialogAction} style={params.styleActionBox} className={classes.dialogActions}>
        {params.dialogConfig.cancelText && (
          <Button onClick={() => params.onClose && params.onClose()} className={classes.btnCancel} variant="contained">
            {params.dialogConfig.cancelText}
          </Button>
        )}
        {params.dialogConfig.confirmText && (
          <Button
            onClick={() => {
              params.onConfirm && params.onConfirm();
              if (params.checkBox === true && checkedDialog === false) return;
              params.onClose && params.onClose();
            }}
            className={classes.btnConfirm}
            variant="contained"
          >
            {params.dialogConfig.confirmText}
          </Button>
        )}
      </CustomDialogAction>
    </CustomDialog>
  );
}
