/* tslint:disable */
/* eslint-disable */
/**
 * UserManagement microservice (dev)
 * UserManagement microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompanyDTO
 */
export interface CompanyDTO {
    /**
     * 
     * @type {boolean}
     * @memberof CompanyDTO
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CompanyDTO
     */
    cif?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDTO
     */
    location?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDTO
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDTO
     */
    tenant?: string;
}

/**
 * Check if a given object implements the CompanyDTO interface.
 */
export function instanceOfCompanyDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CompanyDTOFromJSON(json: any): CompanyDTO {
    return CompanyDTOFromJSONTyped(json, false);
}

export function CompanyDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'active': !exists(json, 'active') ? undefined : json['active'],
        'cif': !exists(json, 'cif') ? undefined : json['cif'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'location': !exists(json, 'location') ? undefined : json['location'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'tenant': !exists(json, 'tenant') ? undefined : json['tenant'],
    };
}

export function CompanyDTOToJSON(value?: CompanyDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'active': value.active,
        'cif': value.cif,
        'id': value.id,
        'location': value.location,
        'name': value.name,
        'phone': value.phone,
        'tenant': value.tenant,
    };
}

