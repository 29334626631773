import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { ChangeEvent, useState } from 'react';

interface IInputPassword {
  label: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const InputPassword = (params: IInputPassword) => {
  const [passwordShown, setPasswordShown] = useState(false);
  return (
    <TextField
      label={params.label}
      type={passwordShown ? 'text' : 'password'}
      variant="standard"
      name="password"
      onChange={params.onChange}
      required
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => {
                setPasswordShown(!passwordShown);
              }}
            >
              {passwordShown ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
