import { AuthApi, UserApi } from '../../clients/user-management';
import { ICredentials } from '../../domain/interfaces/ICredentials';
import { User } from '../../domain/models/User';
import { UserService } from '../../domain/services/UserService';

export class AuthenticateUseCase {
  constructor(private readonly userService: UserService, private readonly loginClient: AuthApi) {}

  public async execute(credentials: ICredentials) {
    const loginResponse = await this.loginClient.loginUser({ loginRequest: credentials });
    this.userService.save(new User(credentials.username, credentials.username.substring(1), credentials.username, loginResponse.token!!));
  }

  public async logout() {
    this.userService.clear();
  }
}
