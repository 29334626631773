import { TYPES } from '@movicoders/domain';
import { useResolve, useSnackbar } from '@movicoders/ui';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SigningDTO, SigningDTOTypeEnum } from '../../../../../clients/signing-management';

import { UserService } from '../../../../../domain/services/UserService';
import { SigningRepository } from '../../../../../infrastructure/repository/SigningRepository';

interface IUseSignFormViewModel {
  date?: Date;
  signing?: SigningDTO;
  setOpen?: (open: boolean) => void;
  onClose?: () => void;
}

export const useSignFormViewModel = (params: IUseSignFormViewModel) => {
  const [date, setDate] = useState(params.date || new Date());
  const [signing, setSigning] = useState<SigningDTO>(params.signing || {});
  const [signResponse, setSignResponse] = useState<SigningDTO>();
  const signingRepository = useResolve(SigningRepository);
  const [loading, setLoading] = useState(false);
  const { show } = useSnackbar();
  const { t } = useTranslation();

  const userService = useResolve<UserService>(TYPES.UserService);

  const [values, setValues] = useState<SigningDTO>({
    signDate: date.getTime(),
    state: params.date ? 'MISSED' : 'CREATED',
    type: signing.type || SigningDTOTypeEnum.In,
    observation: signing.observation!!,
    user: userService.user?.credentials,
    latitude: signing.latitude || 0,
    longitude: signing.longitude || 0,
  });

  const handleChange = (e: any) => {
    date.setSeconds(0, 0);
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleChangeHour = (e: any) => {
    const { name, value } = e.target;
    let data = value.split(':');
    if (data[0] && data[1]) {
      date.setHours(Number(data[0]), Number(data[1]), 0, 0);
      e.target = { name: 'signDate', value: date.getTime() };
      handleChange(e);
    }
  };

  const handleSubmit = async (e: any) => {
    setLoading(true);
    e.preventDefault();
    let signing: SigningDTO = values;
    try {
      if (params.signing) {
        signing.id = params.signing.id || '0';
        signing.user = params.signing.user;
        const response = await signingRepository.update(signing);
        if (response && params.onClose) params.onClose();
      } else {
        const response = await signingRepository.create(signing);
        if (response) {
          window.setTimeout(() => {
            setLoading(false);
            show(t('register.add'), 'success');
            params.onClose?.();
          }, 1000);
        }
      }
    } catch (e) {
      console.log(e);
    }
    !params.date && window.location.reload();
  };

  return {
    date,
    handleSubmit,
    handleChange,
    handleChangeHour,
    signResponse,
    loading,
    values,
  };
};
