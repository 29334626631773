import MenuIcon from '@mui/icons-material/Menu';
import { Toolbar as MUIToolbar } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { DrawerMenu } from './toolbar-drawer';
import { Menu } from './toolbar-menu';
import { useToolbarViewModel } from './useToolbarViewModel';

interface IToolbar {
  drawerwidth: string | number;
}
interface AppBarProps extends MuiAppBarProps {
  drawerwidth: string | number;
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open, drawerwidth }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerwidth,
    /* width: `calc(100% - ${drawerwidth}px)`, */
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const Toolbar = (params: IToolbar) => {
  const drawerwidth = params.drawerwidth;
  const { open, setOpen, t, loading } = useToolbarViewModel();

  return (
    <>
      <CssBaseline />
      <AppBar position="fixed" open={open} drawerwidth={drawerwidth}>
        <MUIToolbar
          sx={{
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => setOpen(!open)}
              edge="start"
              sx={{
                marginRight: 5,
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              {t('toolbar.title')}
            </Typography>
          </Box>
          <Box sx={{ flexGrow: 0 }}>{loading ? <Menu /> : <></>}</Box>
        </MUIToolbar>
      </AppBar>
      <DrawerMenu open={open} setOpen={setOpen} drawerwidth={drawerwidth} />
    </>
  );
};
