import { Container as MUIContainer } from '@mui/material';

interface IContainer {
  children: React.ReactNode;
  background?: string;
}

export const Container = (params: IContainer) => {
  return (
    <MUIContainer
      sx={{
        flexGrow: 1,
        p: 3,
        marginTop: 8,
        marginLeft: 0,
        marginRight: 0,
        paddingLeft: 2,
        minHeight: '100%',
        maxWidth: '100% !important',
        background: params.background,
      }}
    >
      {params.children}
    </MUIContainer>
  );
};
