import { SNACKBAR, SnackbarService, useResolve } from '@movicoders/ui';
import { Alert, AlertColor, Snackbar as MUISnackbar } from '@mui/material';

export const Snackbar = () => {
  const {
    state: { isVisible, message, severity },
    hide,
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useResolve<SnackbarService>(SNACKBAR);

  const handleClose = () => hide();

  return (
    <MUISnackbar open={isVisible} autoHideDuration={5000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
      <Alert onClose={handleClose} severity={severity as AlertColor} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </MUISnackbar>
  );
};
