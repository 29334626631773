import GroupIcon from '@mui/icons-material/Group';
import { Box, Button, Grid, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as XLSX from 'xlsx-js-style';
import { UserDTO } from '../../../../../clients/user-management';
import { userSheet } from '../../../../../helpers/sheetXlsx';
import { timestamp2HumanReadableDateMonth } from '../../../../../helpers/utils';
import { useBtnStyles } from '../../../../styles/buttons.styles';
import { useTablesStyle } from '../../../../styles/tables.styles';
import { IOptionsResponse, ISelected } from '../../../base/table/options/types-options';
import { Table } from '../../../base/table/Table';
import { SummaryColumns } from './summary-columns';
import { useTableSummary } from './useTableSummary';

interface ITableSummary {
  setUser: (user: UserDTO) => void;
  date?: Date;
  setDate?: (date: Date) => void;
}

export const TableSummary = (params: ITableSummary) => {
  const { t } = useTranslation();
  const tablesStyles = useTablesStyle();
  const buttonsStyles = useBtnStyles();

  const { data, loading, getSummaryList, getCompany } = useTableSummary(params);

  const handleClick = (props: IOptionsResponse) => {};

  const selected = (props: ISelected) => {
    params.setDate!! && params.setDate(new Date(params.date!!));
    if (props.data.length > 0) {
      let user: UserDTO = {
        id: props.data[0].toString(),
      };
      params.setUser(user);
      return;
    }
  };

  const handleOnExport = async () => {
    let companyInfo = await getCompany();
    let signingList = await getSummaryList();
    let wb = XLSX.utils.book_new();
    const names : string[] = []
    signingList.forEach((item) => {
      const ws = userSheet(item, companyInfo, params.date);
      let key = (item.name + "_" + item.surname).replaceAll(" ", "_")
      let counter = names?.filter(item => item === key).length;
      names.push(key)
      XLSX.utils.book_append_sheet(wb, ws, key + "_" + (counter + 1));
    });
    XLSX.writeFile(wb, `${t('summary')}_${timestamp2HumanReadableDateMonth(params.date?.getTime()!!)}.xlsx`);
  };

  return (
    <Grid container spacing={1} columns={12} direction="column">
      <Grid item xs={12} className={tablesStyles.toolbar}>
        <Box>
          <Typography sx={{ fontWeight: 'bold' }}>{String(t('table.employee.title')).toLocaleUpperCase()}</Typography>
        </Box>
        <Box></Box>
        <Tooltip title={t('summary.download')}>
          <Button className={buttonsStyles.addBtn} onClick={handleOnExport}>
            <GroupIcon />
            {t('summary')}
          </Button>
        </Tooltip>
      </Grid>
      <Grid item xs={12} sx={{ padding: '0px !important' }}>
        <Table columns={SummaryColumns({ onClick: handleClick })} rows={data} loading={loading} setSelection={selected} getRowId={(r) => r.userId} />
      </Grid>
    </Grid>
  );
};
