import { Colors } from '../../ui/theme';

export enum Status {
  'COMPLETE' = 'COMPLETE',
  'INCOMPLETE' = 'INCOMPLETE',
}

interface IStatusService {
  status: string;
}
export const StatusService = (params: IStatusService) => {
  const { status } = params;
  if (status === Status.COMPLETE) return Colors.statusComplete;
  if (status === Status.INCOMPLETE) return Colors.statusIncomplete;
  return Colors.statusEmpty;
};
