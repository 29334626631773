import { IRepository } from '@movicoders/domain';
import { DomainPage } from '@movicoders/domain/lib/Entities/DomainPage';
import { GetCalendarSigningsByUser1Request, SigningForCalendarDTO, SigningsManagementApi } from '../../clients/signing-management';

export class SigningManagementCalendarRepository implements IRepository<SigningForCalendarDTO, GetCalendarSigningsByUser1Request, any> {
  constructor(private readonly client: SigningsManagementApi) {}
  get(criteria?: GetCalendarSigningsByUser1Request | undefined): Promise<SigningForCalendarDTO[]> {
    return this.client.getCalendarSigningsByUser1(criteria!!);
  }
  getPaginated(pageCriteria?: any): Promise<DomainPage<SigningForCalendarDTO>> {
    throw new Error('Method not implemented.');
  }
  getById(id: string): Promise<SigningForCalendarDTO> {
    throw new Error('Method not implemented.');
  }
  create(entity: SigningForCalendarDTO): Promise<SigningForCalendarDTO> {
    throw new Error('Method not implemented.');
  }
  update(entity: SigningForCalendarDTO): Promise<SigningForCalendarDTO> {
    throw new Error('Method not implemented.');
  }
  remove(id: string): Promise<void> {
    throw new Error('Method not implemented.');
  }
}
