import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, Button, FormControl, Grid, IconButton, InputAdornment, Paper, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from '../../components/view/View';
import { Colors } from '../../theme';
import { StylesLogin } from './login.styles';
import { useLogin } from './useLogin';

interface State {
  password: string;
  showPassword: boolean;
  username: string;
}

export const Login = () => {
  const { login, errorTxt, errorVisible } = useLogin();
  const { t } = useTranslation();
  const classes = StylesLogin();

  const [values, setValues] = useState<State>({
    password: '',
    showPassword: false,
    username: '',
  });

  const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => setValues({ ...values, [prop]: event.target.value });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let username = values.username || '';
    let password = values.password || '';
    login({ username, password });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const renderError = () => {
    return (
      <Box
        className={classes.boxError}
        style={{
          visibility: errorVisible ? 'visible' : 'hidden',
          textAlign: 'center',
        }}
      >
        <p>{errorTxt}</p>
      </Box>
    );
  };

  return (
    <View fullWidth justifyContent={'center'} backgroundColor={Colors.primaryDark}>
      <Paper square elevation={5} sx={{ borderRadius: '5px', maxHeight: '600px', marginTop: '5vh' }}>
        <Box className={classes.boxImgLogo} />
        <Box className={classes.divLogin}>
          <Grid container className={classes.gridContainer}>
            <form className={classes.form} onSubmit={handleSubmit}>
              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <TextField label={t('login.user')} value={values.username} onChange={handleChange('username')} className={classes.textField} />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <TextField
                    label={t('login.password')}
                    value={values.password}
                    onChange={handleChange('password')}
                    type={values.showPassword ? 'text' : 'password'}
                    className={classes.textField}
                    style={{ marginTop: '10px' }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword}>
                            {values.showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>
              {renderError()}
              <Grid item xs={12}>
                <Button type="submit" className={classes.submitBtn}>
                  {t('button.access')}
                </Button>
              </Grid>
            </form>
          </Grid>
        </Box>
      </Paper>
    </View>
  );
};
