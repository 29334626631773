import { UUIDGenerator } from '../utils/arrayHelper';
import { Snackbar } from './core/snackbar/Snackbar';
import { Toolbar } from './core/toolbar/Toolbar';

export const Layout = () => {
  return (
    <>
      <Toolbar drawerwidth={240} key={UUIDGenerator()} />
      <Snackbar />
    </>
  );
};
