import { useResolve } from '@movicoders/ui';
import { Skeleton } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StatisticDTO } from '../../../../clients/signing-management';
import { StatisticsRepository } from '../../../../infrastructure/repository/StatisticsRepository';
import { Colors } from '../../../theme';
import { RadialBar } from '../../base/graphics/RadialBar';

export const GraphicsSigningMissed = () => {
  const service = useResolve(StatisticsRepository);
  const [date, _] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<StatisticDTO>();

  const { t } = useTranslation();

  const getData = async () => {
    setLoading(true);
    const from = new Date(date.getFullYear(), date.getMonth(), 1).setHours(0, 0, 0, 1);
    const to = new Date(date.getFullYear(), date.getMonth() + 1, 0).setHours(23, 59, 59, 999);
    let data = await service.getStatisticMissed({ from, to });
    setData(data);
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return loading ? (
    <Skeleton />
  ) : (
    <RadialBar series={[Number(data?.percent?.toFixed(2))]} labels={[t('chart.missed.title')]} colors={[Colors.statusMissing]} />
  );
};
