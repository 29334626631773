import { useResolve, useSnackbar } from '@movicoders/ui';
import { useState } from 'react';
import { UserDTO } from '../../../../../clients/user-management';
import { ErrorsService } from '../../../../../domain/services/ErrorsService';
import { UsersManagementRepository } from '../../../../../infrastructure/repository/UsersManagementRepository';

interface IUseFormRegisterUser {
  onClose?: () => void;
  user?: UserDTO;
}

export const useFormRegister = (params: IUseFormRegisterUser) => {
  const usersManagementRepository = useResolve(UsersManagementRepository);
  const { show } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState<UserDTO>(
    params.user || {
      role: 'WORKER',
    }
  );

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = async (e: any) => {
    setLoading?.(true);
    e.preventDefault();
    let user: UserDTO = values;
    try {
      const response = await usersManagementRepository.create(user);
      if (response) {
        window.setTimeout(() => {
          setLoading?.(false);
          show('Registro añadido', 'success');
          params.onClose?.();
        }, 1000);
      }
    } catch (e: any) {
      const error = await ErrorsService(e.response);
      setLoading?.(false);
      show(error?.message!!, 'error');
      params.onClose?.();
    }
  };

  const handleUpdate = async (e: any) => {
    setLoading?.(true);
    e.preventDefault();
    let user: UserDTO = values;
    try {
      const response = await usersManagementRepository.update(user);
      if (response) {
        window.setTimeout(() => {
          setLoading?.(false);
          show('Registro actualizado', 'success');
          params.onClose?.();
        }, 1000);
      }
    } catch (e: any) {
      const error = await ErrorsService(e.response);
      setLoading?.(false);
      show(error?.message!!, 'error');
      params.onClose?.();
    }
  };

  return { handleSubmit, handleChange, loading, handleUpdate };
};
