/* tslint:disable */
/* eslint-disable */
/**
 * SigningManagement microservice (dev)
 * SigningManagement microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SigningForCalendarDTO
 */
export interface SigningForCalendarDTO {
    /**
     * 
     * @type {number}
     * @memberof SigningForCalendarDTO
     */
    day?: number;
    /**
     * 
     * @type {number}
     * @memberof SigningForCalendarDTO
     */
    hours?: number;
    /**
     * 
     * @type {string}
     * @memberof SigningForCalendarDTO
     */
    status?: SigningForCalendarDTOStatusEnum;
}


/**
 * @export
 */
export const SigningForCalendarDTOStatusEnum = {
    Complete: 'COMPLETE',
    Empty: 'EMPTY',
    Holiday: 'HOLIDAY',
    Incomplete: 'INCOMPLETE'
} as const;
export type SigningForCalendarDTOStatusEnum = typeof SigningForCalendarDTOStatusEnum[keyof typeof SigningForCalendarDTOStatusEnum];


/**
 * Check if a given object implements the SigningForCalendarDTO interface.
 */
export function instanceOfSigningForCalendarDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SigningForCalendarDTOFromJSON(json: any): SigningForCalendarDTO {
    return SigningForCalendarDTOFromJSONTyped(json, false);
}

export function SigningForCalendarDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SigningForCalendarDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'day': !exists(json, 'day') ? undefined : json['day'],
        'hours': !exists(json, 'hours') ? undefined : json['hours'],
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function SigningForCalendarDTOToJSON(value?: SigningForCalendarDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'day': value.day,
        'hours': value.hours,
        'status': value.status,
    };
}

