import { TYPES } from '@movicoders/domain';
import { useResolve } from '@movicoders/ui';
import i18next from 'i18next';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { AuthenticateUseCase } from '../../../application/authenticate/authenticate';
import { ICredentials } from '../../../domain/interfaces/ICredentials';
import { UserService } from '../../../domain/services/UserService';
import { ROUTER_PATHS } from '../../constants';

export const useLogin = () => {
  const navigate = useNavigate();

  const userService = useResolve<UserService>(TYPES.UserService);
  const authenticateUseCase = useResolve(AuthenticateUseCase);

  const [errorTxt, setErrorTxt] = useState('.');
  const [errorVisible, setErrorVisible] = useState(false);

  useEffect(() => {
    if (userService.user) navigate(ROUTER_PATHS.home);
  }, [navigate, userService.user]);

  const login = async (credentials: ICredentials) => {
    try {
      await authenticateUseCase.execute(credentials);
      setErrorVisible(true);
      navigate('/');
    } catch (err) {
      console.error(err);
      showErrorMessage(credentials);
    }
  };

  const showErrorMessage = (credentials: ICredentials) => {
    setErrorTxt(i18next.t('login.error.invalid'));
    if (credentials.username === '' || credentials.password === '') setErrorTxt(i18next.t('login.error.required'));
    setErrorVisible(true);
  };

  return { login, errorTxt, errorVisible, showErrorMessage };
};
