import { Grid } from '@mui/material';
import { useState } from 'react';
import { SigningCalendarRepository } from '../../../infrastructure/repository/SigningCalendarRepository';
import { SigningRepository } from '../../../infrastructure/repository/SigningRepository';
import { Paper } from '../../components/base/paper/Paper';
import { Container } from '../../components/container/Container';
import { SingleCalendar } from '../../components/signing/calendar/single-calendar/singleCalendar';
import { TableSignByDay } from '../../components/signing/tables/sign-by-day/TableSignByDay';

export const CalendarView = () => {
  const [view, _] = useState('module');
  const [date, setDate] = useState(new Date());

  return (
    <Container>
      <Grid container spacing={0} gap={0} flexDirection={'row'} alignItems={'center'} justifyContent={'flex-end'}>
        <Grid item xs={12} display={'flex'} justifyContent={'flex-end'}></Grid>
      </Grid>
      <Grid container spacing={1} gap={0}>
        <Grid item xs={4} minWidth={'25em'}>
          <Paper sx={{ padding: '2em !important' }}>
            {view === 'module' ? (
              <SingleCalendar openDay={false} date={date} setDate={setDate} repository={SigningCalendarRepository} isToolbar={true} />
            ) : (
              <></>
            )}
          </Paper>
        </Grid>
        <Grid item xs={8}>
          <Paper sx={{ padding: '2em !important' }}>
            <TableSignByDay date={date} setDate={setDate} repository={SigningRepository} isTools={true} />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};
