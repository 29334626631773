import { SigningRepository } from '../../../infrastructure/repository/SigningRepository';
import { Paper } from '../../components/base/paper/Paper';
import { Container } from '../../components/container/Container';
import { TableSignByDay } from '../../components/signing/tables/sign-by-day/TableSignByDay';

export const History = () => {
  return (
    <Container>
      {
        <Paper>
          <TableSignByDay repository={SigningRepository} isTools={true} />
        </Paper>
      }
    </Container>
  );
};
