import { makeStyles } from '@mui/styles';
import { Colors, Sizes } from '../theme';

export interface StyleProps {
  colorBTN: string;
  size?: number;
}

export const useFormStyles = makeStyles({
  titleTypography: {
    fontSize: `calc(${Sizes.title} * 1.5)`,
    minWidth: '500px',
    textAlign: 'center',
    letterSpacing: '4px !important',
    color: Colors.primary,
  },
});

export default useFormStyles;
