/* tslint:disable */
/* eslint-disable */
/**
 * UserManagement microservice (dev)
 * UserManagement microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OfficeDTO
 */
export interface OfficeDTO {
    /**
     * 
     * @type {string}
     * @memberof OfficeDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeDTO
     */
    name?: string;
}

/**
 * Check if a given object implements the OfficeDTO interface.
 */
export function instanceOfOfficeDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OfficeDTOFromJSON(json: any): OfficeDTO {
    return OfficeDTOFromJSONTyped(json, false);
}

export function OfficeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfficeDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function OfficeDTOToJSON(value?: OfficeDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
    };
}

