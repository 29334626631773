/* tslint:disable */
/* eslint-disable */
/**
 * UserManagement microservice (dev)
 * UserManagement microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  MovicodersBadRequestException,
  MovicodersNotFoundException,
  MovicodersServiceException,
  ProfileDTO,
  UserBasicDTO,
  UserDTO,
} from '../models';
import {
    MovicodersBadRequestExceptionFromJSON,
    MovicodersBadRequestExceptionToJSON,
    MovicodersNotFoundExceptionFromJSON,
    MovicodersNotFoundExceptionToJSON,
    MovicodersServiceExceptionFromJSON,
    MovicodersServiceExceptionToJSON,
    ProfileDTOFromJSON,
    ProfileDTOToJSON,
    UserBasicDTOFromJSON,
    UserBasicDTOToJSON,
    UserDTOFromJSON,
    UserDTOToJSON,
} from '../models';

export interface ActivateUserByIdRequest {
    profileId: string;
}

export interface DeactivateUserByIdRequest {
    profileId: string;
}

export interface DeleteUserByIdRequest {
    profileId: string;
}

export interface GetUsersBasicInformationByDepartmentRequest {
    departmentId: string;
}

export interface GetUsersBasicInformationByOfficeRequest {
    officeId: string;
}

export interface RegisterUserRequest {
    userDTO?: UserDTO;
}

export interface UpdateUserByIdRequest {
    profileId: string;
    profileDTO?: ProfileDTO;
}

/**
 * 
 */
export class UsersManagementApi extends runtime.BaseAPI {

    /**
     * Activate a client by profile id.
     */
    async activateUserByIdRaw(requestParameters: ActivateUserByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProfileDTO>> {
        if (requestParameters.profileId === null || requestParameters.profileId === undefined) {
            throw new runtime.RequiredError('profileId','Required parameter requestParameters.profileId was null or undefined when calling activateUserById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/user-management/{profileId}/activate`.replace(`{${"profileId"}}`, encodeURIComponent(String(requestParameters.profileId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfileDTOFromJSON(jsonValue));
    }

    /**
     * Activate a client by profile id.
     */
    async activateUserById(requestParameters: ActivateUserByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProfileDTO> {
        const response = await this.activateUserByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deactivate a client by profile id.
     */
    async deactivateUserByIdRaw(requestParameters: DeactivateUserByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProfileDTO>> {
        if (requestParameters.profileId === null || requestParameters.profileId === undefined) {
            throw new runtime.RequiredError('profileId','Required parameter requestParameters.profileId was null or undefined when calling deactivateUserById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/user-management/{profileId}/deactivate}`.replace(`{${"profileId"}}`, encodeURIComponent(String(requestParameters.profileId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfileDTOFromJSON(jsonValue));
    }

    /**
     * Deactivate a client by profile id.
     */
    async deactivateUserById(requestParameters: DeactivateUserByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProfileDTO> {
        const response = await this.deactivateUserByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a client by profile id.
     */
    async deleteUserByIdRaw(requestParameters: DeleteUserByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProfileDTO>> {
        if (requestParameters.profileId === null || requestParameters.profileId === undefined) {
            throw new runtime.RequiredError('profileId','Required parameter requestParameters.profileId was null or undefined when calling deleteUserById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/user-management/{profileId}`.replace(`{${"profileId"}}`, encodeURIComponent(String(requestParameters.profileId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfileDTOFromJSON(jsonValue));
    }

    /**
     * Delete a client by profile id.
     */
    async deleteUserById(requestParameters: DeleteUserByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProfileDTO> {
        const response = await this.deleteUserByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the Users Basic Information
     */
    async getUsersBasicInformationRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserBasicDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/user-management`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserBasicDTOFromJSON));
    }

    /**
     * Returns the Users Basic Information
     */
    async getUsersBasicInformation(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserBasicDTO>> {
        const response = await this.getUsersBasicInformationRaw(initOverrides);
        return await response.value();
    }

    /**
     * Returns the Users Basic Information by department
     */
    async getUsersBasicInformationByDepartmentRaw(requestParameters: GetUsersBasicInformationByDepartmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserBasicDTO>>> {
        if (requestParameters.departmentId === null || requestParameters.departmentId === undefined) {
            throw new runtime.RequiredError('departmentId','Required parameter requestParameters.departmentId was null or undefined when calling getUsersBasicInformationByDepartment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/user-management/department/{departmentId}`.replace(`{${"departmentId"}}`, encodeURIComponent(String(requestParameters.departmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserBasicDTOFromJSON));
    }

    /**
     * Returns the Users Basic Information by department
     */
    async getUsersBasicInformationByDepartment(requestParameters: GetUsersBasicInformationByDepartmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserBasicDTO>> {
        const response = await this.getUsersBasicInformationByDepartmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the Users Basic Information by office
     */
    async getUsersBasicInformationByOfficeRaw(requestParameters: GetUsersBasicInformationByOfficeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserBasicDTO>>> {
        if (requestParameters.officeId === null || requestParameters.officeId === undefined) {
            throw new runtime.RequiredError('officeId','Required parameter requestParameters.officeId was null or undefined when calling getUsersBasicInformationByOffice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/user-management/office/{officeId}`.replace(`{${"officeId"}}`, encodeURIComponent(String(requestParameters.officeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserBasicDTOFromJSON));
    }

    /**
     * Returns the Users Basic Information by office
     */
    async getUsersBasicInformationByOffice(requestParameters: GetUsersBasicInformationByOfficeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserBasicDTO>> {
        const response = await this.getUsersBasicInformationByOfficeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the registered User
     */
    async registerUserRaw(requestParameters: RegisterUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/user-management`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserDTOToJSON(requestParameters.userDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDTOFromJSON(jsonValue));
    }

    /**
     * Returns the registered User
     */
    async registerUser(requestParameters: RegisterUserRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserDTO> {
        const response = await this.registerUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a client by profile id.
     */
    async updateUserByIdRaw(requestParameters: UpdateUserByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProfileDTO>> {
        if (requestParameters.profileId === null || requestParameters.profileId === undefined) {
            throw new runtime.RequiredError('profileId','Required parameter requestParameters.profileId was null or undefined when calling updateUserById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/user-management/{profileId}`.replace(`{${"profileId"}}`, encodeURIComponent(String(requestParameters.profileId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProfileDTOToJSON(requestParameters.profileDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfileDTOFromJSON(jsonValue));
    }

    /**
     * Update a client by profile id.
     */
    async updateUserById(requestParameters: UpdateUserByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProfileDTO> {
        const response = await this.updateUserByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
