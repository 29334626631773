import { Backdrop, Box, Button, CircularProgress } from '@mui/material';
import { useBtnStyles } from '../../../styles/buttons.styles';
import { Colors } from '../../../theme';

interface IFeedback {
  loading: boolean;
  onClick?: () => void;
  children?: React.ReactNode;
  type?: 'submit' | 'button' | 'reset';
}

export const FeedbackBTN = (params: IFeedback) => {
  const classes = useBtnStyles();

  const buttonSx = {
    ...(params.loading && {
      color: Colors.blackText + '!important',
      bgcolor: Colors.text + '!important',
    }),
  };

  return (
    <Box sx={{ m: 1, position: 'relative' }}>
      <Backdrop open={params.loading} />
      <Button onClick={params.onClick} className={classes.confirmBtn} sx={buttonSx} type={params.type}>
        {params.children}
      </Button>
      {params.loading && (
        <CircularProgress
          thickness={5}
          size={24}
          sx={{
            color: 'green',
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-12px',
            marginLeft: '-12px',
          }}
        />
      )}
    </Box>
  );
};
