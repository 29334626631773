import { AppBase, Resource } from '@movicoders/ui';
import { Box, createTheme, PaletteMode, ThemeProvider } from '@mui/material';
import { Suspense, useMemo, useState } from 'react';
import './App.css';
import { register } from './di.register';
import i18n from './infrastructure/i18n/i18n';
import { ROUTER_PATHS } from './ui/constants';
import { AuthGuard } from './ui/guard/AuthGuard';
import { Layout } from './ui/Layout';
import { getDesignTokens } from './ui/theme';
import { Account } from './ui/views/account/Account';
import { CalendarView } from './ui/views/calendar/CalendarView';
import { Employees } from './ui/views/employees/EmployeesView';
import { History } from './ui/views/history/History';
import { HistoryEmployee } from './ui/views/historyEmployee/HistoryEmployee';
import { Home } from './ui/views/home/Home';
import { Login } from './ui/views/login/Login';
import { Settings } from './ui/views/settings/Settings';

console.log(i18n.language);

function App() {
  const [mode, _] = useState<PaletteMode>('light');
  const theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: 'flex',
          minHeight: '100vh',
        }}
      >
        <Suspense fallback={<div>Loading...</div>}>
          <AppBase register={register} layout={<AuthGuard children={Layout()} />} catchAll={<div>404</div>}>
            <Resource path={ROUTER_PATHS.home} main={<AuthGuard children={Home()} />} />
            <Resource path={ROUTER_PATHS.history} main={History} />
            <Resource path={ROUTER_PATHS.calendar} main={CalendarView} />
            <Resource path={ROUTER_PATHS.employees} main={Employees} permissions={['ADMINISTRATOR', 'MANAGER']} />
            <Resource path={ROUTER_PATHS.historyemployee} main={HistoryEmployee} permissions={['ADMINISTRATOR', 'MANAGER']} />
            <Resource path={ROUTER_PATHS.settings} main={Settings} />
            <Resource path={ROUTER_PATHS.account} main={Account} />
            <Resource path={ROUTER_PATHS.login} main={Login} />
          </AppBase>
        </Suspense>
      </Box>
    </ThemeProvider>
  );
}

export default App;
