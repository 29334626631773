import { TYPES } from '@movicoders/domain';
import { useResolve } from '@movicoders/ui';
import { useState } from 'react';
import { User } from '../../../../domain/models/User';
import { UserService } from '../../../../domain/services/UserService';

export const useBoxInfo = () => {
  const userService = useResolve<UserService>(TYPES.UserService);
  const [user, setUser] = useState<User>(userService.user!!);

  const data = () => setUser(userService.user!!);

  return { data, user };
};
