import './di.decorate';
import { Container } from '@movicoders/di';
import { TYPES } from '@movicoders/domain';
import { AuthenticateUseCase } from './application/authenticate/authenticate';
import { AuthApi, CompaniesApi, DepartmentsApi, OfficesApi, UserApi, UsersManagementApi } from './clients/user-management';
import { UserService } from './domain/services/UserService';
import { buildUserService } from './infrastructure/services/BuildUserService';
import { UserManagementApiConfiguration } from './infrastructure/api/UserManagementApiConfiguration';
import { ObservationRepository } from './infrastructure/repository/ObservationRepository';
import { UserUpdatePasswordUseCase, UserUseCase } from './application/user/user';
import { SigningRepository } from './infrastructure/repository/SigningRepository';
import { SNACKBAR, SnackbarService, useSnackbar } from '@movicoders/ui';
import { OfficeRepository } from './infrastructure/repository/OfficeRepository';
import { DepartmentRepository } from './infrastructure/repository/DepartmentRepository';
import { SigningManagementApiConfiguration } from './infrastructure/api/SigningManagementApiConfiguration';
import { ObservationsApi, SigningsApi, SigningsManagementApi, StatisticsApi } from './clients/signing-management';
import { UsersManagementRepository } from './infrastructure/repository/UsersManagementRepository';
import { SigningCalendarRepository } from './infrastructure/repository/SigningCalendarRepository';
import { SigningManagementRepository } from './infrastructure/repository/SigningManagementRepository';
import { SigningManagementCalendarRepository } from './infrastructure/repository/SigningManagementCalendarRepository';
import { StatisticsRepository } from './infrastructure/repository/StatisticsRepository';
import { CompanyRepository } from './infrastructure/repository/CompanyRepository';

export const register = (container: typeof Container) => {
  container.unbindAll();
  container.bind(UserManagementApiConfiguration).toSelf();
  container.bind(SigningManagementApiConfiguration).toSelf();

  container.bind<UserService>(TYPES.UserService).toDynamicValue(() => buildUserService());

  container.bind(AuthenticateUseCase).toSelf();
  container.bind(UserUseCase).toSelf();
  container.bind(UserUpdatePasswordUseCase).toSelf();

  container.bind(AuthApi).toSelf();
  container.bind(CompaniesApi).toSelf();
  container.bind(OfficesApi).toSelf();
  container.bind(DepartmentsApi).toSelf();
  container.bind(UserApi).toSelf();
  container.bind(UsersManagementApi).toSelf();
  container.bind(ObservationsApi).toSelf();
  container.bind(SigningsApi).toSelf();
  container.bind(SigningsManagementApi).toSelf();
  container.bind(StatisticsApi).toSelf();

  container.bind(OfficeRepository).toSelf();
  container.bind(CompanyRepository).toSelf();
  container.bind(DepartmentRepository).toSelf();
  container.bind(UsersManagementRepository).toSelf();
  container.bind(ObservationRepository).toSelf();
  container.bind(SigningRepository).toSelf();
  container.bind(SigningCalendarRepository).toSelf();
  container.bind(SigningManagementRepository).toSelf();
  container.bind(SigningManagementCalendarRepository).toSelf();
  container.bind(StatisticsRepository).toSelf();

  container.bind<SnackbarService>(SNACKBAR).toDynamicValue(() => useSnackbar());
};
