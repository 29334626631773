import { TYPES } from '@movicoders/domain';
import { useResolve } from '@movicoders/ui';
import { useEffect, useState } from 'react';
import { UserService } from '../../../../domain/services/UserService';

export const useAvatarViewModel = () => {
  const userService = useResolve<UserService>(TYPES.UserService);
  const [name, setName] = useState(userService.user?.getFullName());

  const getNameUser = () => {
    if (userService.user) setName(userService.user.getFullName());
  };

  useEffect(() => {
    getNameUser();
  }, []);

  return { getNameUser, name };
};
