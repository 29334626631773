import { create } from '@movicoders/store';
import { User } from '../../domain/models/User';
import { UserService } from '../../domain/services/UserService';

export const buildUserService = create<UserService>((set) => ({
  save: (user: User) => set({ user }),
  clear: () => set({ user: undefined }),
}))
  .withPersist(User, 'UserService')
  .build();
