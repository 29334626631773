/* tslint:disable */
/* eslint-disable */
/**
 * SigningManagement microservice (dev)
 * SigningManagement microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IconDTO
 */
export interface IconDTO {
    /**
     * 
     * @type {string}
     * @memberof IconDTO
     */
    family?: IconDTOFamilyEnum;
    /**
     * 
     * @type {string}
     * @memberof IconDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof IconDTO
     */
    name?: string;
}


/**
 * @export
 */
export const IconDTOFamilyEnum = {
    Antdesign: 'ANTDESIGN',
    Entypo: 'ENTYPO',
    Evilicons: 'EVILICONS',
    Feather: 'FEATHER',
    Fontawesome: 'FONTAWESOME',
    Fontawesome5: 'FONTAWESOME5',
    Fontawesome5Brands: 'FONTAWESOME5BRANDS',
    Fontisto: 'FONTISTO',
    Foundation: 'FOUNDATION',
    Ionicons: 'IONICONS',
    Materialcommunityicons: 'MATERIALCOMMUNITYICONS',
    Materialicons: 'MATERIALICONS',
    Octicons: 'OCTICONS',
    Simplelineicons: 'SIMPLELINEICONS',
    Zocial: 'ZOCIAL'
} as const;
export type IconDTOFamilyEnum = typeof IconDTOFamilyEnum[keyof typeof IconDTOFamilyEnum];


/**
 * Check if a given object implements the IconDTO interface.
 */
export function instanceOfIconDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function IconDTOFromJSON(json: any): IconDTO {
    return IconDTOFromJSONTyped(json, false);
}

export function IconDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): IconDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'family': !exists(json, 'family') ? undefined : json['family'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function IconDTOToJSON(value?: IconDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'family': value.family,
        'id': value.id,
        'name': value.name,
    };
}

