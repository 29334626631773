import { Button } from '@mui/material';
import { useState } from 'react';
import { useStylesOptions } from './sign-option.style';

interface ISignOptionBT {
  color: string;
  onClick: (type: string) => void;
  isSelected: boolean;
  children: React.ReactNode;
  value?: string;
}

export const SignOptionBT = (params: ISignOptionBT) => {
  const [value, _] = useState(params.value || '');
  const classes = useStylesOptions();

  return (
    <Button
      value={value}
      variant="contained"
      color="primary"
      onClick={() => {
        params.onClick(value);
      }}
      className={classes.button}
      sx={{
        ...(params.isSelected ? { boxShadow: `0px 0px 2px 12px ${params.color}` } : { boxShadow: 'none' }),
        backgroundColor: params.color,
        '&:hover': {
          backgroundColor: params.color,
          ...(params.isSelected ? { boxShadow: `0px 0px 2px 12px ${params.color}` } : { boxShadow: 'none' }),
        },
      }}
    >
      {params.children}
    </Button>
  );
};
