import { IRepository } from '@movicoders/domain';
import { useListViewModel } from '@movicoders/ui';
import { ObjectType } from '@movicoders/ui/lib/ViewModels/types';
import AddIcon from '@mui/icons-material/Add';
import { Badge, Box, Button, Grid, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SigningDTO } from '../../../../../clients/signing-management';
import { UserDTO } from '../../../../../clients/user-management';
import { timestamp2HumanReadableDate } from '../../../../../helpers/utils';
import { useBtnStyles } from '../../../../styles/buttons.styles';
import { useTablesStyle } from '../../../../styles/tables.styles';
import { Colors } from '../../../../theme';
import { EnumOptions, IOptionsResponse } from '../../../base/table/options/types-options';
import { Table } from '../../../base/table/Table';
import { ConfirmAction, EnumAction } from '../../../dialog/actions/ConfirmAction';
import { Dialog } from '../../../dialog/Dialog';
import { ConfirmationDialogs } from '../../../dialog/variant/ConfirmationDialogs';
import { SignForm } from '../../forms/sign-form/sign-form';
import { SignColumns } from './sign-columns';

interface ISignByDay {
  date?: Date;
  setDate?: (date: Date) => void;
  repository: ObjectType<IRepository<any, any, any>>;
  user?: UserDTO;
  isTools?: boolean;
}

export const TableSignByDay = (params: ISignByDay) => {
  const { t } = useTranslation();
  const tablesStyles = useTablesStyle();
  const buttonsStyles = useBtnStyles();
  const [openSignForm, setOpenSignForm] = useState(false);
  const [openEditSignForm, setEditOpenSignForm] = useState(false);
  const [openDeleteSignForm, setDeleteOpenSignForm] = useState(false);

  const [date, _] = useState(params.date ? params.date : new Date());
  const [signDate, setSignDate] = useState(new Date());
  const [signing, setSigning] = useState<SigningDTO>();

  const { data, fetch, loading, remove } = useListViewModel(params.repository);

  const getSignings = () => {
    let from = date.setHours(0, 0, 0, 1);
    let to = date.setHours(23, 59, 59, 999);
    date.setHours(new Date().getHours(), new Date().getMinutes(), new Date().getSeconds(), new Date().getMilliseconds());
    fetch({
      from: from.valueOf(),
      to: to.valueOf(),
      userId: params.user?.id,
    });
  };

  useEffect(() => {
    params.date && date.setTime(params.date.getTime());
    getSignings();
  }, [params.date, params.user]);

  const handleClick = (props: IOptionsResponse) => {
    setSigning(props.data.row);
    if (props.origin === EnumOptions.EDIT) {
      setSignDate(new Date(props.data.row.signDate!!));
      setEditOpenSignForm(true);
      return;
    }
    if (props.origin === EnumOptions.DELETE) {
      setDeleteOpenSignForm(true);
      return;
    }
  };
  const handleDelete = () => {
    remove(signing?.id!!);
    onClose();
  };

  const onClose = () => {
    let newDate = new Date(date.getTime());
    params.setDate!! && params.setDate(newDate);
    setOpenSignForm(false);
    setEditOpenSignForm(false);
    setDeleteOpenSignForm(false);
    getSignings();
  };
  return (
    <Grid container spacing={1} columns={12} direction="column">
      <Grid item xs={12} className={tablesStyles.toolbar}>
        <Box>
          {params.date ? (
            <Typography sx={{ fontWeight: 'bold' }}>{String(timestamp2HumanReadableDate(params.date?.getTime())).toLocaleUpperCase()}</Typography>
          ) : (
            <Typography sx={{ fontWeight: 'bold' }}>{String(t('table.signing.title')).toLocaleUpperCase()}</Typography>
          )}
        </Box>
        <Box>
          <Dialog
            open={openSignForm}
            onClose={onClose}
            dialogConfig={{
              title: '',
              content: <SignForm setOpen={setOpenSignForm} onClose={onClose} date={date} />,
            }}
          />
          <Dialog
            open={openEditSignForm}
            onClose={onClose}
            dialogConfig={{
              content: <SignForm setOpen={setEditOpenSignForm} date={signDate} signing={signing} onClose={onClose} />,
            }}
          />
          <ConfirmationDialogs
            open={openDeleteSignForm}
            value={undefined}
            onClose={onClose}
            onConfirm={handleDelete}
            dialogConfig={{
              content: <ConfirmAction type={EnumAction.ERROR} actionText={t('confirm.action.delete')} descriptionText={t('warning.action.delete')} />,
              confirmText: t('accept'),
              cancelText: t('button.cancel'),
            }}
          />
          {params.user ? (
            <></>
          ) : (
            <Tooltip title={t('button.newChecking')}>
              <Button className={buttonsStyles.addBtn} onClick={() => setOpenSignForm(true)}>
                <AddIcon />
              </Button>
            </Tooltip>
          )}
        </Box>
      </Grid>
      <Grid item xs={12} sx={{ padding: '0px !important' }}>
        <Table
          columns={SignColumns({ onClick: handleClick, isTools: params.isTools })}
          rows={data}
          loading={loading}
          initialState={{
            sorting: {
              sortModel: [{ field: 'hours', sort: 'desc' }],
            },
          }}
        />
      </Grid>
      <Grid container spacing={2} sx={{ marginTop: '2%' }}>
        <Grid item sx={{ display: 'flex', direction: 'row' }} xs={12}>
          <Badge
            sx={{
              '& .MuiBadge-badge': {
                backgroundColor: Colors.statusPending,
              },
              marginRight: '1%',
            }}
            variant="dot"
          />

          <Typography variant="body1">{t('badge.modified')}</Typography>
        </Grid>
        <Grid item sx={{ display: 'flex', direction: 'row' }} xs={12}>
          <Badge
            sx={{
              '& .MuiBadge-badge': {
                backgroundColor: Colors.statusMissing,
              },
              marginRight: '1%',
            }}
            variant="dot"
          />
          <Typography variant="body1">{t('badge.missed')}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
