/* tslint:disable */
/* eslint-disable */
/**
 * UserManagement microservice (dev)
 * UserManagement microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DepartmentDTO,
  DepartmentInfoDTO,
  MovicodersBadRequestException,
  MovicodersNotFoundException,
  MovicodersServiceException,
} from '../models';
import {
    DepartmentDTOFromJSON,
    DepartmentDTOToJSON,
    DepartmentInfoDTOFromJSON,
    DepartmentInfoDTOToJSON,
    MovicodersBadRequestExceptionFromJSON,
    MovicodersBadRequestExceptionToJSON,
    MovicodersNotFoundExceptionFromJSON,
    MovicodersNotFoundExceptionToJSON,
    MovicodersServiceExceptionFromJSON,
    MovicodersServiceExceptionToJSON,
} from '../models';

export interface CreateDepartmentRequest {
    departmentDTO?: DepartmentDTO;
}

export interface DeleteDepartmentIdRequest {
    departmentId: string;
}

/**
 * 
 */
export class DepartmentsApi extends runtime.BaseAPI {

    /**
     * Create a department
     */
    async createDepartmentRaw(requestParameters: CreateDepartmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DepartmentDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/departments`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DepartmentDTOToJSON(requestParameters.departmentDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DepartmentDTOFromJSON(jsonValue));
    }

    /**
     * Create a department
     */
    async createDepartment(requestParameters: CreateDepartmentRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DepartmentDTO> {
        const response = await this.createDepartmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a department by departmentId
     */
    async deleteDepartmentIdRaw(requestParameters: DeleteDepartmentIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DepartmentDTO>> {
        if (requestParameters.departmentId === null || requestParameters.departmentId === undefined) {
            throw new runtime.RequiredError('departmentId','Required parameter requestParameters.departmentId was null or undefined when calling deleteDepartmentId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/departments/{departmentId}`.replace(`{${"departmentId"}}`, encodeURIComponent(String(requestParameters.departmentId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DepartmentDTOFromJSON(jsonValue));
    }

    /**
     * Delete a department by departmentId
     */
    async deleteDepartmentId(requestParameters: DeleteDepartmentIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DepartmentDTO> {
        const response = await this.deleteDepartmentIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Departments
     */
    async getDepartmentsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DepartmentDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/departments`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DepartmentDTOFromJSON));
    }

    /**
     * Get Departments
     */
    async getDepartments(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DepartmentDTO>> {
        const response = await this.getDepartmentsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get Departments full info
     */
    async getDepartmentsFullInfoRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DepartmentInfoDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/departments/getFullInfo`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DepartmentInfoDTOFromJSON));
    }

    /**
     * Get Departments full info
     */
    async getDepartmentsFullInfo(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DepartmentInfoDTO>> {
        const response = await this.getDepartmentsFullInfoRaw(initOverrides);
        return await response.value();
    }

}
