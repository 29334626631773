import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { GridRenderCellParams, GridRowId } from '@mui/x-data-grid';

export enum EnumOptions {
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  ACTIVE = 'ACTIVE',
}

export interface IOptions {
  id: EnumOptions;
  name: string;
  color: string;
  icon: () => JSX.Element;
}

export interface IOptionsResponse {
  data: GridRenderCellParams<any>;
  origin: EnumOptions;
}

export interface ISelected {
  data: GridRowId[];
}

export const optionsBase: IOptions[] = [
  {
    id: EnumOptions.EDIT,
    name: 'Edit',
    color: '#00bcd4',
    icon: () => <EditIcon sx={{ color: '#00bcd4' }} />,
  },
  {
    id: EnumOptions.DELETE,
    name: 'Delete',
    color: '#f44336',
    icon: () => <DeleteIcon sx={{ color: '#f44336' }} />,
  },
];
export const optionsAdmin = () => {
  return [
    {
      id: 'Admin Example',
      icon: () => <DeleteIcon />,
      color: 'secondary',
    },
  ];
};
