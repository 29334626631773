import { IRepository } from '@movicoders/domain';
import { DomainPage } from '@movicoders/domain/lib/Entities/DomainPage';
import { ObservationDTO, ObservationsApi } from '../../clients/signing-management';

export class ObservationRepository implements IRepository<ObservationDTO, any, any> {
  constructor(private readonly client: ObservationsApi) {}
  get(criteria?: any): Promise<ObservationDTO[]> {
    return this.client.listObservations(criteria);
  }
  getPaginated(pageCriteria?: any): Promise<DomainPage<ObservationDTO>> {
    throw new Error('Method not implemented.');
  }
  getById(id: string): Promise<ObservationDTO> {
    throw new Error('Method not implemented.');
  }
  create(entity: ObservationDTO): Promise<ObservationDTO> {
    throw new Error('Method not implemented.');
  }
  update(entity: ObservationDTO): Promise<ObservationDTO> {
    throw new Error('Method not implemented.');
  }
  remove(id: string): Promise<void> {
    throw new Error('Method not implemented.');
  }
}
/*
export class ObservationRepository implements IRepository<ObservationDTO, GetAllObservationsRequest, any> {
  constructor(private readonly signingsApi: ObservationsApi) {}
  getPaginated(pageCriteria?: any): Promise<DomainPage<ObservationDTO>> {
    throw new Error('Method not implemented.');
  }
  remove(id: string): Promise<void> {
    throw new Error('Method not implemented.');
  }
  get(criteria?: GetAllObservationsRequest | undefined): Promise<ObservationDTO[]> {
    return this.signingsApi.getAllObservations(criteria);
  }
  getById(id: string): Promise<ObservationDTO> {
    throw new Error('Method not implemented.');
  }
  create(entity: ObservationDTO): Promise<ObservationDTO> {
    throw new Error('Method not implemented.');
  }
  update(entity: ObservationDTO): Promise<ObservationDTO> {
    throw new Error('Method not implemented.');
  }
  delete(id: string): Promise<void> {
    throw new Error('Method not implemented.');
  }
}
*/
