import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Grid, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useBtnStyles } from '../../../../styles/buttons.styles';
import { useTablesStyle } from '../../../../styles/tables.styles';
import { ConfirmAction, EnumAction } from '../../../dialog/actions/ConfirmAction';
import { Dialog } from '../../../dialog/Dialog';
import { Table } from '../../../base/table/Table';
import { FormRegisterUser } from '../../forms/register/form-register-user';
import { EmployeeColumns } from './employee-columns';
import { useTableEmployees } from './useTableEmployees';

export const TableEmployees = () => {
  const { t } = useTranslation();
  const tablesStyles = useTablesStyle();
  const buttonsStyles = useBtnStyles();

  const {
    openDeleteData,
    openFormRegister,
    setOpenFormRegister,
    openEditFormRegister,
    user,
    handleClick,
    onClose,
    onDelete,
    dataList,
    loading,
    userService,
  } = useTableEmployees();

  return (
    <Grid container spacing={1} columns={12} direction="column">
      <Grid item xs={12} className={tablesStyles.toolbar}>
        <Box>
          {userService.user?.hasPermission(['MANAGER']) ? (
            <Typography sx={{ fontWeight: 'bold' }}>{String(userService.user.department?.name.toLocaleUpperCase())}</Typography>
          ) : (
            <Typography sx={{ fontWeight: 'bold' }}>{String(t('table.employee.title')).toLocaleUpperCase()}</Typography>
          )}
        </Box>
        <Box>
          <Dialog
            open={openFormRegister}
            onClose={onClose}
            maxWidth={'md'}
            fullWidth={true}
            dialogConfig={{
              content: <FormRegisterUser onClose={onClose} />,
            }}
          />
          <Dialog
            open={openEditFormRegister}
            onClose={onClose}
            maxWidth={'md'}
            fullWidth={true}
            dialogConfig={{
              content: <FormRegisterUser onClose={onClose} user={user!!} />,
            }}
          />
          <Dialog
            open={openDeleteData}
            onClose={onClose}
            maxWidth={'xs'}
            fullWidth={true}
            closeWithX={false}
            onConfirm={() => onDelete()}
            dialogConfig={{
              content: <ConfirmAction type={EnumAction.ERROR} actionText={t('confirm.action.delete')} />,
              confirmText: t('accept'),
              cancelText: t('button.cancel'),
            }}
          />
        </Box>
        {userService.user?.hasPermission(['ADMINISTRATOR']) && (
          <Tooltip title={t('button.newEmployee')}>
            <Button className={buttonsStyles.addBtn} onClick={() => setOpenFormRegister(true)}>
              <AddIcon />
            </Button>
          </Tooltip>
        )}
      </Grid>
      <Grid item xs={12} sx={{ padding: '0px !important' }}>
        <Table
          columns={EmployeeColumns({ onClick: handleClick, isTools: userService.user?.hasPermission(['ADMINISTRATOR']) })}
          rows={dataList}
          loading={loading}
        />
      </Grid>
    </Grid>
  );
};
