import InputIcon from '@mui/icons-material/Input';
import OutputIcon from '@mui/icons-material/Output';
import PlaceIcon from '@mui/icons-material/Place';
import { Badge, IconButton, Tooltip } from '@mui/material';
import { GridColumns, GridRenderCellParams, GridValueFormatterParams, GridValueGetterParams } from '@mui/x-data-grid';
import { Icon, NativeBaseProvider } from 'native-base';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SigningDTO } from '../../../../../clients/signing-management';
import { Observation } from '../../../../../domain/models/Observation';
import { enumToDirectory } from '../../../../../domain/models/vector-icon';
import { timestamp2HumanReadableHour } from '../../../../../helpers/utils';
import { Colors } from '../../../../theme';
import { ColumnOptions } from '../../../base/table/options/column-options';
import { IOptionsResponse } from '../../../base/table/options/types-options';

interface ISignColumns {
  onClick: (props: IOptionsResponse) => void | undefined;
  isTools?: boolean;
}

export const SignColumns = (params: ISignColumns) => {
  const { t } = useTranslation();

  const columns = useMemo<GridColumns<SigningDTO>>(
    () => [
      {
        field: 'type',
        headerName: t('table.signing.type'),
        flex: 1,
        filterable: false,
        valueGetter: (params: GridValueGetterParams) => {
          let data = {
            type: params.row.type,
            state: params.row.state,
          };
          return data;
        },
        renderCell: (params: GridRenderCellParams) =>
          params.value.state === 'DISABLED' ? (
            <>
              {params.value.type === 'IN' ? (
                <Tooltip title={`Disabled ${params.value.type}`}>
                  <InputIcon sx={{ color: Colors.linkGrayText }} />
                </Tooltip>
              ) : (
                <Tooltip title={`Disabled ${params.value.type}`}>
                  <OutputIcon sx={{ color: Colors.linkGrayText }} />
                </Tooltip>
              )}
            </>
          ) : (
            <>
              {params.value.state === 'MODIFIED' && (
                <Badge
                  sx={{
                    '& .MuiBadge-badge': {
                      backgroundColor: Colors.statusPending,
                    },
                  }}
                  variant="dot"
                >
                  {params.value.type === 'IN' ? (
                    <Tooltip title={params.value.type}>
                      <InputIcon sx={{ color: Colors.statusComplete }} />
                    </Tooltip>
                  ) : (
                    <Tooltip title={params.value.type}>
                      <OutputIcon sx={{ color: Colors.statusIncomplete }} />
                    </Tooltip>
                  )}
                </Badge>
              )}
              {params.value.state === 'MISSED' && (
                <Badge
                  sx={{
                    '& .MuiBadge-badge': {
                      backgroundColor: Colors.statusMissing,
                    },
                  }}
                  variant="dot"
                >
                  {params.value.type === 'IN' ? (
                    <Tooltip title={params.value.type}>
                      <InputIcon sx={{ color: Colors.statusComplete }} />
                    </Tooltip>
                  ) : (
                    <Tooltip title={params.value.type}>
                      <OutputIcon sx={{ color: Colors.statusIncomplete }} />
                    </Tooltip>
                  )}
                </Badge>
              )}
              {params.value.state === 'CREATED' && (
                <>
                  {params.value.type === 'IN' ? (
                    <Tooltip title={params.value.type}>
                      <InputIcon sx={{ color: Colors.statusComplete }} />
                    </Tooltip>
                  ) : (
                    <Tooltip title={params.value.type}>
                      <OutputIcon sx={{ color: Colors.statusIncomplete }} />
                    </Tooltip>
                  )}
                </>
              )}
            </>
          ),
      },
      {
        field: 'signDate',
        headerName: t('table.signing.date'),
        type: 'date',
        flex: 0.5,
        filterable: false,
        renderCell: (params: GridRenderCellParams) => <>{new Date(params.value).toLocaleDateString()}</>,
        valueFormatter: (params: GridValueFormatterParams) => `${new Date(params.value).toLocaleDateString()}`,
      },
      {
        field: 'hours',
        headerName: t('table.signing.hours'),
        flex: 0.5,
        type: 'string',
        valueGetter: (params: GridValueGetterParams) => {
          return timestamp2HumanReadableHour(params.row.signDate);
        },
      },
      {
        field: 'observation',
        headerName: t('table.signing.observation'),
        flex: 1,
        filterable: false,
        sortable: false,
        valueFormatter: (params: GridValueFormatterParams<Observation>) => `${params.value?.name || ''}`,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <>
              {params.value && (
                <NativeBaseProvider>
                  <Icon
                    as={enumToDirectory(params.value.icon?.family || 'Entypo')}
                    name={params.value.icon.name}
                    size={'25px'}
                    alignSelf={'left'}
                    color={Colors.primary}
                  />
                </NativeBaseProvider>
              )}
            </>
          );
        },
      },
      {
        field: 'location',
        headerName: t('table.signing.location'),
        flex: 1,
        sortable: false,
        filterable: false,
        valueGetter: (params: GridValueGetterParams) => {
          return `https://www.google.com/maps/?q=${params.row.latitude},${params.row.longitude}`;
        },
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Tooltip title={'Location'}>
              <IconButton
                onClick={() => {
                  window.open(params.value, '_blank');
                }}
              >
                <PlaceIcon />
              </IconButton>
            </Tooltip>
          );
        },
      },
      ...(params.isTools
        ? [
            {
              field: 'actions',
              type: 'actions',
              width: 80,
              getActions: (props: GridRenderCellParams<SigningDTO>) => ColumnOptions({ onClick: params.onClick, props }),
            },
          ]
        : []),
    ],
    []
  );

  return columns;
};
