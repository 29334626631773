import { TYPES } from '@movicoders/domain';
import { useResolve, useSnackbar } from '@movicoders/ui';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import { Box, Divider, FormControl, Grid, IconButton, InputAdornment, Paper, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserUpdatePasswordUseCase } from '../../../../application/user/user';
import { User } from '../../../../domain/models/User';
import { UserService } from '../../../../domain/services/UserService';
import { Colors } from '../../../theme';
import { Avatar } from '../../base/avatar/Avatar';
import { FeedbackBTN } from '../../buttons/feedback/feedback';

interface State {
  password: string;
}

export const ContainerAccount = () => {
  const userService = useResolve<UserService>(TYPES.UserService);
  const [user, _] = useState<User>(userService.user!!);
  const { t } = useTranslation();
  const changeUserPassword = useResolve(UserUpdatePasswordUseCase);
  const [loading, setLoading] = useState(false);
  const { show } = useSnackbar();

  const [values, setValues] = useState<State>({
    password: '',
  });

  const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    event.preventDefault();
    try {
      let password = values.password || '';
      changeUserPassword.execute(password);
      window.setTimeout(() => {
        setLoading(false);
        show(t('user.updatePassword'), 'success');
      }, 1000);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <Grid container padding={'0em 12em 0em 12em'}>
      <Typography variant="h4" color={Colors.primary} sx={{ borderBottom: `1px solid ${Colors.primaryDark}`, marginBottom: '1em' }}>
        {t('toolbar.account')}
      </Typography>
      <Grid item xs={12}>
        <Grid container spacing={3} alignItems="flex-start" gridAutoRows={2}>
          <Grid item xs={4} md={4}>
            <Paper sx={{ padding: '6em', paddingTop: '12em' }}>
              <Grid container direction={'row'} alignItems={'center'}>
                <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                  <Box
                    sx={{
                      transform: 'scale(4)',
                      position: 'relative',
                      top: '-80px',
                      padding: '1px',
                      borderRadius: '50%',
                      borderStyle: 'dashed',
                      borderColor: Colors.primaryOpacity,
                      borderWidth: '1px',
                      transition: 'all 0.3s linear',
                      '&:hover': {
                        borderColor: Colors.primary,
                      },
                    }}
                  >
                    <Avatar name={`${user.name} ${user.surname}`} />
                  </Box>
                </Grid>
                <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                  <Typography variant="subtitle1" fontWeight={'bold'}>{`@${user.username}`}</Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={8}>
            <Paper sx={{ padding: '2em' }}>
              <Box sx={{ display: 'grid', gap: '1em', gridTemplateColumns: 'repeat(2, 1fr)' }}>
                <FormControl>
                  <TextField
                    id="name"
                    label={t('formUser.name')}
                    defaultValue={user.name.toUpperCase()}
                    variant="outlined"
                    sx={{ input: { color: Colors.primaryOpacity } }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
                <FormControl>
                  <TextField
                    id="surname"
                    label={t('formUser.surname')}
                    defaultValue={user.surname.toUpperCase()}
                    variant="outlined"
                    sx={{ input: { color: Colors.primaryOpacity } }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
                <FormControl>
                  <TextField
                    id="email"
                    label={t('formUser.email')}
                    defaultValue={user.email?.toUpperCase()}
                    variant="outlined"
                    sx={{ input: { color: Colors.primaryOpacity } }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
                <FormControl>
                  <TextField
                    id="role"
                    label={t('table.employee.role')}
                    defaultValue={user.role?.toUpperCase()}
                    variant="outlined"
                    sx={{ input: { color: Colors.primaryOpacity } }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
                <FormControl>
                  <TextField
                    id="office"
                    label={t('formUser.office')}
                    defaultValue={user.office?.name.toUpperCase()}
                    variant="outlined"
                    sx={{ input: { color: Colors.primaryOpacity } }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
                <FormControl>
                  <TextField
                    id="department"
                    label={t('formUser.department')}
                    defaultValue={user.department?.name.toUpperCase()}
                    variant="outlined"
                    sx={{ input: { color: Colors.primaryOpacity } }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
              </Box>
              <Divider sx={{ padding: '1em' }} />
            </Paper>

            <br />
            <Paper sx={{ padding: '2em' }}>
              <Typography variant="h6" color={Colors.primary}>
                {t('form.changePassword')}
              </Typography>
              <br />
              <form onSubmit={handleSubmit}>
                <Box sx={{ display: 'grid', gap: '1em', gridTemplateColumns: 'repeat(1, 1fr)' }}>
                  <FormControl>
                    <TextField
                      id="newPassword"
                      label={t('newPassword')}
                      variant="outlined"
                      required
                      size="small"
                      onChange={handleChange('password')}
                      type={'text'}
                    />
                  </FormControl>
                </Box>
                <Box display={'flex'} flexDirection={'row-reverse'}>
                  <FeedbackBTN loading={false} type="submit">
                    {t('button.save')}
                  </FeedbackBTN>
                </Box>
              </form>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
