import { IRepository } from '@movicoders/domain';
import { DomainPage } from '@movicoders/domain/lib/Entities/DomainPage';
import {
  GetCalendarSigningsByUser1Request,
  GetDateRangeSigningsByUserRequest,
  GetSummaryListSigningsByTenantRequest,
  GetSummarySigningsByTenantRequest,
  SigningDTO,
  SigningsApi,
  SigningsManagementApi,
  SigningSummaryDTO,
} from '../../clients/signing-management';

export class SigningManagementRepository implements IRepository<SigningDTO, GetDateRangeSigningsByUserRequest, any> {
  constructor(private readonly client: SigningsManagementApi) {}
  get(criteria?: GetDateRangeSigningsByUserRequest | undefined): Promise<SigningDTO[]> {
    return this.client.getDateRangeSigningsByUser(criteria!!);
  }
  getPaginated(pageCriteria?: any): Promise<DomainPage<SigningDTO>> {
    throw new Error('Method not implemented.');
  }
  getById(id: string): Promise<SigningDTO> {
    throw new Error('Method not implemented.');
  }
  create(entity: SigningDTO): Promise<SigningDTO> {
    throw new Error('Method not implemented.');
  }
  update(entity: SigningDTO): Promise<SigningDTO> {
    throw new Error('Method not implemented.');
  }
  remove(id: string): Promise<void> {
    throw new Error('Method not implemented.');
  }
  getSummary(criteria: GetSummarySigningsByTenantRequest): Promise<Array<SigningSummaryDTO>> {
    return this.client.getSummarySigningsByTenant(criteria);
  }
  getSummaryList(criteria: GetSummaryListSigningsByTenantRequest): Promise<Array<SigningSummaryDTO>> {
    return this.client.getSummaryListSigningsByTenant(criteria);
  }
}
