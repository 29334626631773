import { CalendarPicker, CalendarPickerSkeleton, LocalizationProvider, PickersDayProps } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import esLocale from 'date-fns/locale/es';
import { useState } from 'react';
import { SigningForCalendarDTO } from '../../../../clients/signing-management';
import { UserDTO } from '../../../../clients/user-management';
import { UUIDGenerator } from '../../../../utils/arrayHelper';
import { PickerDays } from './picker-day';

interface ICalendar {
  date: Date;
  data: SigningForCalendarDTO[] | any[];
  onChange: (currentDate: Date) => void;
  onChangeMonth?: (currentDate: Date) => void;
  onSelectDay?: (currentDate: Date) => void;
  isHours?: boolean;
  isOpenDay?: Boolean;
  loading?: boolean;
  user?: UserDTO;
}
export const Calendar = (params: ICalendar) => {
  const [date, _] = useState(params.date || new Date());
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
      <CalendarPicker
        date={date}
        views={['day']}
        onChange={() => {}}
        onMonthChange={(currentDate) => params.onChange?.(currentDate)}
        loading={params.loading}
        renderLoading={() => {
          return <CalendarPickerSkeleton />;
        }}
        showDaysOutsideCurrentMonth={true}
        renderDay={(day: Date, selectedDays: Date[], pickersDayProps: PickersDayProps<Date>) => {
          const isRender = day.getMonth() !== date.getMonth();
          const alterDay = new Date(date.getFullYear(), date.getMonth(), day.getDate());
          const daySame = params.data.find((d) => {
            let dataDay = new Date(d.day);
            if (dataDay.getDate() == alterDay.getDate()) return d;
          });
          return (
            <PickerDays
              key={UUIDGenerator()}
              render={isRender}
              day={day}
              selected={params.date}
              isOpenDay={params.isOpenDay}
              isHours={params.isHours}
              daySame={daySame}
              onChange={params.onChange}
              onSelectDay={params.onSelectDay}
              user={params.user}
            />
          );
        }}
      />
    </LocalizationProvider>
  );
};
