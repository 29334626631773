import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SecurityIcon from '@mui/icons-material/Security';
import { GridActionsCellItem, GridColumns, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar } from '../../../base/avatar/Avatar';
import { EnumOptions, IOptionsResponse } from '../../../base/table/options/types-options';

interface IEmployeeColumns {
  onClick: (props: IOptionsResponse) => void | undefined;
  isTools?: boolean;
}

export const EmployeeColumns = (params: IEmployeeColumns) => {
  const { t } = useTranslation();

  const columns = useMemo<GridColumns<any>>(
    () => [
      {
        field: 'Avatar',
        sortable: false,
        width: 70,
        filterable: false,
        valueGetter: (params: GridValueGetterParams) => {
          return `${params.row.name} ${params.row.surname}`;
        },
        renderCell: (params: GridRenderCellParams) => <Avatar name={params.value} />,
      },
      {
        field: 'name',
        headerName: t('table.employee.name'),
        flex: 1,
        type: 'string',
        valueGetter: (params: GridValueGetterParams) => {
          return `${params.row.name} ${params.row.surname}`;
        },
      },
      {
        field: 'active',
        type: 'boolean',
        headerName: t('table.employee.active'),
        flex: 0.5,
        headerAlign: 'left',
        align: 'left',
      },
      {
        field: 'role',
        type: 'string',
        headerName: t('table.employee.role'),
        flex: 1,
      },
      {
        field: 'office',
        type: 'string',
        headerName: t('table.employee.office'),
        flex: 1,
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.office.name.toLocaleUpperCase();
        },
      },
      {
        field: 'department',
        type: 'string',
        headerName: t('table.employee.department'),
        flex: 1,
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.department.name.toLocaleUpperCase();
        },
      },
      ...(params.isTools
        ? [
            {
              field: 'actions',
              type: 'actions',
              width: 80,
              getActions: (props: GridRenderCellParams<any>) => [
                <GridActionsCellItem
                  icon={<EditIcon />}
                  title={t('edit')}
                  label="Edit"
                  onClick={() => {
                    params.onClick({ origin: EnumOptions.EDIT, data: props });
                  }}
                />,
                <GridActionsCellItem
                  icon={<SecurityIcon />}
                  label={t(`toggle.active.${props.row.active}`) + ` ${t('employee').toLowerCase()}`}
                  showInMenu
                  onClick={() => params.onClick({ origin: EnumOptions.ACTIVE, data: props })}
                />,
                <GridActionsCellItem
                  icon={<DeleteIcon />}
                  label={t('delete')}
                  showInMenu
                  onClick={() => params.onClick({ origin: EnumOptions.DELETE, data: props })}
                />,
              ],
            },
          ]
        : []),
    ],
    []
  );
  return columns;
};
