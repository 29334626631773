import { makeStyles } from '@mui/styles';
import { Sizes } from '../../../../theme';

export interface StyleProps {
  colorBTN: string;
  size?: number;
}

export const signFormStyles = makeStyles({
  titleTypography: {
    fontSize: `calc(${Sizes.title} * 1.5) !important`,
    minWidth: '500px',
    textAlign: 'center',
    letterSpacing: '4px !important',
  },
});

export default signFormStyles;
