/* tslint:disable */
/* eslint-disable */
/**
 * UserManagement microservice (dev)
 * UserManagement microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DepartmentDTO } from './DepartmentDTO';
import {
    DepartmentDTOFromJSON,
    DepartmentDTOFromJSONTyped,
    DepartmentDTOToJSON,
} from './DepartmentDTO';
import type { OfficeDTO } from './OfficeDTO';
import {
    OfficeDTOFromJSON,
    OfficeDTOFromJSONTyped,
    OfficeDTOToJSON,
} from './OfficeDTO';

/**
 * 
 * @export
 * @interface UserDTO
 */
export interface UserDTO {
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    biometry?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    credentials?: string;
    /**
     * 
     * @type {DepartmentDTO}
     * @memberof UserDTO
     */
    department?: DepartmentDTO;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    nfcId?: string;
    /**
     * 
     * @type {OfficeDTO}
     * @memberof UserDTO
     */
    office?: OfficeDTO;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    role?: UserDTORoleEnum;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    surname?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    tenant?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    username?: string;
}


/**
 * @export
 */
export const UserDTORoleEnum = {
    Administrator: 'ADMINISTRATOR',
    Manager: 'MANAGER',
    Worker: 'WORKER'
} as const;
export type UserDTORoleEnum = typeof UserDTORoleEnum[keyof typeof UserDTORoleEnum];


/**
 * Check if a given object implements the UserDTO interface.
 */
export function instanceOfUserDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserDTOFromJSON(json: any): UserDTO {
    return UserDTOFromJSONTyped(json, false);
}

export function UserDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'biometry': !exists(json, 'biometry') ? undefined : json['biometry'],
        'credentials': !exists(json, 'credentials') ? undefined : json['credentials'],
        'department': !exists(json, 'department') ? undefined : DepartmentDTOFromJSON(json['department']),
        'email': !exists(json, 'email') ? undefined : json['email'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'nfcId': !exists(json, 'nfcId') ? undefined : json['nfcId'],
        'office': !exists(json, 'office') ? undefined : OfficeDTOFromJSON(json['office']),
        'password': !exists(json, 'password') ? undefined : json['password'],
        'role': !exists(json, 'role') ? undefined : json['role'],
        'surname': !exists(json, 'surname') ? undefined : json['surname'],
        'tenant': !exists(json, 'tenant') ? undefined : json['tenant'],
        'username': !exists(json, 'username') ? undefined : json['username'],
    };
}

export function UserDTOToJSON(value?: UserDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'biometry': value.biometry,
        'credentials': value.credentials,
        'department': DepartmentDTOToJSON(value.department),
        'email': value.email,
        'id': value.id,
        'name': value.name,
        'nfcId': value.nfcId,
        'office': OfficeDTOToJSON(value.office),
        'password': value.password,
        'role': value.role,
        'surname': value.surname,
        'tenant': value.tenant,
        'username': value.username,
    };
}

