import { Box, Grid } from '@mui/material';
import { Paper } from '../../components/base/paper/Paper';
import { Container } from '../../components/container/Container';
import { GraphicsDaysBad } from '../../components/signing/graphics/GraphicsDaysBad';
import { GraphicsDaysGood } from '../../components/signing/graphics/GraphicsDaysGood';
import { GraphicsSigningMissed } from '../../components/signing/graphics/GraphicsSigningMissed';
import { TimeLine } from '../../components/signing/timeline/TimeLine';
import { BoxInfo } from '../../components/user/box-information/BoxInfo';
import { Colors } from '../../theme';

export const Home = () => {
  return (
    <Container>
      <Box sx={{ minHeight: '100%' }}>
        <Grid container wrap="nowrap" gap={2} minHeight={'80vh'}>
          <Grid item xs={8} sx={{ minWidth: '50em' }}>
            <Grid container gap={2} direction={'column'} minHeight={'100%'}>
              <Grid item xs={12}>
                <Paper sx={{ backgroundColor: Colors.primaryDark, minWidth: '45em' }}>
                  <BoxInfo />
                </Paper>
              </Grid>
              <Grid container gap={2} columns={12} direction={'row'} justifyContent={'space-between'}>
                <Grid item xs={3}>
                  <Paper sx={{ height: '14em' }}>
                    <GraphicsDaysGood />
                  </Paper>
                </Grid>
                <Grid item xs={3}>
                  <Paper sx={{ height: '14em' }}>
                    <GraphicsSigningMissed />
                  </Paper>
                </Grid>
                <Grid item xs={5}>
                  <Paper sx={{ height: '14em' }}>
                    <GraphicsDaysBad />
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4} minHeight={'100%'}>
            <Box maxWidth={'20em'} sx={{ position: 'relative', left: '80px', top: '150px', transform: 'scale(2.0)' }}>
              <TimeLine />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
