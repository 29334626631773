import { Button, Grid, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import esLocale from 'date-fns/locale/es';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SigningDTO } from '../../../../../clients/signing-management';
import { timestamp2HumanReadableDate, timestamp2HumanReadableHour } from '../../../../../helpers/utils';
import { useBtnStyles } from '../../../../styles/buttons.styles';
import { Form } from '../../../base/form/Form';
import { InputTimePicker } from '../../../base/form/input/input-time-picker';
import { FeedbackBTN } from '../../../buttons/feedback/feedback';

import { SignFormObservation } from './observations/sign-form-observation';
import { SignFormPicker } from './picker/sign-form-picker';
import { signFormStyles } from './sign-form.styles';
import { useSignFormViewModel } from './useSignFormViewModel';

interface ISignForm {
  date?: Date;
  signing?: SigningDTO;
  setOpen?: (open: boolean) => void;
  onClose?: () => void;
}

export const SignForm = (params: ISignForm) => {
  const { date, values, handleSubmit, handleChange, handleChangeHour, loading } = useSignFormViewModel(params);

  const { t } = useTranslation();
  const classes = useBtnStyles();
  const signFormClasses = signFormStyles();

  useEffect(() => {
    getCurrentUserLocation();
  }, []);

  const getCurrentUserLocation = () => {
    navigator.geolocation.watchPosition(
      (position) => {
        values.latitude = position.coords.latitude;
        values.longitude = position.coords.longitude;
      },
      (error) => {
        console.log(error);
      },
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      }
    );
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container direction={'column'} gap={5} sx={{ justifyContent: 'center', alignItems: 'center' }}>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
          <>
            <Typography className={signFormClasses.titleTypography}>{timestamp2HumanReadableDate(values.signDate!!)}</Typography>
            <SignFormPicker type={values.type!!} onChange={handleChange} />
            <SignFormObservation observation={values.observation} onChange={handleChange} />
            {params.date ? (
              <InputTimePicker label={''} value={timestamp2HumanReadableHour(date.getTime()) || '00:00'} onChange={handleChangeHour} />
            ) : (
              <InputTimePicker disabled={true} label={''} value={timestamp2HumanReadableHour(date.getTime()) || '00:00'} onChange={handleChangeHour} />
            )}
            {params.signing ? (
              <Grid container spacing={2} direction={'row'} justifyContent={'center'} alignItems={'center'} gap={2}>
                <Button onClick={() => params.onClose?.()} className={classes.cancelBtn}>
                  {t('button.cancel')}
                </Button>
                <Button onClick={handleSubmit} className={classes.confirmBtn}>
                  {t('button.save')}
                </Button>
              </Grid>
            ) : (
              <FeedbackBTN type="submit" loading={loading}>
                {t('button.checking')}
              </FeedbackBTN>
            )}
          </>
        </LocalizationProvider>
      </Grid>
    </Form>
  );
};
