import { useListViewModel } from '@movicoders/ui';
import { Grid } from '@mui/material';
import { useEffect } from 'react';
import { ObservationDTO } from '../../../../../../clients/signing-management';
import { ObservationRepository } from '../../../../../../infrastructure/repository/ObservationRepository';
import { UUIDGenerator } from '../../../../../../utils/arrayHelper';
import { SignObservationBT } from '../../../../buttons/sign-observation/sign-observation';

interface ISignFormObservation {
  observation?: ObservationDTO;
  onChange?: (e: any) => void;
}

export const SignFormObservation = (params: ISignFormObservation) => {
  const { data, fetch } = useListViewModel(ObservationRepository);

  useEffect(() => {
    fetch({});
  }, []);

  const selector = (observation: ObservationDTO) => {
    if (params.observation?.id === observation.id) {
      params.onChange?.({ target: { name: 'observation', value: undefined } });
    } else {
      params.onChange?.({ target: { name: 'observation', value: observation } });
    }
  };

  return (
    <Grid
      key={UUIDGenerator()}
      sx={{
        display: 'flex',
        width: '70%',
        justifyContent: 'space-evenly',
      }}
    >
      {data.map((item) => (
        <SignObservationBT
          icon={item?.icon}
          onClick={() => selector(item)}
          isSelected={params.observation?.id === item?.id ? true : false}
          key={UUIDGenerator()}
          value={item?.name}
        />
      ))}
    </Grid>
  );
};
