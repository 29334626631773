import { IRepository } from '@movicoders/domain';
import { DomainPage } from '@movicoders/domain/lib/Entities/DomainPage';
import { CompaniesApi, CompanyDTO } from '../../clients/user-management';

export class CompanyRepository implements IRepository<CompanyDTO, CompanyDTO, any> {
  constructor(private readonly companiesApi: CompaniesApi) {}
  get(criteria?: CompanyDTO | undefined): Promise<CompanyDTO[]> {
    return this.companiesApi.getCompanies();
  }
  getPaginated(pageCriteria?: any): Promise<DomainPage<CompanyDTO>> {
    throw new Error('Method not implemented.');
  }
  getById(id: string): Promise<CompanyDTO> {
    return this.companiesApi.getCompany({ companyId: id });
  }
  create(entity: CompanyDTO): Promise<CompanyDTO> {
    throw new Error('Method not implemented.');
  }
  update(entity: CompanyDTO): Promise<CompanyDTO> {
    throw new Error('Method not implemented.');
  }
  remove(id: string): Promise<void> {
    throw new Error('Method not implemented.');
  }
  getByTenant(): Promise<CompanyDTO> {
    return this.companiesApi.getCompanyByTenant();
  }
}
