import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import SettingsIcon from '@mui/icons-material/Settings';
import DashboardIcon from '@mui/icons-material/Dashboard';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

export const pathIconsCommons: any = [
  {
    name: 'toolbar.dashboard',
    icon: () => <DashboardIcon />,
    path: '/',
    permissions: [],
  },
  {
    name: 'toolbar.history',
    icon: () => <WorkHistoryIcon />,
    path: '/history',
    permissions: [],
  },
  {
    name: 'toolbar.calendar',
    icon: () => <CalendarMonthIcon />,
    path: '/calendar',
    permissions: [],
  },
];

export const pathIconsUser: any = [
  {
    name: 'toolbar.settings',
    icon: () => <SettingsIcon />,
    path: '/settings',
    permissions: [],
  },
];

export const pathAlterUser: any = [
  {
    name: 'toolbar.historyemployee',
    icon: () => <PermContactCalendarIcon />,
    path: '/historyEmployee',
    permissions: ['ADMINISTRATOR'],
  },
  {
    name: 'toolbar.employees',
    icon: () => <PeopleAltIcon />,
    path: '/employees',
    permissions: ['ADMINISTRATOR', 'MANAGER'],
  },
];

export const pathIconsAvatar: any = [
  {
    name: 'toolbar.account',
    icon: () => <FolderSharedIcon />,
    path: '/account',
    permissions: [],
  },
  {
    name: 'toolbar.logout',
    icon: () => <SettingsIcon />,
    path: '/login',
    permissions: [],
  },
];
