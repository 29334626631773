import { Grid } from '@mui/material';
import { useState } from 'react';
import { UserDTO } from '../../../clients/user-management';
import { SigningManagementCalendarRepository } from '../../../infrastructure/repository/SigningManagementCalendarRepository';
import { Paper } from '../../components/base/paper/Paper';
import { Container } from '../../components/container/Container';
import { SingleCalendar } from '../../components/signing/calendar/single-calendar/singleCalendar';
import { TableSummary } from '../../components/user/tables/summary/TableSummary';

export const HistoryEmployee = () => {
  const [date, setDate] = useState(new Date());
  const [user, setUser] = useState<UserDTO>();
  return (
    <Container>
      <Grid container spacing={0} gap={0} flexDirection={'row'} alignItems={'center'} justifyContent={'flex-end'}>
        <Grid item xs={12} display={'flex'} justifyContent={'flex-end'}></Grid>
      </Grid>
      <Grid container spacing={1} gap={0}>
        <Grid item xs={4} minWidth={'25em'}>
          <Paper sx={{ padding: '2em !important' }}>
            <SingleCalendar openDay={true} date={date} setDate={setDate} repository={SigningManagementCalendarRepository} isToolbar={true} user={user} />
          </Paper>
        </Grid>
        <Grid item xs={8}>
          <Paper sx={{ padding: '2em !important' }}>
            <TableSummary setUser={setUser} date={date} setDate={setDate} />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};
