import { FormControlLabel, FormGroup, Grid, Switch, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { numberOfHours } from '../../../../helpers/utils';

interface ICalendarTools {
  isHours: boolean;
  setIsHours: (isHours: boolean) => void;
  hours?: number;
}

export const CalendarTools = (params: ICalendarTools) => {
  const { t } = useTranslation();
  const hoursSwitch = (event: React.ChangeEvent<HTMLInputElement>) => params.setIsHours(event.target.checked);

  return (
    <Grid container justifyContent="flex-end" alignItems={'center'}>
      <FormGroup row>
        <FormControlLabel control={<Switch checked={params.isHours} onChange={hoursSwitch} />} label={t('calendar.hours')} />
      </FormGroup>
      <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
        {params.isHours && `${t('table.signing.totalHours')}: ` + params.hours && `${t('table.signing.totalHours')}: ` + numberOfHours(params.hours!!)}
      </Typography>
    </Grid>
  );
};
