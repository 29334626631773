import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { Grid, LinearProgress, Typography } from '@mui/material';
import { Colors } from '../../../theme';

interface IProgressBar {
  series: number[];
  labels: string[];
  colors: string[];
  colorsGradient?: string[];
  number?: number;
  buffer?: number;
}

export const ProgressBars = (params: IProgressBar) => {
  const iconSize = '6em';
  return (
    <Grid container alignItems={'center'} height="10em">
      <Grid item xs={12}>
        <Grid container alignItems={'center'} gap={2}>
          <Typography gutterBottom component="div" color={Colors.paperDark} fontWeight={'bold'} fontSize={'20px'}>
            {`${params.labels[0]}`}
          </Typography>
          <Typography gutterBottom component="div" color={Colors.linkGrayText} fontWeight={'bold'} fontSize={'20px'}>
            {`${params.number!! <= 4 ? params.number : 4}/${params.buffer} `}
          </Typography>
          {params.number!! == 0 && <SentimentSatisfiedAltIcon sx={{ fontSize: iconSize, color: Colors.statusComplete }} />}
          {params.number!! == 1 && <SentimentSatisfiedIcon sx={{ fontSize: iconSize, color: Colors.statusMissing }} />}
          {params.number!! == 2 && <SentimentNeutralIcon sx={{ fontSize: iconSize, color: Colors.statusMissing }} />}
          {params.number!! == 3 && <SentimentDissatisfiedIcon sx={{ fontSize: iconSize, color: Colors.statusIncomplete }} />}
          {params.number!! >= 4 && <SentimentVeryDissatisfiedIcon sx={{ fontSize: iconSize, color: Colors.statusIncomplete }} />}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <LinearProgress
          variant="determinate"
          value={(params.series[0] * 100) / params.buffer!!}
          sx={{
            height: 10,
            borderRadius: 5,
            bgcolor: Colors.darkGray,
            color: 'params.colors[0],',
          }}
        />
      </Grid>
    </Grid>
  );
};
