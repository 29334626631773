/* tslint:disable */
/* eslint-disable */
/**
 * SigningManagement microservice (dev)
 * SigningManagement microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DepartmentDTO } from './DepartmentDTO';
import {
    DepartmentDTOFromJSON,
    DepartmentDTOFromJSONTyped,
    DepartmentDTOToJSON,
} from './DepartmentDTO';
import type { OfficeDTO } from './OfficeDTO';
import {
    OfficeDTOFromJSON,
    OfficeDTOFromJSONTyped,
    OfficeDTOToJSON,
} from './OfficeDTO';
import type { SigningDTO } from './SigningDTO';
import {
    SigningDTOFromJSON,
    SigningDTOFromJSONTyped,
    SigningDTOToJSON,
} from './SigningDTO';

/**
 * 
 * @export
 * @interface SigningSummaryDTO
 */
export interface SigningSummaryDTO {
    /**
     * 
     * @type {DepartmentDTO}
     * @memberof SigningSummaryDTO
     */
    department?: DepartmentDTO;
    /**
     * 
     * @type {string}
     * @memberof SigningSummaryDTO
     */
    email?: string;
    /**
     * 
     * @type {number}
     * @memberof SigningSummaryDTO
     */
    hours?: number;
    /**
     * 
     * @type {string}
     * @memberof SigningSummaryDTO
     */
    name?: string;
    /**
     * 
     * @type {OfficeDTO}
     * @memberof SigningSummaryDTO
     */
    office?: OfficeDTO;
    /**
     * 
     * @type {string}
     * @memberof SigningSummaryDTO
     */
    role?: SigningSummaryDTORoleEnum;
    /**
     * 
     * @type {Array<SigningDTO>}
     * @memberof SigningSummaryDTO
     */
    signings?: Array<SigningDTO>;
    /**
     * 
     * @type {string}
     * @memberof SigningSummaryDTO
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof SigningSummaryDTO
     */
    surname?: string;
    /**
     * 
     * @type {string}
     * @memberof SigningSummaryDTO
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof SigningSummaryDTO
     */
    username?: string;
}


/**
 * @export
 */
export const SigningSummaryDTORoleEnum = {
    Administrator: 'ADMINISTRATOR',
    Manager: 'MANAGER',
    Worker: 'WORKER'
} as const;
export type SigningSummaryDTORoleEnum = typeof SigningSummaryDTORoleEnum[keyof typeof SigningSummaryDTORoleEnum];


/**
 * Check if a given object implements the SigningSummaryDTO interface.
 */
export function instanceOfSigningSummaryDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SigningSummaryDTOFromJSON(json: any): SigningSummaryDTO {
    return SigningSummaryDTOFromJSONTyped(json, false);
}

export function SigningSummaryDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SigningSummaryDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'department': !exists(json, 'department') ? undefined : DepartmentDTOFromJSON(json['department']),
        'email': !exists(json, 'email') ? undefined : json['email'],
        'hours': !exists(json, 'hours') ? undefined : json['hours'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'office': !exists(json, 'office') ? undefined : OfficeDTOFromJSON(json['office']),
        'role': !exists(json, 'role') ? undefined : json['role'],
        'signings': !exists(json, 'signings') ? undefined : ((json['signings'] as Array<any>).map(SigningDTOFromJSON)),
        'status': !exists(json, 'status') ? undefined : json['status'],
        'surname': !exists(json, 'surname') ? undefined : json['surname'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'username': !exists(json, 'username') ? undefined : json['username'],
    };
}

export function SigningSummaryDTOToJSON(value?: SigningSummaryDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'department': DepartmentDTOToJSON(value.department),
        'email': value.email,
        'hours': value.hours,
        'name': value.name,
        'office': OfficeDTOToJSON(value.office),
        'role': value.role,
        'signings': value.signings === undefined ? undefined : ((value.signings as Array<any>).map(SigningDTOToJSON)),
        'status': value.status,
        'surname': value.surname,
        'userId': value.userId,
        'username': value.username,
    };
}

