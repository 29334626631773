import { Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { SigningDTOTypeEnum } from '../../../../../../clients/signing-management';
import { SignOptionBT } from '../../../../buttons/sign-option/sign-option';
import { signFormPickerStyle } from './sign-form-picker.styles';
import { useTranslation } from 'react-i18next';
interface ISignFormPickerProps {
  type: SigningDTOTypeEnum;
  onChange: (e: any) => void;
}

export const SignFormPicker = (params: ISignFormPickerProps) => {
  const classes = signFormPickerStyle();
  const { t } = useTranslation();
  const [activeSign, setActiveSign] = useState(params.type === SigningDTOTypeEnum.In);

  const selector = (type: string) => {
    let e = { target: { name: 'type', value: '' } };
    if (type === SigningDTOTypeEnum.In) {
      setActiveSign(true);
      e.target.value = SigningDTOTypeEnum.In;
    }
    if (type === SigningDTOTypeEnum.Out) {
      setActiveSign(false);
      e.target.value = SigningDTOTypeEnum.Out;
    }
    params.onChange(e);
  };

  return (
    <Grid container className={classes.grid} justifyContent={'center'} alignItems={'center'} direction={'row'} spacing={4}>
      <Grid item xs={6} display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
        <SignOptionBT color={'hsl(123, 80%, 44%)'} onClick={(value: string) => selector(value)} value={SigningDTOTypeEnum.In} isSelected={activeSign}>
          <Typography>{t('button.entry')}</Typography>
        </SignOptionBT>
      </Grid>
      <Grid item xs={6} display={'flex'} alignItems={'center'} justifyContent={'flex-start'}>
        <SignOptionBT color={'hsl(0, 80%, 44%)'} onClick={(value: string) => selector(value)} value={SigningDTOTypeEnum.Out} isSelected={!activeSign}>
          <Typography>{t('button.exit')}</Typography>
        </SignOptionBT>
      </Grid>
    </Grid>
  );
};
