import { IRepository } from '@movicoders/domain';
import { useListViewModel } from '@movicoders/ui';
import { ObjectType } from '@movicoders/ui/lib/ViewModels/types';
import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { UserDTO } from '../../../../../clients/user-management';
import { Calendar } from '../../../base/calendar/Calendar';
import { CalendarTools } from '../calendar-tools';

interface ISingleCalendar {
  date?: Date;
  setDate?: (date: Date) => void;
  openDay?: boolean;
  isToolbar?: boolean;
  repository: ObjectType<IRepository<any, any, any>>;
  user?: UserDTO;
}

export const SingleCalendar = (params: ISingleCalendar) => {
  const [date, setDate] = useState(params.date || new Date());
  const [isHours, setIsHours] = useState(false);

  const { data, fetch, loading } = useListViewModel(params.repository);
  const windowWidth = window.innerWidth;

  const getSignings = () => {
    const from = new Date(date.getFullYear(), date.getMonth(), 1).setHours(0, 0, 0, 1);
    const to = new Date(date.getFullYear(), date.getMonth() + 1, 0).setHours(23, 59, 59, 999);
    fetch({
      from: from.valueOf(),
      to: to.valueOf(),
      userId: params.user?.id,
    });
  };

  const onChange = (currentDate: Date) => {
    date.setMonth(currentDate.getMonth());
    date.setDate(currentDate.getDate());
    setDate(date);
    params.setDate!! && params.setDate(currentDate);
    getSignings();
  };

  const sumHours = (signings: any[]) => {
    let sum = 0;
    signings.forEach((signing) => {
      sum += signing.hours;
    });
    return sum;
  };

  useEffect(() => {
    params.date && date.setTime(params.date.getTime());
    getSignings();
  }, [params.date, params.user]);

  return (
    <Grid container>
      <Grid item xs={12}>
        {params.isToolbar && <CalendarTools isHours={isHours} setIsHours={setIsHours} hours={sumHours(data)} />}
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          marginTop: '2em',
          marginBottom: '2em',
          '&>div': {
            transform: `scale(${windowWidth < 800 ? 1 : 1.3})`,
          },
        }}
      >
        <Calendar date={date} data={data} loading={loading} onChange={onChange} isHours={isHours} isOpenDay={params.openDay} user={params.user} />
      </Grid>
    </Grid>
  );
};
