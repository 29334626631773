import { Button, Tooltip } from '@mui/material';
import { Icon, NativeBaseProvider } from 'native-base';
import { useState } from 'react';
import { IconDTO } from '../../../../clients/signing-management';
import { enumToDirectory, VectorDirectory, VectorIcon } from '../../../../domain/models/vector-icon';
import { UUIDGenerator } from '../../../../utils/arrayHelper';
import { Colors } from '../../../theme';
import { signObservationsStyles } from './sign-observation.styles';

interface ISignObservationBT {
  icon: IconDTO | undefined;
  onClick: (type: string) => void;
  isSelected: boolean;
  children?: React.ReactNode;
  value?: string;
}
export const SAMPLE_ICON_DATA = [
  new VectorIcon('a1', 'frown', VectorDirectory.AntDesign),
  new VectorIcon('b2', 'swarm', VectorDirectory.Entypo),
  new VectorIcon('c3', 'eye', VectorDirectory.Entypo),
];
export const SignObservationBT = (params: ISignObservationBT) => {
  const [value, setValue] = useState(params.value || '');
  const [icon, setIcon] = useState(params.icon || undefined);

  const classes = signObservationsStyles();

  const DEFAULT_DIMS = {
    size: '30px',
  };
  return (
    <Tooltip title={value} key={UUIDGenerator()}>
      <Button
        value={value}
        variant="contained"
        color="primary"
        onClick={() => {
          params.onClick(value);
        }}
        className={`${classes.button} ` + (params.isSelected ? `${classes.selected}` : '')}
      >
        {icon && (
          <NativeBaseProvider>
            <Icon
              as={enumToDirectory(icon?.family || 'MATERIALCOMMUNITYICONS')}
              name={icon.name}
              color={params.isSelected ? Colors.secondary : Colors.whiteText}
              size={DEFAULT_DIMS.size}
              alignSelf={'center'}
            />
          </NativeBaseProvider>
        )}
        {params.children}
      </Button>
    </Tooltip>
  );
};
