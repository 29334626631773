/* tslint:disable */
/* eslint-disable */
/**
 * SigningManagement microservice (dev)
 * SigningManagement microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ObservationDTO } from './ObservationDTO';
import {
    ObservationDTOFromJSON,
    ObservationDTOFromJSONTyped,
    ObservationDTOToJSON,
} from './ObservationDTO';

/**
 * 
 * @export
 * @interface SigningDTO
 */
export interface SigningDTO {
    /**
     * 
     * @type {string}
     * @memberof SigningDTO
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof SigningDTO
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof SigningDTO
     */
    longitude?: number;
    /**
     * 
     * @type {ObservationDTO}
     * @memberof SigningDTO
     */
    observation?: ObservationDTO;
    /**
     * 
     * @type {string}
     * @memberof SigningDTO
     */
    place?: SigningDTOPlaceEnum;
    /**
     * 
     * @type {number}
     * @memberof SigningDTO
     */
    signDate?: number;
    /**
     * 
     * @type {string}
     * @memberof SigningDTO
     */
    state?: SigningDTOStateEnum;
    /**
     * 
     * @type {string}
     * @memberof SigningDTO
     */
    type?: SigningDTOTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SigningDTO
     */
    user?: string;
}


/**
 * @export
 */
export const SigningDTOPlaceEnum = {
    Office: 'OFFICE',
    Ooo: 'OOO'
} as const;
export type SigningDTOPlaceEnum = typeof SigningDTOPlaceEnum[keyof typeof SigningDTOPlaceEnum];

/**
 * @export
 */
export const SigningDTOStateEnum = {
    Created: 'CREATED',
    Disabled: 'DISABLED',
    Missed: 'MISSED',
    Modified: 'MODIFIED'
} as const;
export type SigningDTOStateEnum = typeof SigningDTOStateEnum[keyof typeof SigningDTOStateEnum];

/**
 * @export
 */
export const SigningDTOTypeEnum = {
    In: 'IN',
    Out: 'OUT'
} as const;
export type SigningDTOTypeEnum = typeof SigningDTOTypeEnum[keyof typeof SigningDTOTypeEnum];


/**
 * Check if a given object implements the SigningDTO interface.
 */
export function instanceOfSigningDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SigningDTOFromJSON(json: any): SigningDTO {
    return SigningDTOFromJSONTyped(json, false);
}

export function SigningDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SigningDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
        'observation': !exists(json, 'observation') ? undefined : ObservationDTOFromJSON(json['observation']),
        'place': !exists(json, 'place') ? undefined : json['place'],
        'signDate': !exists(json, 'signDate') ? undefined : json['signDate'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'user': !exists(json, 'user') ? undefined : json['user'],
    };
}

export function SigningDTOToJSON(value?: SigningDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'latitude': value.latitude,
        'longitude': value.longitude,
        'observation': ObservationDTOToJSON(value.observation),
        'place': value.place,
        'signDate': value.signDate,
        'state': value.state,
        'type': value.type,
        'user': value.user,
    };
}

