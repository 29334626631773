import { makeStyles } from '@mui/styles';

export const useTablesStyle = makeStyles({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1em !important',
    paddingRight: '2em !important',
    border: `1px solid rgba(224, 224, 224, 1) !important`,
    borderRadius: '4px !important',
    /* backgroundColor: Colors.primaryDark, */
  },
});
