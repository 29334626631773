import { useListViewModel } from '@movicoders/ui';
import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { Box, Typography } from '@mui/material';
import { Icon, NativeBaseProvider } from 'native-base';
import { useEffect } from 'react';
import { SigningDTO } from '../../../../clients/signing-management';
import { enumToDirectory } from '../../../../domain/models/vector-icon';
import { timestamp2HumanReadableHour } from '../../../../helpers/utils';
import { SigningRepository } from '../../../../infrastructure/repository/SigningRepository';
import { UUIDGenerator } from '../../../../utils/arrayHelper';
import { Colors } from '../../../theme';

export const TimeLine = () => {
  const { data, fetch } = useListViewModel(SigningRepository);

  const getSignings = () => {
    const date = new Date();
    let from = date.setHours(0, 0, 0, 1);
    let to = date.setHours(23, 59, 59, 999);
    date.setHours(new Date().getHours(), new Date().getMinutes(), new Date().getSeconds(), new Date().getMilliseconds());
    fetch({
      from: from.valueOf(),
      to: to.valueOf(),
    });
  };

  useEffect(() => {
    getSignings();
  }, []);

  return (
    <Box>
      <Timeline position="alternate" key={UUIDGenerator()}>
        {(data as SigningDTO[])
          .sort((a, b) => b.signDate!! - a.signDate!!)
          .map((item, index) => {
            if (index < 4) {
              return (
                <TimelineItem key={UUIDGenerator()}>
                  <TimelineSeparator>
                    <TimelineDot color={item.type == 'IN' ? 'primary' : 'error'} sx={{ boxShadow: '0px 4px 4px 0px rgb(50 65 145 / 50%) !important' }}>
                      {item.observation && (
                        <NativeBaseProvider>
                          <Icon as={enumToDirectory(item.observation.icon?.family || 'Entypo')} name={item.observation.icon?.name} color="white" />
                        </NativeBaseProvider>
                      )}
                    </TimelineDot>
                    <TimelineConnector sx={{ backgroundColor: `${Colors.primaryOpacity} !important` }} />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Typography variant="caption" fontWeight={'bold'}>
                      {timestamp2HumanReadableHour(Number(item.signDate))}
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
              );
            }
          })}
      </Timeline>
    </Box>
  );
};
