/* tslint:disable */
/* eslint-disable */
/**
 * UserManagement microservice (dev)
 * UserManagement microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserBasicDTO } from './UserBasicDTO';
import {
    UserBasicDTOFromJSON,
    UserBasicDTOFromJSONTyped,
    UserBasicDTOToJSON,
} from './UserBasicDTO';

/**
 * 
 * @export
 * @interface OfficeInfoDTO
 */
export interface OfficeInfoDTO {
    /**
     * 
     * @type {string}
     * @memberof OfficeInfoDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeInfoDTO
     */
    name?: string;
    /**
     * 
     * @type {Array<UserBasicDTO>}
     * @memberof OfficeInfoDTO
     */
    users?: Array<UserBasicDTO>;
}

/**
 * Check if a given object implements the OfficeInfoDTO interface.
 */
export function instanceOfOfficeInfoDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OfficeInfoDTOFromJSON(json: any): OfficeInfoDTO {
    return OfficeInfoDTOFromJSONTyped(json, false);
}

export function OfficeInfoDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfficeInfoDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'users': !exists(json, 'users') ? undefined : ((json['users'] as Array<any>).map(UserBasicDTOFromJSON)),
    };
}

export function OfficeInfoDTOToJSON(value?: OfficeInfoDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'users': value.users === undefined ? undefined : ((value.users as Array<any>).map(UserBasicDTOToJSON)),
    };
}

