
import { makeStyles } from '@mui/styles';
import { Colors, Sizes } from '../theme';

export interface StyleProps {
  colorBTN: string;
  size?: number;
}

export const useBtnStyles = makeStyles({
  primaryBtn: {
    backgroundColor: Colors.primary + ' !important',
    color: Colors.whiteText + ' !important',
    borderRadius: '50px !important',
    textTransform: 'none',
    letterSpacing: '2%',
    width: `calc(${Sizes.widthCircleBtn} * 3.5)`,
    /* width: `calc(${Sizes.widthCircleBtn} * 6.5)`, */
    height: `calc(${Sizes.heightCircleBtn} * 3.5)`,
    boxShadow: `0px 4px 4px 0px ${Colors.primaryOpacity}`,
    '&:hover': {
      backgroundColor: Colors.primaryDark + ' !important',
      color: Colors.whiteText + ' !important',
    },
  },

  confirmBtn: {
    backgroundColor: Colors.primary + ' !important',
    color: Colors.whiteText + ' !important',
    borderRadius: '5px' + ' !important',
    textTransform: 'none',
    letterSpacing: '2%' + ' !important',
    width: `calc(${Sizes.widthBtn} * 1.5)`,
    height: `calc(${Sizes.heightBtn} * 1.5)`,
    boxShadow: `0px 4px 4px 0px ${Colors.primaryOpacity}`,
    '&:hover': {
      backgroundColor: Colors.primaryDark + ' !important',
      color: Colors.whiteText + ' !important',
    },
  },

  cancelBtn: {
    backgroundColor: Colors.error + ' !important',
    color: Colors.whiteText + ' !important',
    borderRadius: '5px' + ' !important',
    textTransform: 'none',
    letterSpacing: '2%' + ' !important',
    width: `calc(${Sizes.widthBtn} * 1.5)`,
    height: `calc(${Sizes.heightBtn} * 1.5)`,
    boxShadow: `0px 4px 4px 0px ${Colors.errorOpacity}`,
    '&:hover': {
      backgroundColor: Colors.errorDark + ' !important',
      color: Colors.whiteText + ' !important',
    },
  },

  addBtn: {
    color: Colors.whiteText + ' !important',
    backgroundColor: Colors.primary + ' !important',
    '&:hover': {
      backgroundColor: Colors.primaryDark + ' !important',
    },
  },
  addTopBtn: {
    color: Colors.primary + ' !important',
    backgroundColor: Colors.statusEmpty + ' !important',
    '&:hover': {
      backgroundColor: Colors.linkGrayText + ' !important',
    },
  },
});
