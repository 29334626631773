import * as XLSX from 'xlsx-js-style';
import { SigningSummaryDTO } from '../clients/signing-management';
import { CompanyDTO } from '../clients/user-management';
import { numberOfHours, timestamp2HumanReadableDateMonth, timestamp2HumanReadableHour } from './utils';

export const userSheet = (data: SigningSummaryDTO, companyInfo: CompanyDTO, date?: Date): XLSX.WorkSheet => {
  const styleTitle = { font: { bold: true } };
  const styleBorder = {
    top: {
      border: { top: { style: 'thin' } },
    },
    topRight: {
      border: { top: { style: 'thin' }, right: { style: 'thin' } },
    },
    right: {
      border: { right: { style: 'thin' } },
    },
    bottomRight: {
      border: { bottom: { style: 'thin' }, right: { style: 'thin' } },
    },
    bottom: {
      border: { bottom: { style: 'thin' } },
    },
    bottomLeft: {
      border: { bottom: { style: 'thin' }, left: { style: 'thin' } },
    },
    left: {
      border: { left: { style: 'thin' } },
    },
    topLeft: {
      border: { top: { style: 'thin' }, left: { style: 'thin' } },
    },
    all: {
      border: { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } },
    },
  };

  let ws = XLSX.utils.aoa_to_sheet([]);
  ws['!cols'] = [{ wch: 18 }, { wch: 35 }];

  let header = [
    { v: 'Summary', t: 's', s: { font: { bold: true, sz: 24 } } },
    { v: timestamp2HumanReadableDateMonth(date?.getTime()!!), t: 's', s: { font: { bold: true, sz: 24 } } },
  ];

  let boxCompany: any = {
    companyName: [
      { v: 'Company Name', t: 's', s: { ...styleTitle, ...styleBorder.top } },
      { v: companyInfo.name, t: 's', s: styleBorder.topRight },
    ],
    CEF: [
      { v: 'CIF', t: 's', s: styleTitle },
      { v: companyInfo.cif, t: 's', s: styleBorder.right },
    ],
    phone: [
      { v: 'Phone', t: 's', s: styleTitle },
      { v: companyInfo.phone, t: 's', s: styleBorder.right },
    ],
    headOffice: [
      { v: 'Location', t: 's', s: { ...styleTitle, ...styleBorder.bottom } },
      { v: companyInfo.location, t: 's', s: styleBorder.bottomRight },
    ],
  };
  XLSX.utils.sheet_add_aoa(ws, [header, [], boxCompany.companyName, boxCompany.CEF, boxCompany.phone, boxCompany.headOffice], { origin: -1 });

  let boxUser: any = {
    employee: [
      { v: 'Employee', t: 's', s: { ...styleTitle, ...styleBorder.top } },
      { v: `${data.name} ${data.surname}`, t: 's', s: styleBorder.topRight },
    ],
    email: [
      { v: 'Email', t: 's', s: styleTitle },
      { v: data.email, t: 's', s: styleBorder.right },
    ],
    department: [
      { v: 'Department', t: 's', s: styleTitle },
      { v: data.department?.name, t: 's', s: styleBorder.right },
    ],
    office: [
      { v: 'Office', t: 's', s: { ...styleTitle, ...styleBorder.bottom } },
      { v: data.office?.name, t: 's', s: styleBorder.bottomRight },
    ],
  };

  XLSX.utils.sheet_add_aoa(ws, [[], boxUser.employee, boxUser.email, boxUser.department, boxUser.office], { origin: -1 });

  let titleSigning = [
    { v: 'Date', t: 's', s: { ...styleTitle, ...styleBorder.all } },
    { v: 'Type', t: 's', s: { ...styleTitle, ...styleBorder.all } },
    { v: 'Observation', t: 's', s: { ...styleTitle, ...styleBorder.all } },
    { v: 'Place', t: 's', s: { ...styleTitle, ...styleBorder.all } },
    { v: 'Hour', t: 's', s: { ...styleTitle, ...styleBorder.all } },
  ];
  XLSX.utils.sheet_add_aoa(ws, [[], [], titleSigning], { origin: -1 });

  data.signings?.sort((a, b) => {
    if (a.signDate && b.signDate) return a.signDate - b.signDate;
    return 0;
  });

  let lastDate = new Date();
  const color1 = { fill: { patternType: 'solid', fgColor: { rgb: 'CDD2EE' } } };
  const color2 = { fill: { patternType: 'solid', fgColor: { rgb: 'EEF0F9' } } };
  const colorIn = { fill: { patternType: 'solid', fgColor: { rgb: 'D9EAD3' } } };
  const colorOut = { fill: { patternType: 'solid', fgColor: { rgb: 'F2DCDB' } } };
  let color = color2;
  data.signings?.forEach((signing) => {
    let date = new Date(signing.signDate!!);
    if (date.getDate() !== lastDate.getDate()) color = color === color1 ? color2 : color1;
    lastDate = date;

    let rowSigning = [
      { v: new Date(signing.signDate!!).toLocaleDateString(), t: 's', s: { ...color, ...styleBorder.left } },
      {
        v: signing.type,
        t: 's',
        s: signing.type === 'IN' ? { ...colorIn } : { ...colorOut },
      },
      { v: signing.observation ? signing.observation?.name : '', t: 's', s: { ...color } },
      { v: signing.place, t: 's', s: { ...color } },
      { v: timestamp2HumanReadableHour(signing.signDate!!), t: 's', s: { ...color, ...styleBorder.right } },
    ];
    XLSX.utils.sheet_add_aoa(ws, [rowSigning], { origin: -1 });
  });

  let total = [
    { v: 'Total', t: 's', s: { ...styleTitle, ...styleBorder.bottomLeft } },
    { v: '', t: 's', s: styleBorder.bottom },
    { v: '', t: 's', s: styleBorder.bottom },
    { v: '', t: 's', s: styleBorder.bottom },
    { v: numberOfHours(data.hours!!), t: 's', s: styleBorder.bottomRight },
  ];
  XLSX.utils.sheet_add_aoa(ws, [total], { origin: -1 });

  XLSX.utils.sheet_add_aoa(ws, [], { origin: -1 });

  return ws;
};
