import { IRepository } from '@movicoders/domain';
import { DomainPage } from '@movicoders/domain/lib/Entities/DomainPage';
import { GetStatisticBadRequest, GetStatisticGoodRequest, GetStatisticMissedRequest, StatisticDTO, StatisticsApi } from '../../clients/signing-management';

export class StatisticsRepository implements IRepository<StatisticDTO, GetStatisticGoodRequest, any> {
  constructor(private readonly client: StatisticsApi) {}
  get(criteria?: GetStatisticGoodRequest | undefined): Promise<StatisticDTO[]> {
    throw new Error('Method not implemented.');
  }
  getPaginated(pageCriteria?: any): Promise<DomainPage<StatisticDTO>> {
    throw new Error('Method not implemented.');
  }
  getById(id: string): Promise<StatisticDTO> {
    throw new Error('Method not implemented.');
  }
  create(entity: StatisticDTO): Promise<StatisticDTO> {
    throw new Error('Method not implemented.');
  }
  update(entity: StatisticDTO): Promise<StatisticDTO> {
    throw new Error('Method not implemented.');
  }
  remove(id: string): Promise<void> {
    throw new Error('Method not implemented.');
  }
  getStatisticGood(criteria: GetStatisticGoodRequest): Promise<StatisticDTO> {
    return this.client.getStatisticGood(criteria);
  }
  getStatisticBad(criteria: GetStatisticBadRequest): Promise<StatisticDTO> {
    return this.client.getStatisticBad(criteria);
  }
  getStatisticMissed(criteria: GetStatisticMissedRequest): Promise<StatisticDTO> {
    return this.client.getStatisticMissed(criteria);
  }
}
