import { IRepository } from '@movicoders/domain';
import { ObjectType } from '@movicoders/ui/lib/ViewModels/types';
import AddIcon from '@mui/icons-material/Add';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import { Chip, Grid, IconButton, InputAdornment, TextField } from '@mui/material';
import i18next from 'i18next';
import { useEffect } from 'react';
import { OfficeDTO } from '../../../clients/user-management/models/OfficeDTO';
import { useChipList } from './useSettings';

export interface IChipList {
  repository: ObjectType<IRepository<any, any, any>>;
  icon: JSX.Element;
  object?: any;
}

export const ChipList = (params: IChipList) => {
  const { visibleForm, setVisibleForm, handleChange, handleSubmit, handleDelete, handleCancel, fetch, data } = useChipList(params);

  const getData = () => {
    fetch({});
  };

  useEffect(() => {
    getData();
  }, [visibleForm]);

  return (
    <>
      <Grid>
        {data.map((item) => {
          return (
            <Chip
              icon={params.icon}
              label={item.name?.toUpperCase() ?? 'Not Found'}
              color="primary"
              variant="outlined"
              onDelete={() => handleDelete(item.id!!)}
              sx={{ marginRight: 1 }}
              key={item.id}
            />
          );
        })}
        {!visibleForm && (
          <Chip
            label={i18next.t('settings.chipList.NewLabel') as string}
            color="primary"
            variant="outlined"
            deleteIcon={<AddIcon />}
            onDelete={() => setVisibleForm(true)}
          />
        )}
      </Grid>
      {visibleForm && (
        <Grid container alignItems={'center'} sx={{ marginTop: 3 }}>
          <form onSubmit={handleSubmit}>
            <TextField
              size="small"
              color="primary"
              variant="standard"
              InputProps={{
                startAdornment: <InputAdornment position="start">{params.icon}</InputAdornment>,
              }}
              onChange={handleChange}
            />
            <IconButton color="primary" type="submit">
              <AddIcon />
            </IconButton>
            <IconButton color="primary" onClick={handleCancel}>
              <NotInterestedIcon />
            </IconButton>
          </form>
        </Grid>
      )}
    </>
  );
};
