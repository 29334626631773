/* tslint:disable */
/* eslint-disable */
/**
 * SigningManagement microservice (dev)
 * SigningManagement microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  MovicodersBadRequestException,
  MovicodersNotFoundException,
  MovicodersServiceException,
  SigningDTO,
  SigningForCalendarDTO,
  SigningSummaryDTO,
} from '../models';
import {
    MovicodersBadRequestExceptionFromJSON,
    MovicodersBadRequestExceptionToJSON,
    MovicodersNotFoundExceptionFromJSON,
    MovicodersNotFoundExceptionToJSON,
    MovicodersServiceExceptionFromJSON,
    MovicodersServiceExceptionToJSON,
    SigningDTOFromJSON,
    SigningDTOToJSON,
    SigningForCalendarDTOFromJSON,
    SigningForCalendarDTOToJSON,
    SigningSummaryDTOFromJSON,
    SigningSummaryDTOToJSON,
} from '../models';

export interface DeleteSigningRequest {
    signingId: string;
}

export interface GetCalendarSigningsByUser1Request {
    from: number;
    to: number;
    userId: string;
}

export interface GetDateRangeSigningsByUserRequest {
    from: number;
    to: number;
    userId: string;
}

export interface GetSummaryListSigningsByTenantRequest {
    from: number;
    to: number;
}

export interface GetSummarySigningsByTenantRequest {
    from: number;
    to: number;
}

/**
 * 
 */
export class SigningsManagementApi extends runtime.BaseAPI {

    /**
     * Delete a signing
     */
    async deleteSigningRaw(requestParameters: DeleteSigningRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SigningDTO>> {
        if (requestParameters.signingId === null || requestParameters.signingId === undefined) {
            throw new runtime.RequiredError('signingId','Required parameter requestParameters.signingId was null or undefined when calling deleteSigning.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/signings-management/{signingId}`.replace(`{${"signingId"}}`, encodeURIComponent(String(requestParameters.signingId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SigningDTOFromJSON(jsonValue));
    }

    /**
     * Delete a signing
     */
    async deleteSigning(requestParameters: DeleteSigningRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SigningDTO> {
        const response = await this.deleteSigningRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Return a calendar of signings by user
     */
    async getCalendarSigningsByUser1Raw(requestParameters: GetCalendarSigningsByUser1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SigningForCalendarDTO>>> {
        if (requestParameters.from === null || requestParameters.from === undefined) {
            throw new runtime.RequiredError('from','Required parameter requestParameters.from was null or undefined when calling getCalendarSigningsByUser1.');
        }

        if (requestParameters.to === null || requestParameters.to === undefined) {
            throw new runtime.RequiredError('to','Required parameter requestParameters.to was null or undefined when calling getCalendarSigningsByUser1.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getCalendarSigningsByUser1.');
        }

        const queryParameters: any = {};

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = requestParameters.from;
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = requestParameters.to;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/signings-management/calendar/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SigningForCalendarDTOFromJSON));
    }

    /**
     * Return a calendar of signings by user
     */
    async getCalendarSigningsByUser1(requestParameters: GetCalendarSigningsByUser1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SigningForCalendarDTO>> {
        const response = await this.getCalendarSigningsByUser1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Return a list of range by user
     */
    async getDateRangeSigningsByUserRaw(requestParameters: GetDateRangeSigningsByUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SigningDTO>>> {
        if (requestParameters.from === null || requestParameters.from === undefined) {
            throw new runtime.RequiredError('from','Required parameter requestParameters.from was null or undefined when calling getDateRangeSigningsByUser.');
        }

        if (requestParameters.to === null || requestParameters.to === undefined) {
            throw new runtime.RequiredError('to','Required parameter requestParameters.to was null or undefined when calling getDateRangeSigningsByUser.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getDateRangeSigningsByUser.');
        }

        const queryParameters: any = {};

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = requestParameters.from;
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = requestParameters.to;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/signings-management/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SigningDTOFromJSON));
    }

    /**
     * Return a list of range by user
     */
    async getDateRangeSigningsByUser(requestParameters: GetDateRangeSigningsByUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SigningDTO>> {
        const response = await this.getDateRangeSigningsByUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Return a summary list of signings by tenant
     */
    async getSummaryListSigningsByTenantRaw(requestParameters: GetSummaryListSigningsByTenantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SigningSummaryDTO>>> {
        if (requestParameters.from === null || requestParameters.from === undefined) {
            throw new runtime.RequiredError('from','Required parameter requestParameters.from was null or undefined when calling getSummaryListSigningsByTenant.');
        }

        if (requestParameters.to === null || requestParameters.to === undefined) {
            throw new runtime.RequiredError('to','Required parameter requestParameters.to was null or undefined when calling getSummaryListSigningsByTenant.');
        }

        const queryParameters: any = {};

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = requestParameters.from;
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = requestParameters.to;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/signings-management/summary/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SigningSummaryDTOFromJSON));
    }

    /**
     * Return a summary list of signings by tenant
     */
    async getSummaryListSigningsByTenant(requestParameters: GetSummaryListSigningsByTenantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SigningSummaryDTO>> {
        const response = await this.getSummaryListSigningsByTenantRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Return a summary of signings by tenant
     */
    async getSummarySigningsByTenantRaw(requestParameters: GetSummarySigningsByTenantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SigningSummaryDTO>>> {
        if (requestParameters.from === null || requestParameters.from === undefined) {
            throw new runtime.RequiredError('from','Required parameter requestParameters.from was null or undefined when calling getSummarySigningsByTenant.');
        }

        if (requestParameters.to === null || requestParameters.to === undefined) {
            throw new runtime.RequiredError('to','Required parameter requestParameters.to was null or undefined when calling getSummarySigningsByTenant.');
        }

        const queryParameters: any = {};

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = requestParameters.from;
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = requestParameters.to;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/signings-management/summary`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SigningSummaryDTOFromJSON));
    }

    /**
     * Return a summary of signings by tenant
     */
    async getSummarySigningsByTenant(requestParameters: GetSummarySigningsByTenantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SigningSummaryDTO>> {
        const response = await this.getSummarySigningsByTenantRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
