interface IForm {
  children: React.ReactNode;
  onSubmit: (event: any) => void;
  loading?: boolean;
  save?: React.ReactNode;
  cancel?: React.ReactNode;
  onClickCancel?: () => void;
}

export const Form = (params: IForm) => {
  return <form onSubmit={params.onSubmit}>{params.children}</form>;
};
