import { Configuration } from '../../clients/signing-management/runtime'
import { UserService } from '../../domain/services/UserService';

export class SigningManagementApiConfiguration extends Configuration {
  constructor(private readonly userService: UserService) {
    super();
  }

  get apiKey(): ((name: string) => string) | undefined {
    const apiKey = this.userService.user?.token;

    return apiKey ? () => `Bearer ${apiKey}` : undefined;
  }
}