import { TYPES } from '@movicoders/domain';
import { useListViewModel, useResolve } from '@movicoders/ui';
import { useEffect, useState } from 'react';
import { UserService } from '../../../../../domain/services/UserService';
import { UsersManagementRepository } from '../../../../../infrastructure/repository/UsersManagementRepository';
import { EnumOptions, IOptionsResponse } from '../../../base/table/options/types-options';

export const useTableEmployees = () => {
  const [id, setId] = useState('');
  const [user, setUser] = useState(null);
  const [dataList, setDataList] = useState<any[]>([]);
  const [openDeleteData, setOpenDeleteData] = useState(false);
  const [openFormRegister, setOpenFormRegister] = useState(false);
  const [openEditFormRegister, setOpenEditFormRegister] = useState(false);

  const userService = useResolve<UserService>(TYPES.UserService);
  const usersManagementRepository = useResolve(UsersManagementRepository);

  const { data, fetch, loading } = useListViewModel(UsersManagementRepository);

  useEffect(() => {
    if (userService.user?.hasPermission(['MANAGER'])) {
      usersManagementRepository.getManagementDepartment(String(userService.user?.department?.id)).then((res) => {
        setDataList(res);
        return;
      });
    }
    if (userService.user?.hasPermission(['ADMINISTRATOR'])) {
      usersManagementRepository.get().then((res) => {
        setDataList(res);
        return;
      });
    }
  }, [data]);

  const onClose = () => {
    setOpenFormRegister(false);
    setOpenEditFormRegister(false);
    setOpenDeleteData(false);
    fetch({});
  };

  const onDelete = () => {
    onClose();
    usersManagementRepository.delete(id).then(() => {
      fetch({});
    });
  };

  const handleClick = (props: IOptionsResponse) => {
    if (props.origin === EnumOptions.DELETE) {
      setId(props.data.row.id);
      setOpenDeleteData(true);
      return;
    }
    if (props.origin === EnumOptions.ACTIVE && props.data.row.active === true) {
      usersManagementRepository.deactivate(props.data.row.id).then(() => {
        fetch({});
      });
      return;
    }
    if (props.origin === EnumOptions.ACTIVE && props.data.row.active === false) {
      usersManagementRepository.activate(props.data.row.id).then(() => {
        fetch({});
      });
      return;
    }
    if (props.origin === EnumOptions.EDIT) {
      setUser(props.data.row);
      setOpenEditFormRegister(true);
      return;
    }
  };

  return {
    openDeleteData,
    openFormRegister,
    setOpenFormRegister,
    openEditFormRegister,
    user,
    handleClick,
    onClose,
    onDelete,
    data,
    dataList,
    loading,
    userService,
  };
};
