import { TextField } from '@mui/material';
import { Sizes } from '../../../../theme';

interface IInputTimePicker {
  label: string;
  value?: string;
  onChange?: (e: any) => void;
  disabled?: boolean;
}

export const InputTimePicker = (params: IInputTimePicker) => {
  return (
    <TextField
      id="time"
      label={params.label}
      name="time"
      type="time"
      defaultValue={params.value}
      onChange={params.disabled ? undefined : params.onChange}
      disabled={params.disabled}
      InputLabelProps={{
        shrink: true,
      }}
      sx={{
        minWidth: '140px',
        textAlign: 'center',
        width: `calc(${Sizes.widthBtn} * 1.3)`,
      }}
    />
  );
};
