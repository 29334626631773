import { TYPES } from '@movicoders/domain';
import { useResolve, useHandleError, useSnackbar } from '@movicoders/ui';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserUseCase } from '../../../application/user/user';
import { UserService } from '../../../domain/services/UserService';
import { useNavigate } from 'react-router';

export const useToolbarViewModel = () => {
  const [open, setOpen] = useState(true);
  const { t } = useTranslation();
  const userUseCase = useResolve(UserUseCase);
  const userService = useResolve<UserService>(TYPES.UserService);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { show } = useSnackbar();

  const logout = () => {
    userService.clear();
    navigate('/login');
  };

  const getProfile = async () => {
    setLoading(false);
    await userUseCase.execute();
    await setLoading(true);
  };

  useHandleError(async (param: PromiseRejectionEvent) => {
    if (param.reason?.response?.status === 401 && userService.user?.token) {
      logout();
    } else if (param.reason?.response?.status === 401) {
      show(t('error.0011'), 'error');
    }
  });

  useEffect(() => {
    if (window.location.pathname === '/login') getProfile();
  }, []);

  return { open, setOpen, userService, logout, t, loading };
};
