import BusinessIcon from '@mui/icons-material/Business';
import GroupsIcon from '@mui/icons-material/Groups';
import LanguageIcon from '@mui/icons-material/Language';
import { Divider, Grid, MenuItem, Select, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { OfficeRepository } from '../../../infrastructure/repository/OfficeRepository';
import { Container } from '../../components/container/Container';
import { Colors } from '../../theme';
import { ChipList } from './ChipLists';
import { useLanguageSettings } from './useSettings';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import { DepartmentRepository } from '../../../infrastructure/repository/DepartmentRepository';
import { Paper } from '../../components/base/paper/Paper';
import { useResolve } from '@movicoders/ui';
import { UserService } from '../../../domain/services/UserService';
import { TYPES } from '@movicoders/domain';

export const Settings = () => {
  const userService = useResolve<UserService>(TYPES.UserService);
  const { handleLanguageChange, language } = useLanguageSettings();
  const { t } = useTranslation();

  const SettingTitle = ({ title, icon }: any) => {
    return (
      <>
        <Grid container direction="row">
          <div>{icon}</div>
          <Typography marginLeft={'5px'}>{title}</Typography>
        </Grid>
        <Divider sx={{ marginTop: '5px', marginBottom: '20px' }} />
      </>
    );
  };

  return (
    <>
      <Container>
        <Typography variant="h4" color={Colors.primary} sx={{ marginBottom: '1em' }}>
          {t('settings.title.preference')}
        </Typography>
        <Paper sx={{ padding: '2em' }}>
          <SettingTitle title={t('settings.title.language')} icon={<LanguageIcon />} />
          <Select value={language} onChange={handleLanguageChange} size="small">
            <MenuItem value={'es'}> {t('language.spanish')} </MenuItem>
            <MenuItem value={'en'}> {t('language.english')} </MenuItem>
          </Select>
        </Paper>
        {userService.user?.hasPermission(['ADMINISTRATOR']) && (
          <>
            <Typography variant="h4" color={Colors.primary} sx={{ marginBottom: '1em', marginTop: '1em' }}>
              {t('settings.title.preference.admin')}
            </Typography>
            <Grid container direction="row" justifyContent={'space-between'} spacing={6}>
              <Grid item xs={6}>
                <Paper sx={{ padding: '2em' }}>
                  <SettingTitle title={t('settings.title.Office')} icon={<BusinessIcon />} />
                  <ChipList repository={OfficeRepository} icon={<LocationOnIcon />} />
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper sx={{ padding: '2em' }}>
                  <SettingTitle title={t('settings.title.departments')} icon={<GroupsIcon />} />
                  <ChipList repository={DepartmentRepository} icon={<WorkspacesIcon />} />
                </Paper>
              </Grid>
            </Grid>
          </>
        )}
      </Container>
    </>
  );
};
