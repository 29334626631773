export const ROUTER_PATHS = {
  home: '/',
  history: '/history',
  calendar: '/calendar',
  historyemployee: '/historyemployee',
  employees: '/employees',
  account: '/account',
  settings: '/settings',
  login: '/login',
};
