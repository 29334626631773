import { errorList, IError } from '../../helpers/errors';

const handleError = async (error: any) => {
  const toStream = new Response(await error.body);
  const errorResponse = (await toStream.json()) as any;
  return errorResponse.errors;
};

export const ErrorsService = async (e: any): Promise<IError | undefined> => {
  const error: any = await handleError(e);
  const errorCode = error[0].errorCode;
  const errorListFind = errorList.find((item) => item.errorCode === errorCode);
  if (errorListFind) {
    return errorListFind;
  }
  return errorList.find((item) => item.errorCode === 'default');
};
