import { Box, Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useStyles } from '../../../styles/box.styles';
import { useBoxInfo } from './useBoxInfo';

export const BoxInfo = () => {
  const { user, data } = useBoxInfo();
  const classes = useStyles();

  useEffect(() => {
    data();
  }, [data]);

  return (
    <Box className={classes.mainBox}>
      <Grid container spacing={2} columns={16} alignItems="center">
        <Grid item xs={12}>
          <Typography variant="h2">{`${user?.name} ${user?.surname}`}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h5">{`${user?.office?.name}`}</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} columns={16} alignItems="center">
        <Grid item xs={10}>
          <Typography variant="h5">{`${user?.department?.name.toLocaleUpperCase()}`}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
