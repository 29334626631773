import { useResolve } from '@movicoders/ui';
import { useEffect, useState } from 'react';
import { SigningSummaryDTO } from '../../../../../clients/signing-management';
import { CompanyDTO } from '../../../../../clients/user-management';
import { CompanyRepository } from '../../../../../infrastructure/repository/CompanyRepository';
import { SigningManagementRepository } from '../../../../../infrastructure/repository/SigningManagementRepository';

interface IUseTableSummary {
  date?: Date;
  setDate?: (date: Date) => void;
}

export const useTableSummary = (params: IUseTableSummary) => {
  const signingManagementRepository = useResolve(SigningManagementRepository);
  const companyRepository = useResolve(CompanyRepository);
  const [date, _] = useState(params.date || new Date());
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<SigningSummaryDTO[]>([]);

  const getData = async () => {
    setLoading(true);
    const from = new Date(date.getFullYear(), date.getMonth(), 1).setHours(0, 0, 0, 1);
    const to = new Date(date.getFullYear(), date.getMonth() + 1, 0).setHours(23, 59, 59, 999);
    let data = await signingManagementRepository.getSummary({ from, to });
    setData(data);
    setLoading(false);
  };

  const getSummaryList = async (): Promise<SigningSummaryDTO[]> => {
    const from = new Date(date.getFullYear(), date.getMonth(), 1).setHours(0, 0, 0, 1);
    const to = new Date(date.getFullYear(), date.getMonth() + 1, 0).setHours(23, 59, 59, 999);
    let data = await signingManagementRepository.getSummaryList({ from, to });
    return data;
  };

  const getCompany = async (): Promise<CompanyDTO> => {
    let data = await companyRepository.getByTenant();
    return data;
  };

  useEffect(() => {
    getData();
  }, [params.date?.getMonth()]);

  return { date, data, loading, getSummaryList, getCompany };
};
