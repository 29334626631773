/* tslint:disable */
/* eslint-disable */
/**
 * SigningManagement microservice (dev)
 * SigningManagement microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  MovicodersBadRequestException,
  MovicodersNotFoundException,
  MovicodersServiceException,
  SigningDTO,
  SigningForCalendarDTO,
} from '../models';
import {
    MovicodersBadRequestExceptionFromJSON,
    MovicodersBadRequestExceptionToJSON,
    MovicodersNotFoundExceptionFromJSON,
    MovicodersNotFoundExceptionToJSON,
    MovicodersServiceExceptionFromJSON,
    MovicodersServiceExceptionToJSON,
    SigningDTOFromJSON,
    SigningDTOToJSON,
    SigningForCalendarDTOFromJSON,
    SigningForCalendarDTOToJSON,
} from '../models';

export interface DisableSigningRequest {
    signingId: string;
}

export interface GetCalendarSigningsByUserRequest {
    from: number;
    to: number;
}

export interface GetDateRangeSigningsRequest {
    from: number;
    to: number;
}

export interface SaveSigningRequest {
    signingDTO?: SigningDTO;
}

export interface UpdateSigningRequest {
    signingId: string;
    signingDTO?: SigningDTO;
}

/**
 * 
 */
export class SigningsApi extends runtime.BaseAPI {

    /**
     * Disable a Signing
     */
    async disableSigningRaw(requestParameters: DisableSigningRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SigningDTO>> {
        if (requestParameters.signingId === null || requestParameters.signingId === undefined) {
            throw new runtime.RequiredError('signingId','Required parameter requestParameters.signingId was null or undefined when calling disableSigning.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/signings/{signingId}`.replace(`{${"signingId"}}`, encodeURIComponent(String(requestParameters.signingId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SigningDTOFromJSON(jsonValue));
    }

    /**
     * Disable a Signing
     */
    async disableSigning(requestParameters: DisableSigningRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SigningDTO> {
        const response = await this.disableSigningRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Return a calendar of signings
     */
    async getCalendarSigningsByUserRaw(requestParameters: GetCalendarSigningsByUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SigningForCalendarDTO>>> {
        if (requestParameters.from === null || requestParameters.from === undefined) {
            throw new runtime.RequiredError('from','Required parameter requestParameters.from was null or undefined when calling getCalendarSigningsByUser.');
        }

        if (requestParameters.to === null || requestParameters.to === undefined) {
            throw new runtime.RequiredError('to','Required parameter requestParameters.to was null or undefined when calling getCalendarSigningsByUser.');
        }

        const queryParameters: any = {};

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = requestParameters.from;
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = requestParameters.to;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/signings/calendar`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SigningForCalendarDTOFromJSON));
    }

    /**
     * Return a calendar of signings
     */
    async getCalendarSigningsByUser(requestParameters: GetCalendarSigningsByUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SigningForCalendarDTO>> {
        const response = await this.getCalendarSigningsByUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Return a list of range signings
     */
    async getDateRangeSigningsRaw(requestParameters: GetDateRangeSigningsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SigningDTO>>> {
        if (requestParameters.from === null || requestParameters.from === undefined) {
            throw new runtime.RequiredError('from','Required parameter requestParameters.from was null or undefined when calling getDateRangeSignings.');
        }

        if (requestParameters.to === null || requestParameters.to === undefined) {
            throw new runtime.RequiredError('to','Required parameter requestParameters.to was null or undefined when calling getDateRangeSignings.');
        }

        const queryParameters: any = {};

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = requestParameters.from;
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = requestParameters.to;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/signings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SigningDTOFromJSON));
    }

    /**
     * Return a list of range signings
     */
    async getDateRangeSignings(requestParameters: GetDateRangeSigningsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SigningDTO>> {
        const response = await this.getDateRangeSigningsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the registered Signing
     */
    async saveSigningRaw(requestParameters: SaveSigningRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SigningDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/signings`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SigningDTOToJSON(requestParameters.signingDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SigningDTOFromJSON(jsonValue));
    }

    /**
     * Returns the registered Signing
     */
    async saveSigning(requestParameters: SaveSigningRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SigningDTO> {
        const response = await this.saveSigningRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a Signing
     */
    async updateSigningRaw(requestParameters: UpdateSigningRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SigningDTO>> {
        if (requestParameters.signingId === null || requestParameters.signingId === undefined) {
            throw new runtime.RequiredError('signingId','Required parameter requestParameters.signingId was null or undefined when calling updateSigning.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/signings/{signingId}`.replace(`{${"signingId"}}`, encodeURIComponent(String(requestParameters.signingId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SigningDTOToJSON(requestParameters.signingDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SigningDTOFromJSON(jsonValue));
    }

    /**
     * Update a Signing
     */
    async updateSigning(requestParameters: UpdateSigningRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SigningDTO> {
        const response = await this.updateSigningRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
