import { TYPES } from '@movicoders/domain';
import { useResolve } from '@movicoders/ui';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { UserService } from '../../../domain/services/UserService';
import { Colors } from '../../theme';

interface IDrawer {
  options: [{ name: string; icon: () => JSX.Element; path: string; hidden: boolean }];
  open: boolean;
  currentPath: string;
  setCurrentPath: (path: string) => void;
}

export const DrawerOptions = (params: IDrawer) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const userService = useResolve<UserService>(TYPES.UserService);

  return (
    <List>
      {params.options.map((element: any) =>
        userService.user?.hasPermission(element.permissions) ? (
          <>
            <ListItem
              key={element.name}
              disablePadding
              sx={{ display: 'block' }}
              onClick={() => {
                navigate(element.path);
                params.setCurrentPath(element.path);
              }}
            >
              <ListItemButton
                key={`${element.name} button`}
                sx={{
                  minHeight: 48,
                  justifyContent: params.open ? 'initial' : 'center',
                  px: 2.5,
                  mx: 1,
                  borderBottom: `3px solid ${params.currentPath === element.path ? Colors.primary : 'transparent'}`,
                }}
              >
                <ListItemIcon
                  key={`${element.name} icon`}
                  sx={{
                    minWidth: 0,
                    mr: params.open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {element.icon()}
                </ListItemIcon>
                <ListItemText primary={t(element.name)} sx={{ opacity: params.open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          </>
        ) : (
          <></>
        )
      )}
    </List>
  );
};
