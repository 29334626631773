import { Dialog } from '../Dialog';

interface IConfirmationDialogs {
  open: boolean;
  value: any;
  onClose: () => void;
  onConfirm: (value: any) => void;
  dialogConfig: {
    content: JSX.Element;
    confirmText: any;
    cancelText: any;
  };
}

export const ConfirmationDialogs = (params: IConfirmationDialogs) => {
  return (
    <Dialog
      open={params.open}
      onClose={params.onClose}
      maxWidth={'xs'}
      fullWidth={true}
      closeWithX={false}
      onConfirm={() => {
        params.onConfirm(params.value);
      }}
      dialogConfig={params.dialogConfig}
    />
  );
};
