import { makeStyles } from '@mui/styles';
import { Colors, Sizes } from '../../../theme';

export interface StyleProps {
  colorBTN: string;
  size?: number;
}

export const signObservationsStyles = makeStyles({
  button: {
    backgroundColor: Colors.primary + ' !important',
    width: `calc(${Sizes.widthCircleBtn} * 2 ) !important`,
    height: `calc(${Sizes.heightCircleBtn} * 2) !important`,
    borderRadius: '10px',
    '&:hover': {
      backgroundColor: Colors.primaryDark + ' !important',
    },
  },
  selected: {
    backgroundColor: Colors.selected + '!important',
    '&:hover': {
      backgroundColor: Colors.selected + '!important',
    },
  },
});
