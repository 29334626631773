/* tslint:disable */
/* eslint-disable */
/**
 * SigningManagement microservice (dev)
 * SigningManagement microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  MovicodersBadRequestException,
  MovicodersNotFoundException,
  MovicodersServiceException,
  StatisticDTO,
} from '../models';
import {
    MovicodersBadRequestExceptionFromJSON,
    MovicodersBadRequestExceptionToJSON,
    MovicodersNotFoundExceptionFromJSON,
    MovicodersNotFoundExceptionToJSON,
    MovicodersServiceExceptionFromJSON,
    MovicodersServiceExceptionToJSON,
    StatisticDTOFromJSON,
    StatisticDTOToJSON,
} from '../models';

export interface GetStatisticBadRequest {
    from: number;
    to: number;
}

export interface GetStatisticGoodRequest {
    from: number;
    to: number;
}

export interface GetStatisticMissedRequest {
    from: number;
    to: number;
}

/**
 * 
 */
export class StatisticsApi extends runtime.BaseAPI {

    /**
     * Return statistic of signings bad
     */
    async getStatisticBadRaw(requestParameters: GetStatisticBadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StatisticDTO>> {
        if (requestParameters.from === null || requestParameters.from === undefined) {
            throw new runtime.RequiredError('from','Required parameter requestParameters.from was null or undefined when calling getStatisticBad.');
        }

        if (requestParameters.to === null || requestParameters.to === undefined) {
            throw new runtime.RequiredError('to','Required parameter requestParameters.to was null or undefined when calling getStatisticBad.');
        }

        const queryParameters: any = {};

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = requestParameters.from;
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = requestParameters.to;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/statistics/bad`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatisticDTOFromJSON(jsonValue));
    }

    /**
     * Return statistic of signings bad
     */
    async getStatisticBad(requestParameters: GetStatisticBadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StatisticDTO> {
        const response = await this.getStatisticBadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Return statistic of signings good
     */
    async getStatisticGoodRaw(requestParameters: GetStatisticGoodRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StatisticDTO>> {
        if (requestParameters.from === null || requestParameters.from === undefined) {
            throw new runtime.RequiredError('from','Required parameter requestParameters.from was null or undefined when calling getStatisticGood.');
        }

        if (requestParameters.to === null || requestParameters.to === undefined) {
            throw new runtime.RequiredError('to','Required parameter requestParameters.to was null or undefined when calling getStatisticGood.');
        }

        const queryParameters: any = {};

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = requestParameters.from;
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = requestParameters.to;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/statistics/good`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatisticDTOFromJSON(jsonValue));
    }

    /**
     * Return statistic of signings good
     */
    async getStatisticGood(requestParameters: GetStatisticGoodRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StatisticDTO> {
        const response = await this.getStatisticGoodRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Return statistic of signings missed
     */
    async getStatisticMissedRaw(requestParameters: GetStatisticMissedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StatisticDTO>> {
        if (requestParameters.from === null || requestParameters.from === undefined) {
            throw new runtime.RequiredError('from','Required parameter requestParameters.from was null or undefined when calling getStatisticMissed.');
        }

        if (requestParameters.to === null || requestParameters.to === undefined) {
            throw new runtime.RequiredError('to','Required parameter requestParameters.to was null or undefined when calling getStatisticMissed.');
        }

        const queryParameters: any = {};

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = requestParameters.from;
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = requestParameters.to;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/statistics/missed`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatisticDTOFromJSON(jsonValue));
    }

    /**
     * Return statistic of signings missed
     */
    async getStatisticMissed(requestParameters: GetStatisticMissedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StatisticDTO> {
        const response = await this.getStatisticMissedRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
