import i18n from 'i18next';

export const generateUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const generateRandomName = () => {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
};

export const generateRandomId = () => {
  return Math.floor(Math.random() * 1000000);
};

export const setPriceWith2Decimals = (price: any): number => {
  if (price) {
    if (typeof price === 'string') {
      let tempNum = Number.parseFloat(price);
      if (tempNum) {
        return tempNum.toFixed(2) as unknown as number;
      }
    } else {
      return price.toFixed(2) as number;
    }
  }
  return 0;
};

export const normalizeDoubleValue = (value: string): number => {
  var normalizedValue: number = 0.0;
  if (value) normalizedValue = Number(Number(value.trim().replace(',', '.')).toFixed(2));
  return normalizedValue;
};

export const cloneArray = (obj: any) => {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  var temp = obj.constructor();
  for (var key in obj) {
    temp[key] = cloneArray(obj[key]);
  }
  return temp;
};

export const getUrlParameter = (name: String, url = window.location.href) => {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)'),
    results = regex.exec(url);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export const pad = (num: number, size: number): string => {
  let numStr = num.toString();
  while (numStr.length < size) numStr = '0' + numStr;
  return numStr;
};

export const unpad = (num: number): string => {
  let numStr = num.toString();
  while (numStr[0] === '0') {
    numStr = numStr.substring(1);
  }
  return numStr;
};

export const timestamp2HumanReadableDate = (unixTimestamp: number): string => {
  const t = i18n.t;
  const date = new Date(unixTimestamp);
  var months = [
    t('month.january'),
    t('month.february'),
    t('month.march'),
    t('month.april'),
    t('month.may'),
    t('month.june'),
    t('month.july'),
    t('month.august'),
    t('month.september'),
    t('month.october'),
    t('month.november'),
    t('month.december'),
  ];
  var year = date.getFullYear();
  var month = months[date.getMonth()];
  var day = date.getDate();
  var time = day + ' ' + month + ' ' + year;
  return time;
};

export const timestamp2HumanReadableDateMonth = (unixTimestamp: number): string => {
  const t = i18n.t;
  const date = new Date(unixTimestamp);
  var months = [
    t('month.january'),
    t('month.february'),
    t('month.march'),
    t('month.april'),
    t('month.may'),
    t('month.june'),
    t('month.july'),
    t('month.august'),
    t('month.september'),
    t('month.october'),
    t('month.november'),
    t('month.december'),
  ];
  var year = date.getFullYear();
  var month = months[date.getMonth()];
  var time = month + ' ' + year;
  return time;
};

export const timestamp2HumanReadableDateTime = (unixTimestamp: number): string => {
  const t = i18n.t;
  const date = new Date(unixTimestamp);
  var months = [
    t('month.january'),
    t('month.february'),
    t('month.march'),
    t('month.april'),
    t('month.may'),
    t('month.june'),
    t('month.july'),
    t('month.august'),
    t('month.september'),
    t('month.october'),
    t('month.november'),
    t('month.december'),
  ];
  var year = date.getFullYear();
  var month = months[date.getMonth()];
  var day = date.getDate();
  var hour = date.getHours();
  var min = date.getMinutes();
  var time = day + ' ' + month + ' ' + year + ' ' + hour + ':' + (min < 10 ? '0' + min : min);
  return time;
};

export const timestamp2HumanReadableHour = (unixTimestamp: number): string => {
  const date = new Date(unixTimestamp);
  var hour = date.getHours();
  var min = date.getMinutes();
  var time = (hour < 10 ? '0' + hour : hour) + ':' + (min < 10 ? '0' + min : min);
  return time;
};

export const numberOfHours = (number: number): number => {
  let result = number / 1000 / 60 / 60;
  return Math.floor(result * 100) / 100;
};

export const dateIsWeekend = (date: Date): boolean => {
  return date.getDay() === 6 || date.getDay() === 0;
};

/**
 * TODO: finish this function and do some changes to check all the cases
 * That function receives an array of all the signings by month and returns a string with the status of the day that can be:
 * - complete -> the signings are pairs (IN-OUT)
 * - incomplete -> the signings are impairs (IN-OUT-IN)
 * - empty -> no signings
 * @param signingsByMonth array of signings by month
 * @returns string with the status of the day
 */
export const checkMonthStatus = (signingsByMonth: []): string => {
  if (signingsByMonth.length === 0) return 'empty';
  if (signingsByMonth.length % 2 === 0) return 'complete';
  return 'incomplete';
};

export const dayRange = (date: Date): [Date, Date] => {
  return [new Date(date.setHours(0, 0, 0, 0)), new Date(date.setHours(23, 59, 59, 999))];
};

function isValidJSON(text: string) {
  try {
    var o = JSON.parse(text);
    // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
    // but... JSON.parse(null) returns null, and typeof null === "object",
    // so we must check for that, too. Thankfully, null is falsey, so this suffices:
    if (o && typeof o === 'object') {
      return o;
    }
  } catch (e) {}

  return false;
}

export const getRandomNumber = (lower: number, upper: number): number => {
  let numPossibilities = upper - lower;
  let random = Math.random() * (numPossibilities + 1);
  random = Math.floor(random);
  return lower + random;
};

export const getDifferentRandomNumbers = (lower: number, upper: number, totalNumbersToReturn: number) => {
  let randomNumbers = [];
  while (randomNumbers.length < totalNumbersToReturn) {
    var r = Math.floor(Math.random() * (upper - lower)) + lower;
    if (randomNumbers.indexOf(r) === -1) randomNumbers.push(r);
  }
  return randomNumbers;
};

//convert from Editor state to string (JSON)

export const checkIfEmptyObject = (obj: any) => {
  if (Object.keys(obj).length === 0 && obj.constructor === Object) {
    return true;
  }
  return false;
};

export const resizeBase64Img = function (base64: string, img: any, imageObject: any, maxImgWidth: number, maxImgHeight: number) {
  return new Promise<string>((resolve, reject) => {
    let ratioX = imageObject.width / maxImgWidth;
    let ratioY = imageObject.height / maxImgHeight;
    if (ratioX > 1 || ratioY > 1) {
      let ratio = ratioX > ratioY ? ratioX : ratioY;
      let newWidth = +imageObject.width / +ratio;
      let newHeight = +imageObject.height / +ratio;
      var canvas = document.createElement('canvas');

      let context = canvas.getContext('2d');
      canvas.width = newWidth;
      canvas.height = newHeight;

      let canvasImg = document.createElement('img');
      canvasImg.src = base64;
      canvasImg.onload = function () {
        context?.drawImage(canvasImg, 0, 0, canvasImg.width, canvasImg.height, 0, 0, newWidth, newHeight);
        resolve(canvas.toDataURL());
      };
    } else {
      resolve(base64);
    }
  });
};
