import { IRepository } from '@movicoders/domain';
import { DomainPage } from '@movicoders/domain/lib/Entities/DomainPage';
import { GetCalendarSigningsByUserRequest, SigningForCalendarDTO, SigningsApi } from '../../clients/signing-management';


export class SigningCalendarRepository implements IRepository<SigningForCalendarDTO, GetCalendarSigningsByUserRequest, any> {
  constructor(private readonly client: SigningsApi) {}
  get(criteria?: GetCalendarSigningsByUserRequest | undefined): Promise<SigningForCalendarDTO[]> {
    return this.client.getCalendarSigningsByUser(criteria!!);
  }
  getPaginated(pageCriteria?: any): Promise<DomainPage<SigningForCalendarDTO>> {
    throw new Error('Method not implemented.');
  }
  getById(id: string): Promise<SigningForCalendarDTO> {
    throw new Error('Method not implemented.');
  }
  create(entity: SigningForCalendarDTO): Promise<SigningForCalendarDTO> {
    throw new Error('Method not implemented.');
  }
  update(entity: SigningForCalendarDTO): Promise<SigningForCalendarDTO> {
    throw new Error('Method not implemented.');
  }
  remove(id: string): Promise<void> {
    throw new Error('Method not implemented.');
  }
}