import { TYPES } from '@movicoders/domain';
import { decorate, injectable, inject } from 'inversify';
import { AuthenticateUseCase } from './application/authenticate/authenticate';
import { UserUpdatePasswordUseCase, UserUseCase } from './application/user/user';
import { ObservationsApi, SigningsApi, SigningsManagementApi, StatisticsApi } from './clients/signing-management';
import { AuthApi, CompaniesApi, DepartmentsApi, OfficesApi, UserApi, UsersManagementApi } from './clients/user-management';
import { SigningManagementApiConfiguration } from './infrastructure/api/SigningManagementApiConfiguration';
import { UserManagementApiConfiguration } from './infrastructure/api/UserManagementApiConfiguration';
import { CompanyRepository } from './infrastructure/repository/CompanyRepository';
import { DepartmentRepository } from './infrastructure/repository/DepartmentRepository';
import { ObservationRepository } from './infrastructure/repository/ObservationRepository';
import { OfficeRepository } from './infrastructure/repository/OfficeRepository';
import { SigningCalendarRepository } from './infrastructure/repository/SigningCalendarRepository';
import { SigningManagementCalendarRepository } from './infrastructure/repository/SigningManagementCalendarRepository';
import { SigningManagementRepository } from './infrastructure/repository/SigningManagementRepository';
import { SigningRepository } from './infrastructure/repository/SigningRepository';
import { StatisticsRepository } from './infrastructure/repository/StatisticsRepository';
import { UsersManagementRepository } from './infrastructure/repository/UsersManagementRepository';

decorate(injectable(), UserManagementApiConfiguration);
decorate(injectable(), SigningManagementApiConfiguration);
decorate(inject(TYPES.UserService), UserManagementApiConfiguration, 0);
decorate(inject(TYPES.UserService), SigningManagementApiConfiguration, 0);

decorate(injectable(), AuthApi);
decorate(injectable(), UserApi);
decorate(injectable(), ObservationsApi);
decorate(injectable(), SigningsApi);
decorate(injectable(), SigningsManagementApi);
decorate(injectable(), StatisticsApi);
decorate(injectable(), UsersManagementApi);
decorate(injectable(), OfficesApi);
decorate(injectable(), DepartmentsApi);
decorate(injectable(), CompaniesApi);

/* decorate(inject(UserManagementApiConfiguration), AuthApi, 0); */
decorate(inject(SigningManagementApiConfiguration), ObservationsApi, 0);
decorate(inject(SigningManagementApiConfiguration), SigningsApi, 0);
decorate(inject(SigningManagementApiConfiguration), SigningsManagementApi, 0);
decorate(inject(SigningManagementApiConfiguration), StatisticsApi, 0);
decorate(inject(UserManagementApiConfiguration), UsersManagementApi, 0);
decorate(inject(UserManagementApiConfiguration), UserApi, 0);
decorate(inject(UserManagementApiConfiguration), OfficesApi, 0);
decorate(inject(UserManagementApiConfiguration), DepartmentsApi, 0);
decorate(inject(UserManagementApiConfiguration), CompaniesApi, 0);

decorate(injectable(), AuthenticateUseCase);
decorate(inject(TYPES.UserService), AuthenticateUseCase, 0);
decorate(inject(AuthApi), AuthenticateUseCase, 1);

decorate(injectable(), UserUseCase);
decorate(inject(TYPES.UserService), UserUseCase, 0);
decorate(inject(UserApi), UserUseCase, 1);

decorate(injectable(), UserUpdatePasswordUseCase);
decorate(inject(TYPES.UserService), UserUpdatePasswordUseCase, 0);
decorate(inject(UserApi), UserUpdatePasswordUseCase, 1);

decorate(injectable(), CompanyRepository);
decorate(inject(CompaniesApi), CompanyRepository, 0);

decorate(injectable(), OfficeRepository);
decorate(inject(OfficesApi), OfficeRepository, 0);

decorate(injectable(), DepartmentRepository);
decorate(inject(DepartmentsApi), DepartmentRepository, 0);

decorate(injectable(), UsersManagementRepository);
decorate(inject(UsersManagementApi), UsersManagementRepository, 0);

decorate(injectable(), ObservationRepository);
decorate(inject(ObservationsApi), ObservationRepository, 0);

decorate(injectable(), SigningRepository);
decorate(inject(SigningsApi), SigningRepository, 0);

decorate(injectable(), SigningCalendarRepository);
decorate(inject(SigningsApi), SigningCalendarRepository, 0);

decorate(injectable(), SigningManagementRepository);
decorate(inject(SigningsManagementApi), SigningManagementRepository, 0);

decorate(injectable(), SigningManagementCalendarRepository);
decorate(inject(SigningsManagementApi), SigningManagementCalendarRepository, 0);

decorate(injectable(), StatisticsRepository);
decorate(inject(StatisticsApi), StatisticsRepository, 0);

/* decorate(injectable(), AuthGuard);
decorate(inject(TYPES.UserService), AuthGuard, 0); */
