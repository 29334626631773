import { IRepository } from '@movicoders/domain';
import { DomainPage } from '@movicoders/domain/lib/Entities/DomainPage';
import {
  GetCalendarSigningsByUserRequest,
  GetDateRangeSigningsRequest,
  SigningDTO,
  SigningForCalendarDTO,
  SigningsApi,
} from '../../clients/signing-management';

export class SigningRepository implements IRepository<SigningDTO, GetDateRangeSigningsRequest, any> {
  constructor(private readonly client: SigningsApi) {}
  get(criteria?: GetDateRangeSigningsRequest | undefined): Promise<SigningDTO[]> {
    return this.client.getDateRangeSignings(criteria!!);
  }
  getPaginated(pageCriteria?: any): Promise<DomainPage<SigningDTO>> {
    throw new Error('Method not implemented.');
  }
  getById(id: string): Promise<SigningDTO> {
    throw new Error('Method not implemented.');
  }
  create(entity: SigningDTO): Promise<SigningDTO> {
    return this.client.saveSigning({ signingDTO: entity });
  }
  update(entity: SigningDTO): Promise<SigningDTO> {
    return this.client.updateSigning({ signingId: entity.id!!, signingDTO: entity });
  }
  remove(id: string): Promise<any> {
    return this.client.disableSigning({ signingId: id });
  }
  getCalendar(criteria: GetCalendarSigningsByUserRequest): Promise<SigningForCalendarDTO[]> {
    return this.client.getCalendarSigningsByUser(criteria);
  }
}

/*
export class SigningRepository implements IRepository<SigningDTO | number, GetUserSigningsBetweenDatesRequest, any> {
  constructor(private readonly signingsApi: SigningsApi) {}
  getPaginated(pageCriteria?: any): Promise<DomainPage<SigningDTO>> {
    throw new Error('Method not implemented.');
  }
  remove(id: string): Promise<void> {
    throw new Error('Method not implemented.');
  }
  get(criteria?: GetUserSigningsBetweenDatesRequest | undefined): Promise<SigningDTO[]> {
    return this.signingsApi.getUserSigningsBetweenDates(criteria!!);
  }
  getById(id: string): Promise<SigningDTO> {
    throw new Error('Method not implemented.');
  }
  create(entity: SigningDTO): Promise<SigningDTO> {
    return this.signingsApi.saveSigning({ signingDTO: entity });
  }
  update(entity: SigningDTO): Promise<SigningDTO | number> {
    return this.signingsApi.updateSigning({ signingDTO: entity, id: entity.id!! });
  }
  delete(id: string): Promise<void> {
    throw new Error('Method not implemented.');
  }
}
*/
