import { IRepository } from '@movicoders/domain';
import { DomainPage } from '@movicoders/domain/lib/Entities/DomainPage';
import { DepartmentDTO, DepartmentsApi } from '../../clients/user-management';

export class DepartmentRepository implements IRepository<DepartmentDTO, DepartmentDTO, any> {
  constructor(private readonly departmentApi: DepartmentsApi) {}
  get(criteria?: DepartmentDTO | undefined): Promise<DepartmentDTO[]> {
    return this.departmentApi.getDepartments();
  }
  getPaginated(pageCriteria?: any): Promise<DomainPage<DepartmentDTO>> {
    throw new Error('Method not implemented.');
  }
  getById(id: string): Promise<DepartmentDTO> {
    throw new Error('Method not implemented.');
  }
  create(entity: DepartmentDTO): Promise<DepartmentDTO> {
    return this.departmentApi.createDepartment({ departmentDTO: entity });
  }
  update(entity: DepartmentDTO): Promise<DepartmentDTO> {
    throw new Error('Method not implemented.');
  }
  remove(id: string): Promise<void | any> {
    return this.departmentApi.deleteDepartmentId({ departmentId: id });
  }
}
