import { makeStyles } from '@mui/styles';
import { Sizes } from '../../../theme';

export interface StyleProps {
  colorBT: string;
  size?: number;
}

export const useStylesOptions = makeStyles({
  button: {
    borderRadius: '50% !important',
    width: `calc(${Sizes.widthCircleBtn} * 5)`,
    height: `calc(${Sizes.heightCircleBtn} * 5)`,
    outline: `6px solid rgba(255, 255, 255,1) !important`,
  },
});

export default useStylesOptions;
