import { TYPES, UserService } from '@movicoders/domain';
import { useResolve } from '@movicoders/ui';
import { ReactElement, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { ROUTER_PATHS } from '../constants';

interface IAuthGuard {
  children: ReactElement;
  isLoggedIn?: any;
  redirectPath?: string;
}

export const AuthGuard = (params: IAuthGuard) => {
  const navigate = useNavigate();
  const userService = useResolve<UserService>(TYPES.UserService);

  useEffect(() => {
    if (!userService.user) navigate(params.redirectPath || ROUTER_PATHS.login);
  }, [navigate, userService.user]);

  return userService.user ? <>{params.children}</> : <></>;
};
