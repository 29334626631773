import { useState } from 'react';
import { useNavigate } from 'react-router';
import { Dialog } from '../../components/dialog/Dialog';
import { ContainerAccount } from '../../components/user/container-account/ContainerAccount';
import { Colors } from '../../theme';

export const Account = () => {
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();

  const onClose = () => {
    setOpen(false);
    navigate('/');
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={true}
      styleContent={{ backgroundColor: Colors.background }}
      styleHeader={{ backgroundColor: Colors.background }}
      dialogConfig={{
        content: <ContainerAccount />,
      }}
    />
  );
};
