import { makeStyles } from '@mui/styles';
import { Border, Colors, Sizes } from '../theme';

export interface StyleProps {
  color: string;
  size?: number;
}

export const useStyles = makeStyles({
  mainBox: {
    backgroundColor: 'transparent',
    color: Colors.whiteText,
    borderRadius: Border.borderRadiusBox,
    padding: `calc(${Sizes.paddingBox} * 4)`,
    '@media (max-width: 600px)': {
      width: '100%',
      height: '100%',
      padding: `calc(${Sizes.paddingBox} * 2)`,
    },
  },
});

export default useStyles;
