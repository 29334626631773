/* tslint:disable */
/* eslint-disable */
/**
 * UserManagement microservice (dev)
 * UserManagement microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  MovicodersBadRequestException,
  MovicodersNotFoundException,
  MovicodersServiceException,
  OfficeDTO,
  OfficeInfoDTO,
} from '../models';
import {
    MovicodersBadRequestExceptionFromJSON,
    MovicodersBadRequestExceptionToJSON,
    MovicodersNotFoundExceptionFromJSON,
    MovicodersNotFoundExceptionToJSON,
    MovicodersServiceExceptionFromJSON,
    MovicodersServiceExceptionToJSON,
    OfficeDTOFromJSON,
    OfficeDTOToJSON,
    OfficeInfoDTOFromJSON,
    OfficeInfoDTOToJSON,
} from '../models';

export interface CreateOfficeRequest {
    officeDTO?: OfficeDTO;
}

export interface DeleteOfficeByIdRequest {
    officeId: string;
}

/**
 * 
 */
export class OfficesApi extends runtime.BaseAPI {

    /**
     * Create an office
     */
    async createOfficeRaw(requestParameters: CreateOfficeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OfficeDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/offices`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OfficeDTOToJSON(requestParameters.officeDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OfficeDTOFromJSON(jsonValue));
    }

    /**
     * Create an office
     */
    async createOffice(requestParameters: CreateOfficeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OfficeDTO> {
        const response = await this.createOfficeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete an office by officeId
     */
    async deleteOfficeByIdRaw(requestParameters: DeleteOfficeByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OfficeDTO>> {
        if (requestParameters.officeId === null || requestParameters.officeId === undefined) {
            throw new runtime.RequiredError('officeId','Required parameter requestParameters.officeId was null or undefined when calling deleteOfficeById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/offices/{officeId}`.replace(`{${"officeId"}}`, encodeURIComponent(String(requestParameters.officeId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OfficeDTOFromJSON(jsonValue));
    }

    /**
     * Delete an office by officeId
     */
    async deleteOfficeById(requestParameters: DeleteOfficeByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OfficeDTO> {
        const response = await this.deleteOfficeByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Offices
     */
    async getOfficesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OfficeDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/offices`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OfficeDTOFromJSON));
    }

    /**
     * Get Offices
     */
    async getOffices(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OfficeDTO>> {
        const response = await this.getOfficesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get Offices full info
     */
    async getOfficesFullInfoRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OfficeInfoDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/offices/getFullInfo`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OfficeInfoDTOFromJSON));
    }

    /**
     * Get Offices full info
     */
    async getOfficesFullInfo(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OfficeInfoDTO>> {
        const response = await this.getOfficesFullInfoRaw(initOverrides);
        return await response.value();
    }

}
