/* tslint:disable */
/* eslint-disable */
/**
 * UserManagement microservice (dev)
 * UserManagement microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CredentialsDTO
 */
export interface CredentialsDTO {
    /**
     * 
     * @type {boolean}
     * @memberof CredentialsDTO
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CredentialsDTO
     */
    biometry?: string;
    /**
     * 
     * @type {number}
     * @memberof CredentialsDTO
     */
    created?: number;
    /**
     * 
     * @type {string}
     * @memberof CredentialsDTO
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof CredentialsDTO
     */
    modified?: number;
    /**
     * 
     * @type {string}
     * @memberof CredentialsDTO
     */
    nfcId?: string;
    /**
     * 
     * @type {string}
     * @memberof CredentialsDTO
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof CredentialsDTO
     */
    tenant?: string;
    /**
     * 
     * @type {string}
     * @memberof CredentialsDTO
     */
    username?: string;
}

/**
 * Check if a given object implements the CredentialsDTO interface.
 */
export function instanceOfCredentialsDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CredentialsDTOFromJSON(json: any): CredentialsDTO {
    return CredentialsDTOFromJSONTyped(json, false);
}

export function CredentialsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CredentialsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'active': !exists(json, 'active') ? undefined : json['active'],
        'biometry': !exists(json, 'biometry') ? undefined : json['biometry'],
        'created': !exists(json, 'created') ? undefined : json['created'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'modified': !exists(json, 'modified') ? undefined : json['modified'],
        'nfcId': !exists(json, 'nfcId') ? undefined : json['nfcId'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'tenant': !exists(json, 'tenant') ? undefined : json['tenant'],
        'username': !exists(json, 'username') ? undefined : json['username'],
    };
}

export function CredentialsDTOToJSON(value?: CredentialsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'active': value.active,
        'biometry': value.biometry,
        'created': value.created,
        'id': value.id,
        'modified': value.modified,
        'nfcId': value.nfcId,
        'password': value.password,
        'tenant': value.tenant,
        'username': value.username,
    };
}

