import { CircularProgress, Paper as MUIPaper } from '@mui/material';

interface IPaper {
  sx?: any;
  loading?: boolean;
  children: React.ReactNode;
}

export const Paper = (params: IPaper) => {
  return (
    <MUIPaper sx={params.sx} elevation={3} style={{ padding: '1em', borderRadius: '0.5em' }}>
      {params.loading ? <CircularProgress /> : params.children}
    </MUIPaper>
  );
};
