import { UserApi } from '../../clients/user-management';
import { User } from '../../domain/models/User';
import { UserService } from '../../domain/services/UserService';

export class UserUseCase {
  constructor(private readonly userService: UserService, private readonly userClient: UserApi) {}

  public async execute() {
    try {
      const userResponse = await this.userClient.getUser();
      const user = await this.userService.user;

      this.userService.save(
        new User(
          userResponse.name!!,
          userResponse.surname!!,
          user?.username!!,
          user?.token!!,
          userResponse.credentials!!,
          userResponse.email!!,
          userResponse.role!!,
          {
            id: userResponse.office?.id!!,
            name: userResponse.office?.name!!,
          },
          {
            id: userResponse.department?.id!!,
            name: userResponse.department?.name!!,
          }
        )
      );
      return userResponse;
    } catch (err) {
      console.log(err);
    }
  }
}

export class UserUpdatePasswordUseCase {
  constructor(private readonly userService: UserService, private readonly userClient: UserApi) {}

  public async execute(password: string) {
    try {
      const userResponse = await this.userClient.updatePassword({
        passwordRequestDTO: {
          credentialsId: this.userService.user?.credentials,
          password: password,
        },
      });
      return userResponse;
    } catch (err) {
      console.log(err);
    }
  }
}
