import { Box, LinearProgress } from '@mui/material';
import { DataGrid, GridRowIdGetter } from '@mui/x-data-grid';
import { ISelected } from './options/types-options';
import { TableToolbar } from './toolbar/table-toolbar';

interface ITable {
  columns?: any[];
  rows?: any[];
  loading?: boolean;
  initialState?: {};
  getRowId?: GridRowIdGetter<any>;
  setSelection?: (props: ISelected) => void | undefined;
  nameTable?: string;
  isToolbar?: boolean;
}

export const Table = (params: ITable) => {
  return (
    <Box sx={{ height: 500, minWidth: '100%' }}>
      <DataGrid
        getRowId={params.getRowId}
        columns={params.columns ?? []}
        rows={params.rows ?? []}
        headerHeight={65}
        rowHeight={65}
        pageSize={5}
        loading={params.loading ?? false}
        rowsPerPageOptions={[5]}
        autoHeight
        disableColumnMenu
        hideFooterSelectedRowCount
        components={{
          Toolbar: TableToolbar,
          LoadingOverlay: LinearProgress,
        }}
        initialState={params.initialState}
        onSelectionModelChange={(newSelection) => {
          params.setSelection?.({ data: newSelection });
        }}
      />
    </Box>
  );
};
