import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { CSSObject, styled, Theme, useTheme } from '@mui/material/styles';
import { useState } from 'react';
import { DrawerOptions } from './options-drawer';
import { pathAlterUser, pathIconsCommons, pathIconsUser } from './toolbar-paths';

interface DrawerProps {
  drawerwidth: string | number;
  open?: boolean;
}

const openedMixin = (theme: Theme, drawerwidth: string | number): CSSObject => ({
  width: drawerwidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})<DrawerProps>(({ theme, open, drawerwidth }) => ({
  width: drawerwidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme, drawerwidth),
    '& .MuiDrawer-paper': openedMixin(theme, drawerwidth),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

interface IDrawer {
  open: boolean;
  setOpen: (value: boolean) => void;
  drawerwidth: string | number;
}

export const DrawerMenu = (params: IDrawer) => {
  const { open, setOpen } = params;
  const theme = useTheme();
  const [currentPath, setCurrentPath] = useState('');

  const drawerwidth = params.drawerwidth;
  return (
    <Drawer variant="permanent" open={open} drawerwidth={drawerwidth}>
      <DrawerHeader>
        <IconButton onClick={() => params.setOpen(false)}>{theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}</IconButton>
      </DrawerHeader>
      <Divider />
      <DrawerOptions options={pathIconsCommons} open={open} currentPath={currentPath} setCurrentPath={setCurrentPath} />
      <Divider />
      <DrawerOptions options={pathAlterUser} open={open} currentPath={currentPath} setCurrentPath={setCurrentPath} />
      <Divider />
      <DrawerOptions options={pathIconsUser} open={open} currentPath={currentPath} setCurrentPath={setCurrentPath} />
    </Drawer>
  );
};
