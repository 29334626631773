/* tslint:disable */
/* eslint-disable */
/**
 * UserManagement microservice (dev)
 * UserManagement microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DepartmentDTO } from './DepartmentDTO';
import {
    DepartmentDTOFromJSON,
    DepartmentDTOFromJSONTyped,
    DepartmentDTOToJSON,
} from './DepartmentDTO';
import type { OfficeDTO } from './OfficeDTO';
import {
    OfficeDTOFromJSON,
    OfficeDTOFromJSONTyped,
    OfficeDTOToJSON,
} from './OfficeDTO';

/**
 * 
 * @export
 * @interface UserBasicDTO
 */
export interface UserBasicDTO {
    /**
     * 
     * @type {boolean}
     * @memberof UserBasicDTO
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserBasicDTO
     */
    credentials?: string;
    /**
     * 
     * @type {DepartmentDTO}
     * @memberof UserBasicDTO
     */
    department?: DepartmentDTO;
    /**
     * 
     * @type {string}
     * @memberof UserBasicDTO
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UserBasicDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof UserBasicDTO
     */
    name?: string;
    /**
     * 
     * @type {OfficeDTO}
     * @memberof UserBasicDTO
     */
    office?: OfficeDTO;
    /**
     * 
     * @type {string}
     * @memberof UserBasicDTO
     */
    role?: UserBasicDTORoleEnum;
    /**
     * 
     * @type {string}
     * @memberof UserBasicDTO
     */
    surname?: string;
    /**
     * 
     * @type {string}
     * @memberof UserBasicDTO
     */
    tenant?: string;
    /**
     * 
     * @type {string}
     * @memberof UserBasicDTO
     */
    username?: string;
}


/**
 * @export
 */
export const UserBasicDTORoleEnum = {
    Administrator: 'ADMINISTRATOR',
    Manager: 'MANAGER',
    Worker: 'WORKER'
} as const;
export type UserBasicDTORoleEnum = typeof UserBasicDTORoleEnum[keyof typeof UserBasicDTORoleEnum];


/**
 * Check if a given object implements the UserBasicDTO interface.
 */
export function instanceOfUserBasicDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserBasicDTOFromJSON(json: any): UserBasicDTO {
    return UserBasicDTOFromJSONTyped(json, false);
}

export function UserBasicDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserBasicDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'active': !exists(json, 'active') ? undefined : json['active'],
        'credentials': !exists(json, 'credentials') ? undefined : json['credentials'],
        'department': !exists(json, 'department') ? undefined : DepartmentDTOFromJSON(json['department']),
        'email': !exists(json, 'email') ? undefined : json['email'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'office': !exists(json, 'office') ? undefined : OfficeDTOFromJSON(json['office']),
        'role': !exists(json, 'role') ? undefined : json['role'],
        'surname': !exists(json, 'surname') ? undefined : json['surname'],
        'tenant': !exists(json, 'tenant') ? undefined : json['tenant'],
        'username': !exists(json, 'username') ? undefined : json['username'],
    };
}

export function UserBasicDTOToJSON(value?: UserBasicDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'active': value.active,
        'credentials': value.credentials,
        'department': DepartmentDTOToJSON(value.department),
        'email': value.email,
        'id': value.id,
        'name': value.name,
        'office': OfficeDTOToJSON(value.office),
        'role': value.role,
        'surname': value.surname,
        'tenant': value.tenant,
        'username': value.username,
    };
}

