import { PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import { PickerSelectionState } from '@mui/x-date-pickers/internals';
import { useState } from 'react';
import { SigningForCalendarDTO, SigningForCalendarDTOStatusEnum } from '../../../../clients/signing-management';
import { UserDTO } from '../../../../clients/user-management';
import { numberOfHours } from '../../../../helpers/utils';
import { SigningManagementRepository } from '../../../../infrastructure/repository/SigningManagementRepository';
import { Colors } from '../../../theme';
import { Dialog } from '../../dialog/Dialog';
import { TableSignByDay } from '../../signing/tables/sign-by-day/TableSignByDay';

interface IPickerDay {
  day: Date;
  selected?: Date;
  render: boolean;
  daySame: SigningForCalendarDTO;
  isHours?: boolean;
  isOpenDay?: Boolean;
  loading?: boolean;
  onChange?: (currentDate: Date) => void;
  onSelectDay?: (currentDate: Date) => void;
  user?: UserDTO;
}

const stylesDay = (day: Date, daySame: SigningForCalendarDTO) => {
  if (daySame?.status === SigningForCalendarDTOStatusEnum.Complete && day.getMonth() === new Date(daySame?.day!!).getMonth())
    return { primary: Colors.statusComplete, secondary: Colors.statusCompleteOpacity };
  if (daySame?.status === SigningForCalendarDTOStatusEnum.Incomplete && day.getMonth() === new Date(daySame?.day!!).getMonth())
    return { primary: Colors.statusIncomplete, secondary: Colors.statusIncompleteOpacity };
  return { primary: Colors.statusEmpty, secondary: Colors.statusEmptyOpacity };
};

const actualDay = (day: Date, daySame: SigningForCalendarDTO) => {
  if (day.toDateString() === new Date().toDateString()) return { primary: Colors.primary, secondary: Colors.calendarSecondary };
};

const selectDay = (day: Date, selectDay: Date) => {
  if (day.toDateString() === selectDay.toDateString()) return { primary: Colors.primary, secondary: Colors.calendarSecondary };
};

export const PickerDays = (params: IPickerDay) => {
  const [openTable, setOpenTable] = useState(false);
  return (
    <>
      <PickersDay
        day={params.day}
        onDaySelect={(day: unknown, isFinish: PickerSelectionState) => {
          params.isOpenDay ? setOpenTable(true) : params.onChange && params.onChange(params.day);
        }}
        style={{
          backgroundColor: `${stylesDay(params.day, params.daySame!!)?.secondary}`,
          border: `2px solid ${actualDay(params.day, params.daySame!!)?.primary}`,
          boxShadow: `0 0 0px 1px ${selectDay(params.day, params.selected!!)?.primary}`,
        }}
        outsideCurrentMonth={params.render}
      >
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: '-10' }}>
          {params.isHours ? (
            <>
              <div style={{ position: 'relative', top: '2px' }}>{params.day.getDate()}</div>
              <div style={{ fontSize: '0.6em', position: 'relative', top: '-2px' }}>
                {params.daySame?.hours ? `${numberOfHours(params.daySame?.hours)}` : '⠀'}
              </div>
            </>
          ) : (
            <>
              <div>{params.day.getDate()}</div>
            </>
          )}
        </div>
      </PickersDay>
      {params.isOpenDay && (
        <Dialog
          open={openTable}
          onClose={() => {
            params.onChange?.(params.day);
            setOpenTable(false);
          }}
          maxWidth={'lg'}
          fullWidth={true}
          dialogConfig={{
            title: '',
            content: <TableSignByDay repository={SigningManagementRepository} user={params.user} date={params.day} />,
          }}
        />
      )}
    </>
  );
};
