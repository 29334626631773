import i18next from 'i18next';

export interface IError {
  errorCode: string;
  message: string;
  description: string;
}

export const errorList: IError[] = [
  {
    errorCode: 'ERROR_0001',
    message: i18next.t('error.0001'),
    description: 'Error getting data',
  },
  {
    errorCode: 'ERROR_0002',
    message: i18next.t('error.0002'),
    description: 'No data found',
  },
  {
    errorCode: 'ERROR_0003',
    message: i18next.t('error.0003'),
    description: 'Error creating the registry',
  },
  {
    errorCode: 'ERROR_0004',
    message: i18next.t('error.0004'),
    description: 'Duplicate registry',
  },
  {
    errorCode: 'ERROR_0005',
    message: i18next.t('error.0005'),
    description: 'Registry already existing',
  },
  {
    errorCode: 'ERROR_0006',
    message: i18next.t('error.0006'),
    description: 'Data are not valid',
  },
  {
    errorCode: 'ERROR_0007',
    message: i18next.t('error.0007'),
    description: 'Error updating the registry',
  },
  {
    errorCode: 'ERROR_0008',
    message: i18next.t('error.0008'),
    description: 'Unable to update the registry because it is in use',
  },
  {
    errorCode: 'ERROR_0009',
    message: i18next.t('error.0009'),
    description: 'Error deleting the registry',
  },
  {
    errorCode: 'ERROR_0010',
    message: i18next.t('error.0010'),
    description: 'Unable to delete the registry because it is in use',
  },
  {
    errorCode: 'default',
    message: 'ERROR',
    description: 'Error',
  },
];
