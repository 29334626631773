/* tslint:disable */
/* eslint-disable */
/**
 * UserManagement microservice (dev)
 * UserManagement microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CredentialsDTO,
  MovicodersBadRequestException,
  MovicodersNotFoundException,
  MovicodersServiceException,
  PasswordRequestDTO,
  ProfileDTO,
} from '../models';
import {
    CredentialsDTOFromJSON,
    CredentialsDTOToJSON,
    MovicodersBadRequestExceptionFromJSON,
    MovicodersBadRequestExceptionToJSON,
    MovicodersNotFoundExceptionFromJSON,
    MovicodersNotFoundExceptionToJSON,
    MovicodersServiceExceptionFromJSON,
    MovicodersServiceExceptionToJSON,
    PasswordRequestDTOFromJSON,
    PasswordRequestDTOToJSON,
    ProfileDTOFromJSON,
    ProfileDTOToJSON,
} from '../models';

export interface UpdatePasswordRequest {
    passwordRequestDTO?: PasswordRequestDTO;
}

/**
 * 
 */
export class UserApi extends runtime.BaseAPI {

    /**
     * Get User
     */
    async getUserRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProfileDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/user`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfileDTOFromJSON(jsonValue));
    }

    /**
     * Get User
     */
    async getUser(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProfileDTO> {
        const response = await this.getUserRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update password for logged in user.
     */
    async updatePasswordRaw(requestParameters: UpdatePasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CredentialsDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/user/password`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PasswordRequestDTOToJSON(requestParameters.passwordRequestDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CredentialsDTOFromJSON(jsonValue));
    }

    /**
     * Update password for logged in user.
     */
    async updatePassword(requestParameters: UpdatePasswordRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CredentialsDTO> {
        const response = await this.updatePasswordRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
