import { IRepository } from '@movicoders/domain';
import { DomainPage } from '@movicoders/domain/lib/Entities/DomainPage';
import { UserBasicDTO, UserDTO, UsersManagementApi } from '../../clients/user-management';

export class UsersManagementRepository implements IRepository<UserBasicDTO | UserDTO, UserDTO, any> {
  constructor(private readonly usersManagementApi: UsersManagementApi) {}
  get(criteria?: UserDTO | undefined): Promise<(UserBasicDTO | UserDTO)[]> {
    return this.usersManagementApi.getUsersBasicInformation();
  }
  getManagementDepartment(id: string): Promise<UserBasicDTO[]> {
    return this.usersManagementApi.getUsersBasicInformationByDepartment({ departmentId: id });
  }
  getPaginated(pageCriteria?: any): Promise<DomainPage<UserBasicDTO | UserDTO>> {
    throw new Error('Method not implemented.');
  }
  getById(id: string): Promise<UserBasicDTO | UserDTO> {
    throw new Error('Method not implemented.');
  }
  create(entity: UserDTO): Promise<UserBasicDTO | UserDTO> {
    return this.usersManagementApi.registerUser({ userDTO: entity });
  }
  update(entity: UserBasicDTO | UserDTO): Promise<UserBasicDTO | UserDTO> {
    return this.usersManagementApi.updateUserById({ profileId: entity.id!!, profileDTO: entity });
    /* return Promise.resolve(entity); */
  }
  remove(id: string): Promise<void> {
    throw new Error('Method not implemented.');
  }
  delete(entity: string): Promise<any> {
    return this.usersManagementApi.deleteUserById({ profileId: entity });
  }
  activate(entity: string): Promise<any> {
    return this.usersManagementApi.activateUserById({ profileId: entity });
  }
  deactivate(entity: string): Promise<any> {
    return this.usersManagementApi.deactivateUserById({ profileId: entity });
  }
}
