import { GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid';

export const TableToolbar = () => {
  const csvNameWhenExport = (): string => `Data_${new Date().toISOString().slice(0, 10)}`;

  return (
    <GridToolbarContainer>
      <GridToolbarExport
        csvOptions={{
          fileName: csvNameWhenExport(),
          delimiter: ';',
          utf8WithBom: true,
        }}
      />
      <GridToolbarFilterButton />
    </GridToolbarContainer>
  );
};
