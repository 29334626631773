import {
  AntDesign,
  Entypo,
  EvilIcons,
  Feather,
  FontAwesome,
  FontAwesome5,
  Fontisto,
  Foundation,
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
  Octicons,
  SimpleLineIcons,
  Zocial,
} from "@native-base/icons";

export enum VectorDirectory {
  AntDesign = "AntDesign",
  Entypo = "Entypo",
  EvilIcons = "EvilIcons",
  Feather = "Feather",
  FontAwesome = "FontAwesome",
  FontAwesome5 = "FontAwesome5",
  FontAwesome5Brands = "FontAwesome5Brands",
  Fontisto = "Fontisto",
  Foundation = "Foundation",
  Ionicons = "Ionicons",
  MATERIALCOMMUNITYICONS = "MATERIALCOMMUNITYICONS",
  MaterialIcons = "MaterialIcons",
  Octicons = "Octicons",
  SimpleLineIcons = "SimpleLineIcons",
  Zocial = "Zocial",
}

export const enumToDirectory = (directory: string) => {
  switch (directory) {
    case VectorDirectory.FontAwesome:
      return FontAwesome;
    case VectorDirectory.AntDesign:
      return AntDesign;
    case VectorDirectory.Entypo:
      return Entypo;
    case VectorDirectory.EvilIcons:
      return EvilIcons;
    case VectorDirectory.Feather:
      return Feather;
    case VectorDirectory.FontAwesome5:
      return FontAwesome5;
    case VectorDirectory.Fontisto:
      return Fontisto;
    case VectorDirectory.Foundation:
      return Foundation;
    case VectorDirectory.Ionicons:
      return Ionicons;
    case VectorDirectory.MATERIALCOMMUNITYICONS:
      return MaterialCommunityIcons;
    case VectorDirectory.MaterialIcons:
      return MaterialIcons;
    case VectorDirectory.Octicons:
      return Octicons;
    case VectorDirectory.SimpleLineIcons:
      return SimpleLineIcons;
    case VectorDirectory.Zocial:
      return Zocial;
    default:
      return undefined;
  }
};

export class VectorIcon {
  id: string;
  name: string;
  family: VectorDirectory;

  constructor(id: string, name: string, family: VectorDirectory) {
    this.id = id;
    this.family = family;
    this.name = name;
  }

  getDirectoryAsModule(): any {
    return enumToDirectory(this.family);
  }
}

export class VectorIconFlat {
  name: string;
  directory: VectorDirectory;

  constructor(name: string, directory: VectorDirectory) {
    this.name = name;
    this.directory = directory;
  }

  getDirectoryAsModule(): any {
    return enumToDirectory(this.directory);
  }
}
