import { makeStyles } from '@mui/styles';
import { Colors } from '../../theme';

export const useDialogStyles = makeStyles({
  select: {
    paddingLeft: '1%',
    minWidth: '100%',
    maxWidth: '100%',
  },
  font: {
    fontSize: '16px',
  },
  checkboxLabel: {
    fontSize: 14,
  },
  detailViewTitle: {
    marginRight: 'auto',
    marginTop: '5px !important',
  },
  disableCheckBox: {
    justifyContent: 'flex-end',
    display: 'flex',
    '& .MuiTypography-body1': {
      fontSize: '14px !important',
      color: Colors.typographyBody,
    },
    paddingLeft: '80px',
  },
  dialogActions: {
    justifyContent: 'space-evenly !important',
  },
  btnConfirm: {
    backgroundColor: `${Colors.primary} !important`,
    width: '8em',
    boxShadow: `0px 4px 4px 0px ${Colors.primaryOpacity}`,
    '&:hover': {
      backgroundColor: Colors.primaryDark + ' !important',
      color: Colors.whiteText + ' !important',
    },
  },
  btnCancel: {
    backgroundColor: `${Colors.error} !important`,
    width: '8em',
    boxShadow: `0px 4px 4px 0px ${Colors.errorOpacity}`,
    '&:hover': {
      backgroundColor: Colors.errorDark + ' !important',
      color: Colors.whiteText + ' !important',
    },
  },
});
