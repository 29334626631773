/* tslint:disable */
/* eslint-disable */
/**
 * SigningManagement microservice (dev)
 * SigningManagement microservice for application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StatisticDTO
 */
export interface StatisticDTO {
    /**
     * 
     * @type {number}
     * @memberof StatisticDTO
     */
    _abstract?: number;
    /**
     * 
     * @type {number}
     * @memberof StatisticDTO
     */
    percent?: number;
    /**
     * 
     * @type {number}
     * @memberof StatisticDTO
     */
    total?: number;
}

/**
 * Check if a given object implements the StatisticDTO interface.
 */
export function instanceOfStatisticDTO(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function StatisticDTOFromJSON(json: any): StatisticDTO {
    return StatisticDTOFromJSONTyped(json, false);
}

export function StatisticDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatisticDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        '_abstract': !exists(json, 'abstract') ? undefined : json['abstract'],
        'percent': !exists(json, 'percent') ? undefined : json['percent'],
        'total': !exists(json, 'total') ? undefined : json['total'],
    };
}

export function StatisticDTOToJSON(value?: StatisticDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'abstract': value._abstract,
        'percent': value.percent,
        'total': value.total,
    };
}

