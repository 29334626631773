import { IRepository } from '@movicoders/domain';
import { DomainPage } from '@movicoders/domain/lib/Entities/DomainPage';
import { OfficeDTO, OfficesApi } from '../../clients/user-management';

export class OfficeRepository implements IRepository<OfficeDTO, OfficeDTO, any> {
  constructor(private readonly officeApi: OfficesApi) {}
  get(criteria?: OfficeDTO | undefined): Promise<OfficeDTO[]> {
    return this.officeApi.getOffices();
  }
  getPaginated(pageCriteria?: any): Promise<DomainPage<OfficeDTO>> {
    throw new Error('Method not implemented.');
  }
  getById(id: string): Promise<OfficeDTO> {
    throw new Error('Method not implemented.');
  }
  create(entity: OfficeDTO): Promise<OfficeDTO> {
    return this.officeApi.createOffice({ officeDTO: entity });
  }
  update(entity: OfficeDTO): Promise<OfficeDTO> {
    throw new Error('Method not implemented.');
  }
  remove(id: string): Promise<void | any> {
    return this.officeApi.deleteOfficeById({ officeId: id });
  }
}
